import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  Typography,
  Link as MuiLink,
  useTheme,
  Alert,
  AlertTitle,
  TextField,
} from '@mui/material';
import { TherapyCourse } from '../firebase/firebaseModels';
import useTherapyCourseCollection from '../firebase/useTherapyCourseCollection';
import TherapyCourseCard from '../components/TherapyCourseCard';
import { Link } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { getTranslatableField } from '../services/i18n';
import usePatientCollection from '../firebase/usePatientCollection';
import PendingInvitationsNotification from '../components/PendingInvitationsNotification';

type FrontPageProps = {
  activeOnly: boolean;
  waitingOnly: boolean;
};

export default function FrontPage({
  activeOnly = true,
  waitingOnly = false,
}: FrontPageProps) {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    status: therapyCourseCollectionStatus,
    data: therapyCourseCollectionData,
    error: therapyCourseCollectionError,
  } = useTherapyCourseCollection('all', activeOnly, waitingOnly);
  const [search, setSearch] = useState<string>('');
  // console.log('sharedTherapyCourses', sharedTherapyCourses);

  const { data: patientCollectionData } = usePatientCollection();
  // console.log(patientCollectionData);
  const filteredTherapyCourseCollectionData = useMemo(
    () =>
      therapyCourseCollectionData?.filter((course: TherapyCourse) => {
        const title = getTranslatableField(course.title);
        const patients = course.patients?.map((patient) =>
          patientCollectionData?.find((p) => p.id === patient.id)
        );
        const patientRemarks = patients
          ?.map((patient) => patient?.remark)
          .join('§');
        const patientLicenceCodes = patients
          ?.map((patient) => patient?.licenceCode)
          .join('§');
        const fullSearch =
          `${title}§${patientRemarks}§${patientLicenceCodes}`.toLowerCase();
        const splitSearch = search.toLowerCase().split(' ');
        return splitSearch.every((searchTerm) =>
          fullSearch.includes(searchTerm)
        );
      }),
    [therapyCourseCollectionData, search, patientCollectionData]
  );

  const noSearchResults = useMemo(() => {
    if (!therapyCourseCollectionData) {
      return false;
    }
    return (
      therapyCourseCollectionData.length > 0 &&
      filteredTherapyCourseCollectionData?.length === 0
    );
  }, [therapyCourseCollectionData, filteredTherapyCourseCollectionData]);
  return (
    <>
      <PendingInvitationsNotification />
      {therapyCourseCollectionStatus === 'loading' && <CircularProgress />}
      {therapyCourseCollectionError && (
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error</Trans>
          </AlertTitle>
          <Typography variant="body1" component="p">
            {therapyCourseCollectionError.message}
          </Typography>
        </Alert>
      )}
      {therapyCourseCollectionData &&
        therapyCourseCollectionData.length === 0 && (
          <Box
            textAlign="center"
            mt={4}
            mb={4}
            p={4}
            sx={{
              border: 1,
              borderColor: theme.palette.info.contrastText,
              borderRadius: 1,
              backgroundColor: theme.palette.info.dark,
            }}
          >
            <Typography
              variant="h6"
              component="p"
              align="center"
              color={theme.palette.info.contrastText}
            >
              <Trans>No therapy courses found.</Trans>
            </Typography>
            <Typography
              variant="body1"
              component="p"
              align="center"
              color={theme.palette.info.contrastText}
              sx={{ mt: 2 }}
            >
              <MuiLink component={Link} to="/new-therapy" color="inherit">
                <Trans>Create a therapy course</Trans>
              </MuiLink>
            </Typography>
          </Box>
        )}
      {therapyCourseCollectionData &&
        therapyCourseCollectionData.length > 0 && (
          <TextField
            name="search"
            label={t('Search')}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            sx={{
              mt: 2,
              mb: -1,
              backgroundColor: theme.palette.background.paper,
              borderRadius: 1,
            }}
            color="primary"
          />
        )}
      {noSearchResults && (
        <Alert severity="info" sx={{ mt: 2 }}>
          <AlertTitle>
            <Trans>No therapy courses found with the search term.</Trans>
          </AlertTitle>
        </Alert>
      )}
      {
        /* show courses and patients with quick patient stats */
        filteredTherapyCourseCollectionData &&
          filteredTherapyCourseCollectionData.map((course: TherapyCourse) => (
            <TherapyCourseCard key={course.id} course={course} />
          ))
      }
    </>
  );
}

export function FrontPageWaiting() {
  return <FrontPage activeOnly={false} waitingOnly={true} />;
}
