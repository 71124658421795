import { useTranslation, Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { doc, updateDoc } from 'firebase/firestore';
import {
  Alert,
  AlertTitle,
  Button,
  CardHeader,
  CircularProgress,
  Snackbar,
  Tooltip,
  Chip,
  Box,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useFirestore } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import usePatientDoc from '../firebase/usePatientDoc';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import PendingIcon from '@mui/icons-material/Pending';
import usePatientCollection from '../firebase/usePatientCollection';
import useSharedPatientCollection from '../firebase/useSharedPatientCollection';

export default function PatientEditor() {
  const { t } = useTranslation();
  const { patientId } = useParams<{ patientId: string }>();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();
  const { getSharedPatientRef, getPatientOwnerEmail } =
    useSharedPatientCollection();
  const { isPatientShared } = usePatientCollection();
  const [updateError, setUpdateError] = useState<string | null>(null);

  const [licenceCode, setLicenceCode] = useState('');
  const [remark, setRemark] = useState('');
  // const [userTasks, setUserTasks] = useState<UserTask[]>([]);

  const [updateSuccessful, setUpdateSuccessful] = useState<boolean>(false);
  const patientIsShared = isPatientShared(patientId || null);

  const patientRef = useMemo(() => {
    if (!patientId) return null;

    if (patientIsShared) {
      const sharedRef = getSharedPatientRef(patientId);
      if (sharedRef) return sharedRef;
    }

    // If not shared, use regular patient ref
    if (!userProviderData?.id || !email) return null;

    return doc(
      firestore,
      'ServiceProvider',
      userProviderData.id,
      'UserData',
      email,
      'Patient',
      patientId
    );
  }, [
    patientId,
    patientIsShared,
    userProviderData?.id,
    email,
    firestore,
    getSharedPatientRef,
  ]);

  const { status, error, data } = usePatientDoc(patientRef!);
  const [updating, setUpdating] = useState(false);
  const [errorAlertVisible, setErrorAlertVisible] = useState(false);

  useEffect(() => {
    if (status === 'success' && data) {
      setLicenceCode(data.licenceCode || '');
      setRemark(data.remark || '');
    }
  }, [status, data]);

  const handleSave = useCallback(async () => {
    if (!patientRef) return;

    setUpdating(true);

    if (patientId === 'new') {
      setErrorAlertVisible(true);
      setUpdating(false);
      return;
    }

    updateDoc(patientRef, {
      licenceCode,
      remark,
    })
      .then(() => {
        setUpdateSuccessful(true);
        setUpdating(false);
      })
      .catch((error) => {
        console.error('Error updating patient', error);
        setUpdateError(error.message);
        setUpdating(false);
      });
  }, [patientId, patientRef, licenceCode, remark]);

  if (status === 'loading' || userProviderStatus === 'loading') {
    return <CircularProgress />;
  }

  if (status === 'error') {
    console.error('Error loading patient', error);
    return <Alert severity="error">{t('Error loading patient')}</Alert>;
  }

  if (userProviderStatus === 'error') {
    return <Alert severity="error">{t('Error loading user provider')}</Alert>;
  }

  return (
    <>
      <Card sx={{ mt: 2 }}>
        <CardHeader
          title={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              {t('Basics')}
              {patientIsShared && (
                <Tooltip
                  title={t('Shared by {{ownerEmail}}', {
                    ownerEmail: getPatientOwnerEmail(patientId || null),
                  })}
                >
                  <Chip
                    label={t('Shared')}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                </Tooltip>
              )}
            </Box>
          }
          action={
            data?.pending && (
              <Tooltip title={t('Patient has not yet registered in the app')}>
                <PendingIcon sx={{ color: 'info.main' }} />
              </Tooltip>
            )
          }
        />
        <CardContent>
          {data?.pending && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {t('This patient has not yet registered in the app')}
            </Alert>
          )}
          {data?.isShared && (
            <Alert severity="info" sx={{ mb: 2 }}>
              {t('Shared by {{ownerEmail}}', {
                ownerEmail: data.ownerEmail,
              })}
            </Alert>
          )}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="licence-code"
                label={t('License Code')}
                placeholder={t('Enter License Code')}
                disabled
                value={licenceCode}
                onChange={(e) => setLicenceCode(e.target.value)}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="personal-identifier"
                label={t('Personal Identifier')}
                placeholder={t('Enter Personal Identifier')}
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
                fullWidth
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      {error && <Alert severity="error">{t('Error loading patient')}</Alert>}
      {updateSuccessful && (
        <Alert severity="success" onClose={() => setUpdateSuccessful(false)}>
          {t('Patient updated successfully')}
        </Alert>
      )}
      {errorAlertVisible && (
        <Alert severity="error" onClose={() => setErrorAlertVisible(false)}>
          <AlertTitle>{t('Error')}</AlertTitle>
          {t('Invalid patient id')}
        </Alert>
      )}
      <Button
        sx={{ mt: 2 }}
        loading={updating}
        onClick={handleSave}
        variant="contained"
        color="primary"
      >
        <Trans>Save</Trans>
      </Button>
      <Snackbar open={!!updateError}>
        <Alert severity="error">
          <AlertTitle>{t('Error')}</AlertTitle>
          <Trans>{updateError}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
}
