import { useMemo } from 'react';
import { doc, DocumentReference } from 'firebase/firestore';
import { TherapyCourse } from '../firebase/firebaseModels';
import { useSharedTherapyCourses } from '../firebase/useSharedTherapyCourses';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import { useFirestore } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';

export function useTherapyCourseRef(
  therapyCourseId: string | undefined,
): {
  therapyCourseRef: DocumentReference<TherapyCourse> | null,
  isShared: boolean,
} {
  const { isTherapyCourseShared, getTherapyCourseRef } = useSharedTherapyCourses();

  const firestore = useFirestore();
  const { email } = useFirebaseUser();
  const { data: userProviderData } =
    useUserProvider();

  const userProviderId = userProviderData?.id;

  return useMemo(() => {
    if (!therapyCourseId) return {
      therapyCourseRef: null,
      isShared: false,
    };

    // Check if this is a shared course
    if (isTherapyCourseShared(therapyCourseId)) {
      return {
        therapyCourseRef: getTherapyCourseRef(therapyCourseId),
        isShared: true,
      };
    }

    // If not shared, use the original logic for owned courses
    if (!userProviderId || !email) return {
      therapyCourseRef: null,
      isShared: false,
    };
    return {
      therapyCourseRef: doc(
        firestore,
        'ServiceProvider',
        userProviderId,
        'UserData',
        email,
      'TherapyCourse',
      therapyCourseId
    ).withConverter<TherapyCourse>({
      fromFirestore: (snap) => snap.data() as TherapyCourse,
        toFirestore: (data) => data,
      }),
      isShared: false,
    };
  }, [
    therapyCourseId,
    userProviderId,
    email,
    firestore,
    isTherapyCourseShared,
    getTherapyCourseRef,
  ]);
} 