import * as React from 'react';
import TocIcon from '@mui/icons-material/Toc';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import GrassIcon from '@mui/icons-material/Grass';
import PersonIcon from '@mui/icons-material/Person';
import SpaIcon from '@mui/icons-material/Spa';
import SpaOutlinedIcon from '@mui/icons-material/SpaOutlined';
import CategoryIcon from '@mui/icons-material/Category';
import FrontPage, { FrontPageWaiting } from '../pages/FrontPage';
import Settings from '../pages/Settings';
import Patients from '../pages/Patients';
import TaskPoolTaskEditor from '../pages/TaskPoolTaskEditor';
import TaskPoolTaskCategoryEditor from '../pages/TaskPoolTaskCategoryEditor';
import PatientEditor from '../pages/PatientEditor';
import TherapyPlanEditor from '../pages/TherapyPlanEditor';
import TaskPoolTabs from '../pages/TaskPoolTabs';
import TaskPoolTaskCategoriesTabs from '../pages/TaskPoolTaskCategoriesTabs';
import TherapyCourseOverview from '../pages/TherapyCourseOverview';
import { Trans } from 'react-i18next';
import TherapyPlanTabs from '../pages/TherapyPlanTabs';
import TherapyCoursesAll from '../pages/TherapyCoursesAll';
import TherapyCourseEditor from '../pages/TherapyCourseEditor';
import AddCardIcon from '@mui/icons-material/AddCard';
import GenerateCourse from '../pages/GenerateCourse';
import PortalUsers from '../pages/PortalUsers';
import PortalUserEditor from '../pages/PortalUserEditor';
import usePortalUser from '../firebase/usePortalUser';
import { useMemo } from 'react';
import DeleteAppUser from '../pages/DeleteAppUser';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import TherapyCourseInvitations from '../pages/TherapyCourseInvitations';
import { useTherapyCourseInvitations } from '../firebase/useTherapyCourseInvitations';

export type TabData = {
  label: 'Standard' | 'My Clinic' | 'My Own';
  path: string;
  type: string;
  tasktype?: string;
};

export const TASKPOOL_GENERIC_TABS: TabData[] = [
  {
    label: 'Standard',
    path: '/admin/taskpool/generic/default',
    type: 'default',
    tasktype: 'generic',
  },
  {
    label: 'My Clinic',
    path: '/admin/taskpool/generic/organization',
    type: 'organization',
    tasktype: 'generic',
  },
  {
    label: 'My Own',
    path: '/admin/taskpool/generic/own',
    type: 'own',
    tasktype: 'generic',
  },
];

export const TASKPOOL_QUESTIONNAIRE_TABS: TabData[] = [
  {
    label: 'Standard',
    path: '/admin/taskpool/questionnaire/default',
    type: 'default',
    tasktype: 'questionnaire',
  },
  {
    label: 'My Clinic',
    path: '/admin/taskpool/questionnaire/organization',
    type: 'organization',
    tasktype: 'questionnaire',
  },
  {
    label: 'My Own',
    path: '/admin/taskpool/questionnaire/own',
    type: 'own',
    tasktype: 'questionnaire',
  },
];
export const TASKCATEGORIES_TABS: TabData[] = [
  { label: 'Standard', path: '/admin/taskcategories/default', type: 'default' },
  {
    label: 'My Clinic',
    path: '/admin/taskcategories/organization',
    type: 'organization',
  },
  { label: 'My Own', path: '/admin/taskcategories/own', type: 'own' },
];

export const THERAPYPLANS_TABS: TabData[] = [
  { label: 'Standard', path: '/admin/therapyplans/default', type: 'default' },
  {
    label: 'My Clinic',
    path: '/admin/therapyplans/organization',
    type: 'organization',
  },
  { label: 'My Own', path: '/admin/therapyplans/own', type: 'own' },
];
export interface CrumbData {
  therapyCourseId?: string;
  patientId?: string;
  therapyPlanId?: string;
  taskpooltype?: string;
  taskPoolTaskId?: string;
  taskPoolTaskCategoryId?: string;
  taskcategorytype?: string;
  therapyCourseTitle?: string;
  patientTitle?: string;
  taskPoolTaskTitle?: string;
  taskPoolTaskCategoryTitle?: string;
  therapyPlanTitle?: string;
  therapyplantype?: string;
}

export type AppRoute = {
  path?: string;
  icon?: React.ReactNode;
  label?: string;
  Component?: React.ComponentType<any>;
  exact?: boolean;
  tabs?: TabData[];
  children?: AppRoute[];
  crumb?: (data: CrumbData) => React.ReactNode;
  type: 'top-level' | 'sub-level' | 'divider' | 'tabs' | 'collapse';
  badgeContent?: number;
};

export const appRoutes: AppRoute[] = [
  {
    path: '/',
    type: 'collapse',
    label: 'Therapy Courses',
    children: [
      {
        path: 'overview',
        icon: <NewspaperIcon />,
        label: 'Current Courses',
        Component: FrontPage,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Current Courses</Trans>,
        children: [
          {
            path: '/:therapyCourseId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Session View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
          {
            path: '/:therapyCourseId/:patientId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Session View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
        ],
      },
      {
        path: 'new-therapy',
        label: 'Create New Course',
        icon: <AddCardIcon />,
        Component: GenerateCourse,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Create New Course</Trans>,
      },
      {
        path: 'waiting',
        icon: <SpaOutlinedIcon />,
        label: 'Waiting to Start',
        Component: FrontPageWaiting,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Waiting to Start</Trans>,
        children: [
          {
            path: '/:therapyCourseId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Session View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
          {
            path: '/:therapyCourseId/:patientId',
            Component: TherapyCourseOverview,
            label: 'Session View',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>View</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
        ],
      },
      {
        path: 'therapies',
        label: 'All Therapies',
        icon: <SpaIcon />,
        Component: TherapyCoursesAll,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>All Therapies</Trans>,
        children: [
          {
            path: '/:therapyCourseId',
            Component: TherapyCourseEditor,
            label: 'Edit',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => (
              <>
                <Trans>Edit Therapy Course</Trans>
                {` ${data.therapyCourseTitle}`}
              </>
            ),
          },
        ],
      },
      {
        path: 'invitations',
        label: 'Therapy Course Invitations',
        Component: TherapyCourseInvitations,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Therapy Course Invitations</Trans>,
      },
    ],
  },
  {
    type: 'collapse',
    path: '/admin',
    label: 'Administration',
    children: [
      {
        path: '/patients',
        label: 'Patients',
        icon: <PersonIcon />,
        Component: Patients,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Patients</Trans>,
        children: [
          {
            path: '/:patientId',
            Component: PatientEditor,
            label: 'Edit',
            exact: true,
            type: 'sub-level',
            crumb: (data: CrumbData) => <>{data.patientTitle}</>,
          },
        ],
      },
      {
        type: 'divider',
        Component: () => null,
      },
      {
        path: '/therapyplans',
        label: 'Therapy Plans',
        icon: <GrassIcon />,
        Component: TherapyPlanTabs,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Therapy Plans</Trans>,
        children: [
          {
            path: '/:therapyplantype',
            label: 'Therapy Plans',
            icon: <TocIcon />,
            Component: TherapyPlanTabs,
            exact: true,
            tabs: THERAPYPLANS_TABS,
            type: 'tabs',
            crumb: (data: CrumbData) => {
              const tab = THERAPYPLANS_TABS.find(
                (tab) => tab.type === data.therapyplantype
              );
              return <Trans>{tab?.label}</Trans>;
            },
            children: [
              {
                path: '/:therapyPlanId',
                Component: TherapyPlanEditor,
                exact: true,
                label: 'Edit',
                type: 'sub-level',
                crumb: (data: CrumbData) => <>{data.therapyPlanTitle}</>,
                children: [
                  {
                    path: '/:idToClone',
                    Component: TherapyPlanEditor,
                    exact: true,
                    label: 'Duplicate',
                    type: 'sub-level',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        type: 'divider',
        Component: () => null,
      },
      {
        path: '/taskpool/generic',
        label: 'Home Tasks',
        icon: <AssignmentIcon />,
        Component: TaskPoolTabs,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Home Tasks</Trans>,
        children: [
          {
            path: '/:taskpooltype',
            label: 'Task Pool',
            icon: <TocIcon />,
            Component: TaskPoolTabs,
            exact: true,
            tabs: TASKPOOL_GENERIC_TABS,
            type: 'tabs',
            crumb: (data: CrumbData) => {
              const tab = TASKPOOL_GENERIC_TABS.find(
                (tab) => tab.type === data.taskpooltype
              );
              return <Trans>{tab?.label}</Trans>;
            },
            children: [
              {
                path: '/:taskPoolTaskId',
                Component: TaskPoolTaskEditor,
                label: 'Edit',
                exact: true,
                type: 'sub-level',
                crumb: (data: CrumbData) => <>{data.taskPoolTaskTitle}</>,
                children: [
                  {
                    path: '/:idToClone',
                    Component: TaskPoolTaskEditor,
                    exact: true,
                    label: 'Duplicate',
                    type: 'sub-level',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/taskpool/questionnaire',
        label: 'Questionnaires',
        icon: <TocIcon />,
        Component: TaskPoolTabs,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Questionnaires</Trans>,
        children: [
          {
            path: '/:taskpooltype',
            label: 'Task Pool',
            icon: <TocIcon />,
            Component: TaskPoolTabs,
            exact: true,
            tabs: TASKPOOL_QUESTIONNAIRE_TABS,
            type: 'tabs',
            crumb: (data: CrumbData) => {
              const tab = TASKPOOL_QUESTIONNAIRE_TABS.find(
                (tab) => tab.type === data.taskpooltype
              );
              return <Trans>{tab?.label}</Trans>;
            },
            children: [
              {
                path: '/:taskPoolTaskId',
                Component: TaskPoolTaskEditor,
                label: 'Edit',
                exact: true,
                type: 'sub-level',
                crumb: (data: CrumbData) => <>{data.taskPoolTaskTitle}</>,
                children: [
                  {
                    path: '/:idToClone',
                    Component: TaskPoolTaskEditor,
                    exact: true,
                    label: 'Duplicate',
                    type: 'sub-level',
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '/taskcategories',
        label: 'Categories',
        icon: <CategoryIcon />,
        Component: TaskPoolTaskCategoriesTabs,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Categories</Trans>,
        children: [
          {
            path: '/:taskcategorytype',
            label: 'Categories',
            icon: <TocIcon />,
            Component: TaskPoolTaskCategoriesTabs,
            exact: true,
            tabs: TASKCATEGORIES_TABS,
            type: 'tabs',
            crumb: (data: CrumbData) => {
              const tab = TASKCATEGORIES_TABS.find(
                (tab) => tab.type === data.taskcategorytype
              );
              return <Trans>{tab?.label}</Trans>;
            },
            children: [
              {
                path: '/:taskPoolTaskCategoryId',
                Component: TaskPoolTaskCategoryEditor,
                exact: true,
                label: 'Edit',
                type: 'sub-level',
                crumb: (data: CrumbData) => (
                  <>{data.taskPoolTaskCategoryTitle}</>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    type: 'collapse',
    path: '/settings',
    label: 'Settings',
    children: [
      {
        path: '/userprofile',
        label: 'User Profile',
        icon: <SettingsIcon />,
        Component: Settings,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Settings</Trans>,
      },
      {
        path: '/portalusers',
        label: 'Portal Users',
        icon: <PersonIcon />,
        Component: PortalUsers,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Portal Users</Trans>,
        children: [
          {
            path: '/:portalUserId',
            Component: PortalUserEditor,
            label: 'Edit',
            exact: true,
            type: 'sub-level',
            crumb: () => <Trans>Edit User</Trans>,
          },
        ],
      },
      {
        path: '/deleteappuser',
        label: 'Delete App User',
        icon: <PersonRemoveIcon />,
        Component: DeleteAppUser,
        exact: true,
        type: 'top-level',
        crumb: () => <Trans>Delete App User</Trans>,
      },
    ],
  },
];

export function useDashboardRoutes() {
  const { isSuperAdmin, isOrganizationAdmin, status } = usePortalUser();
  const { invitedInvitations } = useTherapyCourseInvitations();

  const showPortalUsers = useMemo(
    () => isSuperAdmin || isOrganizationAdmin,
    [isSuperAdmin, isOrganizationAdmin]
  );

  const routes = useMemo(() => {
    // Return null if either status is not 'success'
    if (status !== 'success') {
      return null;
    }

    // Create new array with mapped sections
    return appRoutes.map((section) => {
      // Handle therapy courses section
      if (section.path === '/') {
        const invitationsRoute = section.children?.find(
          (route) => route.path === 'invitations'
        );
        if (invitationsRoute && invitedInvitations?.length > 0) {
          invitationsRoute.badgeContent = invitedInvitations.length;
        }

        return {
          ...section,
          children: section.children?.filter(
            (route) =>
              route.path !== 'invitations' ||
              (invitedInvitations && invitedInvitations.length > 0)
          ),
        };
      }

      // Handle settings section
      if (section.path === '/settings') {
        return {
          ...section,
          children: section.children?.filter(
            (route) =>
              (route.path !== '/portalusers' || showPortalUsers) &&
              (route.path !== '/deleteappuser' || isSuperAdmin)
          ),
        };
      }

      // Return other sections unchanged
      return section;
    });
  }, [showPortalUsers, status, isSuperAdmin, invitedInvitations]);

  return routes;
}
