import { useEffect, useState } from 'react';
import { DocumentReference, onSnapshot } from 'firebase/firestore';
import { Patient } from './firebaseModels';
import { FirebaseError } from 'firebase/app';

type TPatientDocData = {
  status: 'loading' | 'success' | 'error';
  error: Error | null;
  data: Patient | null;
};

/**
 * Custom hook for getting a Patient from Firestore.
 * @param ref The DocumentReference of the Patient to fetch.
 * @returns TPatientData
 */
export default function usePatientDoc(ref: DocumentReference): TPatientDocData {
  const [status, setStatus] = useState<TPatientDocData['status']>('loading');
  const [data, setData] = useState<TPatientDocData['data']>(null);
  const [error, setError] = useState<TPatientDocData['error']>(null);
  const [nTimeouts, setNTimeouts] = useState(0);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      ref,
      (doc) => {
        setNTimeouts(0);
        if (doc.exists()) {
          setData({ ...doc.data(), id: doc.id } as Patient);
          setStatus('success');
        } else {
          setStatus('error');
          setError(new Error('Patient not found'));
        }
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        setData(null);

        console.error('Error getting Patient snapshot', error);
        // Incremental backoff for retrying
        const retryDelay = 1000 * (nTimeouts + 1);
        setTimeout(() => {
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [ref, nTimeouts]);

  return { status, error, data };
}
