import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
} from '@mui/material';
import { MuiFileInput } from 'mui-file-input';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CollectionsIcon from '@mui/icons-material/Collections';
import { DEFAULT_IMAGE_URL } from '../consts';

const iconLibrary = [
  'https://cdn.oneirostherapies.com/Book.png',
  'https://cdn.oneirostherapies.com/Form.png',
  'https://cdn.oneirostherapies.com/Heart.png',
  'https://cdn.oneirostherapies.com/Homework%201.png',
  'https://cdn.oneirostherapies.com/Homework%202.png',
  'https://cdn.oneirostherapies.com/Info.png',
  'https://cdn.oneirostherapies.com/Mediation.png',
  'https://cdn.oneirostherapies.com/Moon.png',
  'https://cdn.oneirostherapies.com/Sun.png',
  'https://cdn.oneirostherapies.com/Infi.png',
];

interface ImageSelectorProps {
  disabled: boolean;
  image: File | null;
  imageUrl: string;
  onImageChange: (newImage: File | null, newImageUrl: string) => void;
}

const ImageSelector: React.FC<ImageSelectorProps> = ({
  disabled,
  image,
  imageUrl,
  onImageChange,
}) => {
  const { t } = useTranslation();
  const [openLibrary, setOpenLibrary] = useState(false);

  const handleFileInputChange = (file: File | null) => {
    if (file) {
      onImageChange(file, URL.createObjectURL(file));
    } else {
      onImageChange(null, '');
    }
  };

  const handleDeleteImage = () => {
    onImageChange(null, DEFAULT_IMAGE_URL);
  };

  const handleOpenLibrary = () => {
    setOpenLibrary(true);
  };

  const handleCloseLibrary = () => {
    setOpenLibrary(false);
  };

  const handleSelectLibraryImage = (url: string) => {
    onImageChange(null, url);
    handleCloseLibrary();
  };

  return (
    <Card sx={{ mt: 2 }} variant="outlined">
      <CardHeader
        title={t('Image')}
        action={
          ((imageUrl && imageUrl !== DEFAULT_IMAGE_URL) || image) && (
            <Button
              variant="outlined"
              color="error"
              startIcon={<DeleteIcon />}
              onClick={handleDeleteImage}
              disabled={disabled}
            >
              {t('Delete')}
            </Button>
          )
        }
      />
      <CardContent>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            {(imageUrl || image) && (
              <Avatar
                variant="square"
                alt="Task Image"
                src={image ? URL.createObjectURL(image) : imageUrl}
                sx={{
                  width: 128,
                  height: 128,
                  borderRadius: 0,
                  mt: 1,
                }}
              />
            )}
          </Grid>
        </Grid>
        <Box
          sx={{
            mt: 1,
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            startIcon={<CollectionsIcon />}
            onClick={handleOpenLibrary}
            disabled={disabled}
          >
            {t('Select from Library')}
          </Button>
        </Box>
        <MuiFileInput
          disabled={disabled}
          label={t('Upload Image')}
          inputProps={{ accept: 'image/*' }}
          onChange={handleFileInputChange}
          sx={{ mt: 2 }}
          variant="outlined"
          clearIconButtonProps={{
            title: t('Clear'),
            children: <CloseIcon fontSize="small" />,
          }}
          value={image}
          fullWidth
        />

        <Dialog open={openLibrary} onClose={handleCloseLibrary}>
          <DialogTitle>
            {t('Select Image')}
            <IconButton
              aria-label="close"
              onClick={handleCloseLibrary}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1}>
              {iconLibrary.map((url, index) => (
                <Grid item key={index}>
                  <Avatar
                    variant="square"
                    src={url}
                    sx={{
                      width: 100,
                      height: 100,
                      cursor: 'pointer',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        transform: 'scale(1.05)',
                        boxShadow: 3,
                      },
                    }}
                    onClick={() => handleSelectLibraryImage(url)}
                  />
                </Grid>
              ))}
            </Grid>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default ImageSelector;
