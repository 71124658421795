import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
  Card,
  CardContent,
  Button,
  Stack,
  Alert,
} from '@mui/material';
import { getTranslatableField } from '../services/i18n';
import { useTherapyCourseInvitations } from '../firebase/useTherapyCourseInvitations';

export default function TherapyCourseInvitations() {
  const { t } = useTranslation();
  const { invitedInvitations, acceptInvitation, declineInvitation } =
    useTherapyCourseInvitations();

  if (!invitedInvitations || invitedInvitations.length === 0) {
    return (
      <Alert severity="info" sx={{ my: 2 }}>
        <Trans>You have no pending invitations</Trans>
      </Alert>
    );
  }

  return (
    <Box>
      <Typography variant="h5" component="h1" gutterBottom sx={{ my: 2 }}>
        <Trans>Pending Therapy Course Invitations</Trans>
      </Typography>

      <Stack spacing={2}>
        {invitedInvitations
          .filter((invitation) => invitation.id)
          .map((invitation) => (
            <Card key={invitation.id}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  {getTranslatableField(invitation.courseInfo?.title || {})}
                </Typography>

                <Typography variant="body2" color="text.secondary" gutterBottom>
                  <Trans>Invited by</Trans>: {invitation.invitingTherapistEmail}
                </Typography>

                <Typography variant="body2" color="text.secondary" gutterBottom>
                  <Trans>Course Type</Trans>:{' '}
                  {t(
                    invitation.courseInfo?.type === 'individual'
                      ? 'Individual'
                      : 'Group'
                  )}
                </Typography>

                <Typography variant="body2" color="text.secondary" gutterBottom>
                  <Trans>Number of Patients</Trans>:{' '}
                  {invitation.courseInfo?.patientCount}
                </Typography>

                <Box sx={{ mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => acceptInvitation?.(invitation.id!)}
                    sx={{ mr: 1 }}
                  >
                    <Trans>Accept</Trans>
                  </Button>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => declineInvitation?.(invitation.id!)}
                  >
                    <Trans>Decline</Trans>
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ))}
      </Stack>
    </Box>
  );
}
