import { ThemeProvider } from '@mui/material/styles';
import './services/i18n';
import AppRouter from './routing/AppRouter';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useCustomTheme } from './theme';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import {
  AppCheckProvider,
  AuthProvider,
  useFirebaseApp,
  FirestoreProvider,
  FunctionsProvider,
  StorageProvider,
} from 'reactfire';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAuth, connectAuthEmulator } from 'firebase/auth'; // Firebase v9+
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { FirebaseUserProvider } from './contexts/FirebaseUserContext';
import { PortalUserProvider } from './contexts/PortalUserContext';
import { UserProviderProvider } from './contexts/UserProviderContext';
import { VertexAIProvider } from './contexts/VertexAIContext';
import { PatientProvider } from './contexts/PatientContext';
import { useRef } from 'react';
import { AdminProvider } from './contexts/AdminContext';
import { AuditLogProvider } from './contexts/AuditLogContext';
import { TherapyCourseInvitationProvider } from './contexts/TherapyCourseInvitationContext';
import { devMode, useEmulator } from './consts';

/**
 * The main component of the application.
 *
 * @returns JSX.Element
 */

const APP_CHECK_TOKEN = '6LdqIyYqAAAAALVvJN5Q9Gsz45ELDRO1FoAmSAos';

declare global {
  interface Window {
    FIREBASE_APPCHECK_DEBUG_TOKEN: string;
  }
}

if (devMode) {
  /* eslint-disable no-restricted-globals */
  self.FIREBASE_APPCHECK_DEBUG_TOKEN = 'F6A9B89B-D77C-4798-B9C4-5D7B3FA4B1B9';
}
function App() {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`
  const auth = getAuth(app);
  const storage = getStorage(app);
  const functions = getFunctions(
    app,
    devMode ? 'europe-north1' : 'https://console.oneirostherapies.com'
  );
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(APP_CHECK_TOKEN),
    isTokenAutoRefreshEnabled: true,
  });
  const firestoreInstance = getFirestore(app);
  const emulatorInitialized = useRef(false);

  if (useEmulator && !emulatorInitialized.current) {
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectFirestoreEmulator(firestoreInstance, 'localhost', 8899);
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    connectStorageEmulator(storage, 'localhost', 9199);
    emulatorInitialized.current = true;
  }
  const chtTheme = useCustomTheme();
  return (
    <AppCheckProvider sdk={appCheck}>
      <AuthProvider sdk={auth}>
        <FirebaseUserProvider>
          <VertexAIProvider>
            <FirestoreProvider sdk={firestoreInstance}>
              <PortalUserProvider>
                <UserProviderProvider>
                  <AdminProvider>
                    <PatientProvider>
                      <FunctionsProvider sdk={functions}>
                        <AuditLogProvider>
                          <TherapyCourseInvitationProvider>
                            <StorageProvider sdk={storage}>
                              <ThemeProvider theme={chtTheme}>
                                <AppRouter />
                              </ThemeProvider>
                            </StorageProvider>
                          </TherapyCourseInvitationProvider>
                        </AuditLogProvider>
                      </FunctionsProvider>
                    </PatientProvider>
                  </AdminProvider>
                </UserProviderProvider>
              </PortalUserProvider>
            </FirestoreProvider>
          </VertexAIProvider>
        </FirebaseUserProvider>
      </AuthProvider>
    </AppCheckProvider>
  );
}

export default App;
