import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  AlertColor,
  Box,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  Snackbar,
  AlertTitle,
} from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import {
  MouseEventHandler,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Patient,
  TherapyCourse,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { useFirebaseUser } from '../../firebase/useFirebaseUser';
import { useRefreshPatientStatistics } from '../../hooks/useRefreshPatientStatistics';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import RefreshIcon from '@mui/icons-material/Refresh';
import useRequestToViewPatientData from '../../firebase/useRequestToViewUserData';
import AddIcon from '@mui/icons-material/Add';
import useStatisticsCacheDoc from '../../firebase/useStatisticsCacheDoc';
import { useMaxMarkingDate } from './useMaxMarkingDate';
import {
  customTabA11yProps,
  CustomTabPanel,
} from '../../components/CustomTabPanel';
import ProgressTab from './ProgressTab';
import StatisticsTableTab from './StatisticsTableTab';
import PatientPhaseTasks from './PatientPhaseTasks';
import DayGraphTab from './DayGraphTab';
import { useAppUser } from '../../contexts/PatientContext';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import UpdateIcon from '@mui/icons-material/Update';
import { ButtonGroup, Tooltip } from '@mui/material';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PendingIcon from '@mui/icons-material/Pending';
import { useRemoteLog } from '../../firebase/useRemoteLog';
import { getFormattedDateWithTimezone } from '../../utils';

type DatePeriodType = 'week' | 'twoWeeks' | 'allTime' | 'custom';
type PatientViewProps = {
  therapyCourseId: string;
  therapyCoursePatientId: string;
  course: TherapyCourse;
  patient: Patient;
  phase: TherapyCoursePhase;
  currentStartDate: Timestamp | null;
  currentEndDate: Timestamp | null;
  setCurrentStartDate: (value: SetStateAction<Timestamp | null>) => void;
  setCurrentEndDate: (value: SetStateAction<Timestamp | null>) => void;
  phaseIndex: number;
  defaultLanguage: string;
};

export default function PatientView(props: PatientViewProps) {
  const { t } = useTranslation();
  const {
    patient,
    therapyCourseId,
    therapyCoursePatientId,
    currentStartDate,
    setCurrentStartDate,
    currentEndDate,
    setCurrentEndDate,
    course,
    phase,
    phaseIndex,
    defaultLanguage,
  } = props;

  const { email } = useFirebaseUser();
  const { sendAuditLog } = useRemoteLog();
  const [datePeriod, setDatePeriod] = useState<DatePeriodType>('week');
  // const patientData = patientCollectionData?.find((p) => p.id === patient.id);
  const appUser = useAppUser(patient);

  const { sendRequest, requestSent, requestError, approved, denied } =
    useRequestToViewPatientData(patient, email);

  const {
    refreshPatientStatistics,
    disabled: refreshDisabled,
    isUpdating,
    statisticsHash,
  } = useRefreshPatientStatistics(
    therapyCoursePatientId || null,
    currentStartDate || null,
    currentEndDate || null
  );

  const { data: statisticsCache } = useStatisticsCacheDoc(
    statisticsHash,
    patient?.user?.id || null
  );

  const onClickRefreshStatistics = useCallback<
    MouseEventHandler<HTMLButtonElement>
  >(() => {
    if (
      !patient.id ||
      refreshDisabled ||
      isUpdating ||
      !refreshPatientStatistics ||
      !approved
    ) {
      return;
    }
    refreshPatientStatistics(true);
  }, [
    patient.id,
    refreshDisabled,
    isUpdating,
    refreshPatientStatistics,
    approved,
  ]);

  useEffect(() => {
    refreshPatientStatistics(false);
  }, [refreshPatientStatistics]);

  // console.log('timezone', timezone);
  // Log view access when component mounts and user is approved

  useEffect(() => {
    if (approved && patient?.id && email) {
      // Format dates with timezone information

      sendAuditLog({
        action: 'view',
        resourceType: 'patient',
        resourceId: patient.id,
        performedBy: email,
        details: {
          therapyCourseId,
          phaseIndex,
          phaseId: phase.id,
          dateRange: {
            start: getFormattedDateWithTimezone(currentStartDate),
            end: getFormattedDateWithTimezone(currentEndDate),
          },
        },
      });
    }
  }, [
    approved,
    patient.id,
    email,
    therapyCourseId,
    phaseIndex,
    currentStartDate,
    currentEndDate,
    sendAuditLog,
    phase.id,
  ]);

  const dateRange: [Dayjs | null, Dayjs | null] = useMemo(
    () => [
      currentStartDate ? dayjs(currentStartDate.toDate()) : null,
      currentEndDate ? dayjs(currentEndDate?.toDate()) : null,
    ],
    [currentStartDate, currentEndDate]
  );

  const statistics = useMemo(
    () => statisticsCache?.statistics || null,
    [statisticsCache?.statistics]
  );

  const fromDateStr = getFormattedDateWithTimezone(currentStartDate) || '';
  const toDateStr = getFormattedDateWithTimezone(currentEndDate) || '';

  const statisticsSlice = useMemo(() => {
    if (!statistics) return [];

    return statistics.filter((statistic) => {
      const markingDate = new Date(statistic.marking_date);
      return (
        (!fromDateStr || new Date(markingDate) >= new Date(fromDateStr)) &&
        (!toDateStr || new Date(markingDate) <= new Date(toDateStr))
      );
    });
  }, [statistics, fromDateStr, toDateStr]);

  const maxMarkingDate = useMaxMarkingDate();

  const maxDateAsStr =
    getFormattedDateWithTimezone(Timestamp.fromDate(maxMarkingDate)) || '';

  const [currentTab, setCurrentTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setCurrentTab(newValue);
  };

  let responseMessage: JSX.Element | null = null;
  let responseSeverity: AlertColor = 'info';

  if (!approved && !denied) {
    responseMessage = requestSent ? (
      <Trans>
        Your request to view this patient data has been sent. Please wait for
        approval.
      </Trans>
    ) : (
      <Trans>You do not have permission to view this patient's data</Trans>
    );
  } else if (denied) {
    responseSeverity = 'error';
    responseMessage = (
      <Trans>Your request to view this patient's data has been denied.</Trans>
    );
  }

  const handleQuickSelect = useCallback(
    (period: DatePeriodType) => {
      setDatePeriod(period);
      const now = new Date();
      now.setHours(0, 0, 0, 0);
      const endOfDay = new Date(now.setHours(23, 59, 59, 999));

      switch (period) {
        case 'week':
          const weekAgo = new Date();
          weekAgo.setDate(weekAgo.getDate() - 6);
          setCurrentStartDate(Timestamp.fromDate(weekAgo));
          setCurrentEndDate(Timestamp.fromDate(endOfDay));
          break;
        case 'twoWeeks':
          const twoWeeksAgo = new Date();
          twoWeeksAgo.setDate(twoWeeksAgo.getDate() - 13);
          setCurrentStartDate(Timestamp.fromDate(twoWeeksAgo));
          setCurrentEndDate(Timestamp.fromDate(endOfDay));
          break;
        case 'allTime':
          setCurrentStartDate(null);
          setCurrentEndDate(null);
          break;
      }
    },
    [setCurrentStartDate, setCurrentEndDate]
  );

  const handleDateChange = useCallback(
    (days: number) => {
      setDatePeriod('custom');
      setCurrentStartDate((prevDate) => {
        if (!prevDate) return null;
        const prevDateAsDate = prevDate.toDate();
        prevDateAsDate.setDate(prevDateAsDate.getDate() + days);
        return Timestamp.fromDate(prevDateAsDate);
      });
      setCurrentEndDate((prevDate) => {
        if (!prevDate) return null;
        const prevDateAsDate = prevDate.toDate();
        prevDateAsDate.setDate(prevDateAsDate.getDate() + days);
        return Timestamp.fromDate(prevDateAsDate);
      });
    },
    [setCurrentStartDate, setCurrentEndDate]
  );

  if (patient.pending) {
    return (
      <Alert
        severity="info"
        icon={<PendingIcon />}
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography>
          {t('This patient has not yet registered in the app')}
        </Typography>
      </Alert>
    );
  }

  if (!appUser) {
    return <CircularProgress />;
  }
  return (
    <>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6" color={'textSecondary'}>
            {patient.remark} - {patient.licenceCode}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <PatientPhaseTasks
            email={email}
            course={course}
            phase={phase}
            phaseIndex={phaseIndex}
            therapyCourseId={therapyCourseId}
            therapyCoursePatientId={therapyCoursePatientId}
            appUser={appUser}
            approved={approved}
            patient={patient}
            defaultLanguage={defaultLanguage}
          />
        </Grid>
      </Grid>
      {responseMessage ? (
        <Alert severity={responseSeverity}>
          <Typography gutterBottom>{responseMessage}</Typography>
          {(!requestSent || denied) && (
            <Button
              onClick={() => sendRequest()}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              {t('Request Access')}
            </Button>
          )}
        </Alert>
      ) : (
        <>
          <Divider sx={{ mt: 2, mb: 4 }} />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              mb: 2,
            }}
          >
            <DatePicker
              label={t('Start date')}
              value={dateRange[0]}
              closeOnSelect
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              sx={{ flex: 1 }}
              onChange={(date) => {
                setCurrentStartDate(
                  date
                    ? Timestamp.fromDate(
                        new Date(date.toDate().setHours(0, 0, 0, 0))
                      )
                    : null
                );
              }}
            />
            <Typography sx={{ mx: 2, color: 'text.secondary' }}>—</Typography>
            <DatePicker
              label={t('End date')}
              value={dateRange[1]}
              closeOnSelect
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              sx={{ flex: 1 }}
              onChange={(date) => {
                setCurrentEndDate(
                  date
                    ? Timestamp.fromDate(
                        new Date(date.toDate().setHours(23, 59, 59, 999))
                      )
                    : null
                );
              }}
            />
          </Box>
          <Box sx={{ mt: 1, mb: 2 }}>
            <Stack
              direction="row"
              spacing={1}
              alignItems="center"
              justifyContent="space-between"
            >
              <ButtonGroup aria-label="date navigation">
                <Tooltip title={t('Move back 1 week')}>
                  <Button onClick={() => handleDateChange(-7)}>
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={t('Move back 1 day')}>
                  <Button onClick={() => handleDateChange(-1)}>
                    <KeyboardArrowLeftIcon />
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <ButtonGroup aria-label="quick select" fullWidth>
                <Tooltip title={t('View data for the past week')}>
                  <Button
                    onClick={() => handleQuickSelect('week')}
                    startIcon={<CalendarTodayIcon />}
                    variant={datePeriod === 'week' ? 'contained' : 'outlined'}
                  >
                    {t('1 Week')}
                  </Button>
                </Tooltip>
                <Tooltip title={t('View data for the past two weeks')}>
                  <Button
                    onClick={() => handleQuickSelect('twoWeeks')}
                    startIcon={<DateRangeIcon />}
                    variant={
                      datePeriod === 'twoWeeks' ? 'contained' : 'outlined'
                    }
                  >
                    {t('2 Weeks')}
                  </Button>
                </Tooltip>
                <Tooltip title={t('View all available data')}>
                  <Button
                    onClick={() => handleQuickSelect('allTime')}
                    startIcon={<AllInclusiveIcon />}
                    variant={
                      datePeriod === 'allTime' ? 'contained' : 'outlined'
                    }
                  >
                    {t('All')}
                  </Button>
                </Tooltip>
              </ButtonGroup>

              <ButtonGroup aria-label="date navigation">
                <Tooltip title={t('Move forward 1 day')}>
                  <Button onClick={() => handleDateChange(1)}>
                    <KeyboardArrowRightIcon />
                  </Button>
                </Tooltip>
                <Tooltip title={t('Move forward 1 week')}>
                  <Button onClick={() => handleDateChange(7)}>
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Stack>
          </Box>

          {(appUser?.statistics_cache_updating || isUpdating) && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'info.light',
                color: 'info.contrastText',
                p: 2,
                borderRadius: 1,
                mt: 2,
              }}
            >
              <CircularProgress
                size={24}
                thickness={4}
                sx={{ mr: 2, color: 'info.contrastText' }}
              />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t('Updating Statistics')}
                </Typography>
                <Typography variant="body2">
                  {t('Please wait while we refresh the data...')}
                </Typography>
              </Box>
              <UpdateIcon sx={{ ml: 'auto', fontSize: 32 }} />
            </Box>
          )}
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              mt: 2,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              aria-label="statistics tabs"
            >
              <Tab label={t('Statistics Table')} {...customTabA11yProps(0)} />
              <Tab label={t('Progress Over Time')} {...customTabA11yProps(1)} />
              <Tab label={t('Day Graph')} {...customTabA11yProps(2)} />
            </Tabs>
            <Button
              variant="text"
              color="primary"
              loading={!!appUser?.statistics_cache_updating || isUpdating}
              disabled={
                !!appUser?.statistics_cache_updating ||
                isUpdating ||
                !approved ||
                refreshDisabled
              }
              onClick={onClickRefreshStatistics}
              startIcon={<RefreshIcon />}
            >
              {t('Refresh patient statistics')}
            </Button>
          </Box>
          <Box sx={{ minHeight: '60vh' }}>
            <CustomTabPanel value={currentTab} index={0}>
              <StatisticsTableTab
                statisticsSlice={statisticsSlice}
                fromDateString={fromDateStr}
                toDateString={toDateStr}
                maxDateAsStr={maxDateAsStr}
                patient={patient}
              />
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={1}>
              <ProgressTab
                key={statisticsHash}
                statistics={statisticsSlice}
                fromDateString={fromDateStr}
                toDateString={toDateStr}
                maxDateAsStr={maxDateAsStr}
                patient={patient}
              />
            </CustomTabPanel>
            <CustomTabPanel value={currentTab} index={2}>
              <DayGraphTab
                isUpdating={!!appUser?.statistics_cache_updating}
                statisticsSlice={statisticsSlice}
                patient={patient}
                fromDateString={fromDateStr}
                toDateString={toDateStr}
              />
            </CustomTabPanel>
          </Box>
        </>
      )}
      <Snackbar open={!!requestError}>
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error requesting access to patient data</Trans>
          </AlertTitle>
          <Trans>{requestError}</Trans>
        </Alert>
      </Snackbar>
    </>
  );
}
