import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { TranslatableField } from '../firebase/firebaseModels';

// Import translations
import enTranslations from './locales/en.json';
import fiTranslations from './locales/fi.json';

export const LANGUAGE_NAMES: { [key: string]: string } = {
  en: 'English',
  fi: 'Suomi',
  sv: 'Svenska',
};

export const UI_LANGUAGE_OPTIONS = [
  {
    code: 'en-US',
    name: 'English (American)',
  },
  {
    code: 'fi-FI',
    name: 'Suomi',
  },
];

export const THERAPY_LANGUAGE_OPTIONS = [
  {
    code: 'en',
    name: 'English',
  },
  {
    code: 'fi',
    name: 'Finnish',
  },
  {
    code: 'sv',
    name: 'Swedish',
  },
];

export const resources = {
  en: enTranslations,
  fi: fiTranslations,
};

export const getCurrentLanguageCode = () => {
  const code = i18next.language.split('-')[0];
  if (code in LANGUAGE_NAMES) {
    return code;
  }

  return 'en';
};

/**
 * i18next instance. Used for translations.
 */
export const defaultNS = 'translation';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      convertDetectedLanguage: (lng: string) => {
        console.log('convertDetectedLanguage', lng);
        return lng;
      },
    },
    ns: [defaultNS],
    defaultNS,
    resources,
    fallbackLng: 'en',
    // debug only when not in production
    // debug: process.env.NODE_ENV !== 'production',

    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;

/**
 * Get the translatable field in the user's preferred language.
 */
export function getTranslatableField(
  field: TranslatableField,
  editorLanguage?: string
): string {
  const userLang = editorLanguage || getCurrentLanguageCode();

  if (!field) return '';
  if (userLang in field) {
    return field[userLang];
  }
  if (editorLanguage) {
    return '';
  }
  const firstLanguageOfField = Object.keys(field)[0];
  return field[firstLanguageOfField] + ' [' + firstLanguageOfField + ']';
}

/**
 * Get the translatable field in the user's preferred language along with a flag indicating if it was found in the requested language.
 */
export function getTranslatableFieldWithStatus(
  field: TranslatableField,
  requestedLanguage: string
): { text: string; foundInRequestedLanguage: boolean } {
  if (!field) return { text: '', foundInRequestedLanguage: false };

  if (requestedLanguage in field) {
    return { text: field[requestedLanguage], foundInRequestedLanguage: true };
  }

  const firstLanguageOfField = Object.keys(field)[0];
  return {
    text: field[firstLanguageOfField] + ' [' + firstLanguageOfField + ']',
    foundInRequestedLanguage: false,
  };
}
