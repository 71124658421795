import { Trans } from 'react-i18next';
import { Typography, Link as MuiLink, Alert, AlertTitle } from '@mui/material';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { TherapyCourseInvitationContext } from '../contexts/TherapyCourseInvitationContext';
import { useTranslation } from 'react-i18next';

export default function PendingInvitationsNotification() {
  const { t } = useTranslation();
  const { invitedInvitations } =
    useContext(TherapyCourseInvitationContext) || {};

  if (!invitedInvitations || invitedInvitations.length === 0) {
    return null;
  }

  return (
    <Alert severity="info" sx={{ mb: 2 }}>
      <AlertTitle>
        <Trans>Pending Invitations</Trans>
      </AlertTitle>
      <Typography>
        {t(
          invitedInvitations.length > 1
            ? 'You have {{count}} pending invitations to therapy courses'
            : 'You have {{count}} pending invitation to a therapy course',
          {
            count: invitedInvitations.length,
          }
        )}
        <br />
        <MuiLink component={Link} to="/invitations">
          <Trans>View invitations</Trans>
        </MuiLink>
      </Typography>
    </Alert>
  );
}
