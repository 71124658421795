import React, { createContext, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import {
  collection,
  query,
  where,
  onSnapshot,
  DocumentData,
  QueryDocumentSnapshot,
  doc,
  updateDoc,
  serverTimestamp,
} from 'firebase/firestore';
import { TherapyCourseInvitation } from '../firebase/firebaseModels';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import { FirebaseError } from 'firebase/app';

type TInvitationStatus = 'loading' | 'error' | 'success' | 'not-logged-in';

export type TInvitationData = {
  status: TInvitationStatus;
  error: Error | null;
  invitedInvitations: TherapyCourseInvitation[];
  invitingInvitations: TherapyCourseInvitation[];
  acceptedInvitedInvitations: TherapyCourseInvitation[];
  acceptedInvitingInvitations: TherapyCourseInvitation[];
  acceptInvitation: (invitationId: string) => Promise<void>;
  declineInvitation: (invitationId: string) => Promise<void>;
};

export const TherapyCourseInvitationContext =
  createContext<TInvitationData | null>(null);

export function TherapyCourseInvitationProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const { email } = useFirebaseUser();
  const firestore = useFirestore();

  const [status, setStatus] = useState<TInvitationStatus>('loading');
  const [invitedInvitations, setInvitedInvitations] = useState<
    TherapyCourseInvitation[]
  >([]);
  const [invitingInvitations, setInvitingInvitations] = useState<
    TherapyCourseInvitation[]
  >([]);
  const [acceptedInvitedInvitations, setAcceptedInvitedInvitations] = useState<
    TherapyCourseInvitation[]
  >([]);
  const [acceptedInvitingInvitations, setAcceptedInvitingInvitations] =
    useState<TherapyCourseInvitation[]>([]);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!email) {
      setInvitedInvitations([]);
      setInvitingInvitations([]);
      setAcceptedInvitedInvitations([]);
      setAcceptedInvitingInvitations([]);
      setStatus('not-logged-in');
      setError(null);
      return;
    }

    const invitedQuery = query(
      collection(firestore, 'TherapyCourseInvitation'),
      where('invitedTherapistEmail', '==', email.toLowerCase()),
      where('status', '==', 'pending')
    );

    const invitingQuery = query(
      collection(firestore, 'TherapyCourseInvitation'),
      where('invitingTherapistEmail', '==', email.toLowerCase()),
      where('status', '==', 'pending')
    );

    const acceptedInvitedQuery = query(
      collection(firestore, 'TherapyCourseInvitation'),
      where('invitedTherapistEmail', '==', email.toLowerCase()),
      where('status', '==', 'accepted')
    );

    const acceptedInvitingQuery = query(
      collection(firestore, 'TherapyCourseInvitation'),
      where('invitingTherapistEmail', '==', email.toLowerCase()),
      where('status', '==', 'accepted')
    );

    const unsubscribeInvited = onSnapshot(
      invitedQuery,
      (snapshot) => {
        const invitedData = snapshot.docs.map(
          (doc: QueryDocumentSnapshot<DocumentData>) => ({
            ...doc.data(),
            id: doc.id,
          })
        ) as TherapyCourseInvitation[];

        setInvitedInvitations(invitedData);
        setStatus('success');
        setError(null);
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        console.error(
          'Error getting TherapyCourseInvitation snapshot with invitedQuery',
          error
        );
      }
    );

    const unsubscribeInviting = onSnapshot(
      invitingQuery,
      (snapshot) => {
        const invitingData = snapshot.docs.map(
          (doc: QueryDocumentSnapshot<DocumentData>) => ({
            ...doc.data(),
            id: doc.id,
          })
        ) as TherapyCourseInvitation[];

        setInvitingInvitations(invitingData);
        setStatus('success');
        setError(null);
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        console.error(
          'Error getting TherapyCourseInvitation snapshot with invitingQuery',
          error
        );
      }
    );

    const unsubscribeAcceptedInvited = onSnapshot(
      acceptedInvitedQuery,
      (snapshot) => {
        const acceptedData = snapshot.docs.map(
          (doc: QueryDocumentSnapshot<DocumentData>) => ({
            ...doc.data(),
            id: doc.id,
          })
        ) as TherapyCourseInvitation[];

        setAcceptedInvitedInvitations(acceptedData);
        setStatus('success');
        setError(null);
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        console.error(
          'Error getting accepted invited TherapyCourseInvitation snapshot with acceptedInvitedQuery',
          error
        );
      }
    );

    const unsubscribeAcceptedInviting = onSnapshot(
      acceptedInvitingQuery,
      (snapshot) => {
        const acceptedData = snapshot.docs.map(
          (doc: QueryDocumentSnapshot<DocumentData>) => ({
            ...doc.data(),
            id: doc.id,
          })
        ) as TherapyCourseInvitation[];

        setAcceptedInvitingInvitations(acceptedData);
        setStatus('success');
        setError(null);
      },
      (error) => {
        setStatus('error');
        setError(error as FirebaseError);
        console.error(
          'Error getting accepted inviting TherapyCourseInvitation snapshot with acceptedInvitingQuery',
          error
        );
      }
    );

    return () => {
      unsubscribeInvited();
      unsubscribeInviting();
      unsubscribeAcceptedInvited();
      unsubscribeAcceptedInviting();
    };
  }, [email, firestore]);

  const acceptInvitation = async (invitationId: string) => {
    try {
      const invitationRef = doc(
        firestore,
        'TherapyCourseInvitation',
        invitationId
      );
      await updateDoc(invitationRef, {
        status: 'accepted',
        respondedAt: serverTimestamp(),
        modifiedBy: email || '',
        modified: serverTimestamp(),
      });
    } catch (err) {
      console.error('Error accepting invitation:', err);
      setError(err as Error);
    }
  };

  const declineInvitation = async (invitationId: string) => {
    try {
      const invitationRef = doc(
        firestore,
        'TherapyCourseInvitation',
        invitationId
      );
      await updateDoc(invitationRef, {
        status: 'declined',
        respondedAt: serverTimestamp(),
        modifiedBy: email || '',
        modified: serverTimestamp(),
      });
    } catch (err) {
      console.error('Error declining invitation:', err);
      setError(err as Error);
    }
  };

  const value = {
    status,
    error,
    invitedInvitations,
    invitingInvitations,
    acceptedInvitedInvitations,
    acceptedInvitingInvitations,
    acceptInvitation,
    declineInvitation,
  };

  return (
    <TherapyCourseInvitationContext.Provider value={value}>
      {children}
    </TherapyCourseInvitationContext.Provider>
  );
}
