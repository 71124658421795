import React, { createContext, useEffect, useState } from 'react';
import { useFirestore } from 'reactfire';
import { onSnapshot } from 'firebase/firestore';
import { TherapyCourse } from '../firebase/firebaseModels';
import { useTherapyCourseInvitations } from '../firebase/useTherapyCourseInvitations';
import { DocumentReference } from 'firebase/firestore';

type TSharedCourseStatus = 'loading' | 'error' | 'success' | 'not-logged-in';

export type TSharedCourseData = {
  status: TSharedCourseStatus;
  error: Error | null;
  sharedCourses: TherapyCourse[];
  isTherapyCourseShared: (courseId: string | null) => boolean;
  getTherapyCourseRef: (
    courseId: string | null
  ) => DocumentReference<TherapyCourse> | null;
  getOwnerEmail: (courseId: string | null) => string | null;
};

export const SharedTherapyCourseContext =
  createContext<TSharedCourseData | null>(null);

export function SharedTherapyCourseProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const firestore = useFirestore();
  const { status: invitationStatus, acceptedInvitedInvitations } =
    useTherapyCourseInvitations();

  const [status, setStatus] = useState<TSharedCourseStatus>('loading');
  const [error, setError] = useState<Error | null>(null);
  const [sharedCourses, setSharedCourses] = useState<TherapyCourse[]>([]);

  useEffect(() => {
    if (invitationStatus === 'not-logged-in') {
      setStatus('not-logged-in');
      setSharedCourses([]);
      return;
    }

    const unsubscribes: (() => void)[] = [];
    let completedInitialFetches = 0;
    const totalCourses = acceptedInvitedInvitations.filter(
      (inv) => inv.therapyCourse
    ).length;

    try {
      if (totalCourses === 0) {
        setSharedCourses([]);
        setStatus('success');
        return;
      }

      acceptedInvitedInvitations.forEach((invitation) => {
        if (!invitation.therapyCourse) return;

        const courseRef = invitation.therapyCourse;
        const pathSegments = courseRef.path.split('/');
        const ownerEmail = pathSegments[3];

        const unsubscribe = onSnapshot(
          courseRef,
          (snapshot) => {
            if (snapshot.exists()) {
              setSharedCourses((prevCourses) => {
                const updatedCourse = {
                  id: snapshot.id,
                  ...snapshot.data(),
                  ownerEmail,
                } as TherapyCourse;

                const otherCourses = prevCourses.filter(
                  (course) => course.id !== snapshot.id
                );

                return [...otherCourses, updatedCourse];
              });
            } else {
              setSharedCourses((prevCourses) =>
                prevCourses.filter((course) => course.id !== snapshot.id)
              );
            }

            if (completedInitialFetches < totalCourses) {
              completedInitialFetches++;
              if (completedInitialFetches === totalCourses) {
                setStatus('success');
                setError(null);
              }
            }
          },
          (err) => {
            console.error('Error in course snapshot:', err);
            setError(err as Error);

            if (completedInitialFetches < totalCourses) {
              completedInitialFetches++;
              if (completedInitialFetches === totalCourses) {
                setStatus('error');
              }
            }
          }
        );

        unsubscribes.push(unsubscribe);
      });
    } catch (err) {
      console.error('Error setting up course listeners:', err);
      setStatus('error');
      setError(err as Error);
      setSharedCourses([]);
    }

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [firestore, invitationStatus, acceptedInvitedInvitations]);

  const isTherapyCourseShared = (courseId: string | null): boolean => {
    if (!courseId) return false;
    return sharedCourses.some((course) => course.id === courseId);
  };

  const getTherapyCourseRef = (
    courseId: string | null
  ): DocumentReference<TherapyCourse> | null => {
    if (!courseId) return null;
    const invitation = acceptedInvitedInvitations.find(
      (inv) => inv.therapyCourse?.id === courseId
    );
    return invitation?.therapyCourse || null;
  };

  const getOwnerEmail = (courseId: string | null): string | null => {
    if (!courseId) return null;
    const course = sharedCourses.find((course) => course.id === courseId);
    return course?.ownerEmail || null;
  };

  const value = {
    status,
    error,
    sharedCourses,
    isTherapyCourseShared,
    getTherapyCourseRef,
    getOwnerEmail,
  };

  return (
    <SharedTherapyCourseContext.Provider value={value}>
      {children}
    </SharedTherapyCourseContext.Provider>
  );
}
