import { Trans, useTranslation } from 'react-i18next';
import {
  Alert,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  AutocompleteValue,
  Snackbar,
  AlertTitle,
  Grid,
  Box,
  Button,
  DialogTitle,
  Tooltip,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  doc,
  updateDoc,
  DocumentReference,
  Timestamp,
} from 'firebase/firestore';
import { useCallback, useMemo, useState } from 'react';
import {
  Patient,
  TaskPoolTask,
  TherapyCourse,
  TherapyCoursePhase,
  User,
} from '../../firebase/firebaseModels';
import useUserProvider from '../../firebase/useUserProvider';
import {
  getTranslatableField,
  getTranslatableFieldWithStatus,
} from '../../services/i18n';
import { useFirestore } from 'reactfire';
// import { useUpdatePatientCourses } from '../../firebase/useUpdatePatientCourses';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import useTaskPoolTaskCategoryCollection from '../../firebase/useTaskPoolTaskCategoryCollection';
import usePortalUser from '../../firebase/usePortalUser';
import {
  TaskPoolOption,
  useTaskPoolOptions,
} from '../../hooks/useTaskPoolOptions';
import { TaskPoolSelector } from '../TherapyCourseEditor/TaskPoolSelector';
import { usePhaseTasksValue } from '../TherapyCourseEditor/usePhaseTasksValue';
import usePatientCollection from '../../firebase/usePatientCollection';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from '@hello-pangea/dnd';
import { useStrictDroppable } from '../../hooks/useStrictDroppable';
import SleepRestrictionDialog from './SleepRestrictionDialog';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useTheme } from '@mui/material/styles';
import { localeFormatTime } from '../../utils';
import { usePatientTaskOrder } from '../../hooks/usePatientTaskOrder';
import TaskMarkingHistory from './TaskMarkingHistory';
import HistoryIcon from '@mui/icons-material/History';
import { Dialog, DialogContent } from '@mui/material';
import { TaskTypeIcon } from '../TaskTypeIcon';
import TaskCategoryDot from '../TaskCategoryDot';
import WarningIcon from '@mui/icons-material/Warning';

type PatientPhaseTasksProps = {
  email: string | null;
  course: TherapyCourse;
  phase: TherapyCoursePhase;
  phaseIndex: number;
  therapyCourseId: string;
  therapyCoursePatientId: string;
  appUser: User | null;
  approved: boolean | null;
  patient: Patient | null;
  defaultLanguage: string;
};

export default function PatientPhaseTasks(props: PatientPhaseTasksProps) {
  const {
    course,
    phase,
    phaseIndex,
    email,
    therapyCoursePatientId,
    therapyCourseId,
    appUser,
    approved = false,
    patient,
    defaultLanguage,
  } = props;

  const { t, i18n } = useTranslation();

  const { data: portalUserData } = usePortalUser();
  const { data: patientCollectionData } = usePatientCollection();

  const { data: userProviderData } = useUserProvider();
  const [updateSuccessful, setUpdateSuccessful] = useState(false);

  // const { updatePatientCourses, updateError, resetUpdateStatus } =
  //   useUpdatePatientCourses();

  const firestore = useFirestore();

  const language = defaultLanguage;

  // Memoized computation of available therapy languages
  const therapyLanguages = useMemo(() => {
    let therapyLanguagesTmp = [...(portalUserData?.therapyLanguages || [])];
    if (!therapyLanguagesTmp.includes(defaultLanguage)) {
      therapyLanguagesTmp.push(defaultLanguage);
    }
    if (!therapyLanguagesTmp.includes(language)) {
      therapyLanguagesTmp.push(language);
    }
    return therapyLanguagesTmp;
  }, [portalUserData?.therapyLanguages, defaultLanguage, language]);

  const {
    status: categoryStatus,
    data: categoryData,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection();

  const { status: taskPoolStatus, data: taskPoolData } = useTaskPoolCollection(
    null,
    therapyLanguages
  );

  const [updating, setUpdating] = useState(false);

  // console.log('appUser', appUser);
  const phasePatient = useMemo(() => {
    const pp = phase.patients?.find((p) => p.id === therapyCoursePatientId);
    if (!pp) {
      const patient = patientCollectionData?.find(
        (p) => p.id === therapyCoursePatientId
      );
      if (patient) {
        return {
          activeTasks: {},
          id: therapyCoursePatientId,
          patient: doc(
            firestore,
            'Patient',
            therapyCoursePatientId
          ) as DocumentReference<Patient>,
        };
      }
      return null;
    }
    return pp;
  }, [
    phase.patients,
    therapyCoursePatientId,
    patientCollectionData,
    firestore,
  ]);

  let patientActiveTasks = useMemo(
    () => phasePatient?.activeTasks || ({} as Record<string, boolean>),
    [phasePatient?.activeTasks]
  );
  const taskHistory = useMemo(() => appUser?.taskHistory || {}, [appUser]);
  const taskPoolOptions = useTaskPoolOptions(
    taskPoolData,
    categoryData,
    portalUserData,
    defaultLanguage
  );

  const phaseTasks = useMemo(() => {
    const tasks = phase.phaseTasks || [];
    // Filter out tasks that don't exist in taskPoolData
    return tasks.filter((task) =>
      taskPoolData?.some((tpt) => tpt.id === task.id)
    );
  }, [phase, taskPoolData]);

  const phaseTasksValue: TaskPoolOption[] | null = usePhaseTasksValue(
    phaseTasks,
    taskPoolData,
    categoryData,
    defaultLanguage
  );

  const patientTaskOrder = usePatientTaskOrder(
    phasePatient,
    phaseTasks,
    taskPoolData
  );

  // Calculate task order for display, ensuring unique ordering values
  // const patientTaskOrder: {
  //   [taskId: string]: number;
  // } = useMemo(() => {
  //   if (!phasePatient?.taskOrder) {
  //     let ret: { [taskId: string]: number } = {};
  //     phaseTasks.forEach((t, idx) => {
  //       const taskData = taskPoolData?.find((tpt) => tpt.id === t.id);
  //       if (taskData && taskData.type !== 'sleepRestriction') {
  //         ret[t.id] = taskData.order || 999;
  //       }
  //     });

  //     // Sort and ensure unique ordering
  //     const taskKeys = Object.keys(ret);
  //     taskKeys.sort((a, b) => ret[a] - ret[b]);
  //     // if two consecutive tasks have the same order, add 1 to the one with the higher index
  //     for (let i = 0; i < taskKeys.length - 1; i++) {
  //       if (ret[taskKeys[i]] === ret[taskKeys[i + 1]]) {
  //         ret[taskKeys[i + 1]] += 1;
  //       }
  //     }
  //     return ret;
  //   }
  //   return phasePatient?.taskOrder;
  // }, [phasePatient?.taskOrder, phaseTasks, taskPoolData]);

  // const phaseTasksValue = useMemo(
  //   () =>
  //     phaseTasks
  //       .map((task) => {
  //         const taskPoolTask = taskPoolData?.find((tpt) => tpt.id === task.id);
  //         const category = categoryData?.find(
  //           (cat) => cat.id === taskPoolTask?.category?.id
  //         );
  //         const firstLanguage = category?.title
  //           ? Object.keys(category?.title)[0]
  //           : null;
  //         const categoryTitle =
  //           category?.title[language] ||
  //           category?.title[firstLanguage || ''] ||
  //           null;
  //         const categoryColor = category?.color || null;

  //         return {
  //           id: taskPoolTask?.id || '',
  //           title: taskPoolTask?.title || { [language]: '' },
  //           categoryTitle: categoryTitle || t('Uncategorized'),
  //           categoryColor: categoryColor || '#000000',
  //         };
  //       })
  //       .filter((v) => v.id !== ''),
  //   [phaseTasks, taskPoolData, categoryData, language, t]
  // );
  const onChangePhaseTasks = useCallback(
    (
      _: any,
      newValue: AutocompleteValue<
        {
          id: string;
          translatedTitle: string;
          categoryTitle: string;
          categoryColor: string;
        },
        true,
        false,
        false
      >
    ) => {
      // console.log('onChangePhaseTasks', {
      //   userProviderData,
      //   email,
      //   patientActiveTasks,
      //   phasePatient,
      //   props,
      //   course,
      //   patientCollectionData,
      // });
      if (
        !userProviderData?.id ||
        !email ||
        !patientActiveTasks ||
        !phasePatient ||
        !phase ||
        !course ||
        !patientCollectionData ||
        !therapyCourseId
      ) {
        return;
      }
      // console.log('onChangePhaseTasks', {
      //   newValue,
      // });
      // did we add a new task?

      const newTask = newValue.find(
        (v) => !phaseTasks.find((t) => t.id === v.id)
      );

      let newPhase = { ...phase };

      newPhase.phaseTasks = newValue.map(
        (v) =>
          doc(
            firestore,
            'TaskPool',
            v.id as string
          ) as DocumentReference<TaskPoolTask>
      );

      if (newTask?.id) {
        // new task default is disabled
        newPhase.patients = newPhase.patients?.map((p) => {
          return {
            ...p,
            activeTasks: {
              ...p.activeTasks,
              [newTask.id]: false,
            },
          };
        });
      }
      const newCourse = {
        id: therapyCourseId,
        ...course,
        phases: course.phases.map((ph, idx) =>
          idx === phaseIndex ? newPhase : ph
        ),
      };

      // Use ownerEmail if course is shared
      const userEmail =
        course.isShared && course.ownerEmail ? course.ownerEmail : email;

      const therapyCourseRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        userEmail,
        'TherapyCourse',
        therapyCourseId
      );
      setUpdating(true);
      updateDoc(therapyCourseRef, {
        phases: newCourse.phases,
      })
        .then(async () => {
          // if (course.patients) {
          //   await updatePatientCourses(newCourse, [...course.patients]);
          // }
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdating(false);
        });
    },
    [
      userProviderData,
      email,
      patientActiveTasks,
      phasePatient,
      course,
      patientCollectionData,
      phase,
      therapyCourseId,
      firestore,
      phaseTasks,
      phaseIndex,
    ]
  );

  const handleCheckboxChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, taskId: string) => {
      // console.log('handleCheckboxChange', {
      //   userProviderData,
      //   email,
      //   patientActiveTasks,
      //   phasePatient,
      //   phase,
      //   course,
      //   patientCollectionData,
      // });

      if (
        !userProviderData?.id ||
        !email ||
        !patientActiveTasks ||
        !phasePatient ||
        !phase ||
        !course ||
        !patientCollectionData ||
        !therapyCourseId
      ) {
        return;
      }

      const { checked } = e.target;

      const newActiveTasks = { ...patientActiveTasks, [taskId]: checked };
      const newPhasePatient = {
        ...phasePatient,
        activeTasks: newActiveTasks,
      };
      // console.log('newPhasePatient', newPhasePatient);
      const newPhasePatients = [
        ...phase.patients.filter((p) => p.id !== therapyCoursePatientId),
        newPhasePatient,
      ];
      const newPhase = {
        ...phase,
        patients: newPhasePatients,
      };
      const newCourse = {
        id: therapyCourseId,
        ...course,
        phases: course.phases.map((ph, idx) =>
          ph.id === newPhase.id ? newPhase : ph
        ),
      };
      // console.log('newCourse', newCourse);

      // Use ownerEmail if course is shared
      const userEmail =
        course.isShared && course.ownerEmail ? course.ownerEmail : email;

      const therapyCourseRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        userEmail,
        'TherapyCourse',
        therapyCourseId
      );
      setUpdating(true);
      updateDoc(therapyCourseRef, {
        phases: newCourse.phases,
      })
        .then(async () => {
          // console.log('newCourse', newCourse);
          // console.log('course.patients', course.patients);
          // if (course.patients) {
          //   await updatePatientCourses(newCourse, [...course.patients]);
          // }
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdating(false);
        });
    },
    [
      userProviderData,
      email,
      patientActiveTasks,
      phasePatient,
      phase,
      course,
      patientCollectionData,
      therapyCourseId,
      firestore,
      therapyCoursePatientId,
    ]
  );

  const grid = 1;
  const getListStyle = useCallback((isDraggingOver: boolean): any => {
    return {
      padding: grid,
    };
  }, []);

  const theme = useTheme();

  const getItemStyle = useCallback(
    (
      isDragging: boolean,
      draggableStyle?: DraggingStyle | NotDraggingStyle
    ): any => ({
      userSelect: 'none',
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(2),
      background: isDragging ? theme.palette.action.hover : 'transparent',
      ...draggableStyle,
      display: 'flex',
      alignItems: 'center',
      border: '1px solid',
      borderColor: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
    }),
    [theme]
  );

  // Handle drag and drop reordering of tasks
  const onDragEnd = useCallback(
    (result: any) => {
      if (
        !result.destination ||
        !userProviderData?.id ||
        !email ||
        !therapyCourseId
      ) {
        return;
      }

      // Calculate new task order based on drag position
      let newTaskOrder = { ...patientTaskOrder };

      const reorder = (
        list: string[],
        startIndex: number,
        endIndex: number
      ): string[] => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
      };

      const taskKeys = Object.keys(patientTaskOrder);
      const sortedTaskKeysOriginal = taskKeys.sort(
        (a, b) => patientTaskOrder[a] - patientTaskOrder[b]
      );

      const movedItemIdx = result.destination.index;

      // inject the moved item in the right position
      const sortedTaskKeys = reorder(
        sortedTaskKeysOriginal,
        result.source.index,
        result.destination.index
      );

      const previousItemIdx = movedItemIdx > 0 ? movedItemIdx - 1 : null;
      const nextItemIdx =
        movedItemIdx < sortedTaskKeys.length - 1 ? movedItemIdx + 1 : null;
      const previousItemKey =
        previousItemIdx !== null ? sortedTaskKeys[previousItemIdx] : null;
      const nextItemKey =
        nextItemIdx !== null ? sortedTaskKeys[nextItemIdx] : null;
      const previousItemOrder =
        previousItemKey !== null ? patientTaskOrder[previousItemKey] : 0;
      const nextItemOrder =
        nextItemKey !== null ? patientTaskOrder[nextItemKey] : null;

      const movedItem = result.draggableId as string;

      if (nextItemOrder === null) {
        newTaskOrder[movedItem] =
          previousItemOrder !== null ? previousItemOrder + 10 : 10;
      } else if (nextItemOrder - previousItemOrder <= 10) {
        // add 20 to all items after previousItemIdx
        sortedTaskKeys.forEach((to, idx) => {
          if (idx >= nextItemIdx) {
            newTaskOrder[to] += 20;
          }
        });
        newTaskOrder[movedItem] = previousItemOrder + 10;
      } else {
        newTaskOrder[movedItem] = Math.round(
          (previousItemOrder + nextItemOrder) / 2
        );
      }

      const newPhasePatient = {
        ...phasePatient,
        taskOrder: newTaskOrder,
      };

      const newPhase = {
        ...phase,
        patients: phase.patients.map((p) =>
          p.id === therapyCoursePatientId ? newPhasePatient : p
        ),
      };

      const newCourse = {
        id: therapyCourseId,
        ...course,
        phases: course.phases.map((ph, idx) =>
          ph.id === newPhase.id ? newPhase : ph
        ),
      };

      // Use ownerEmail if course is shared
      const userEmail =
        course.isShared && course.ownerEmail ? course.ownerEmail : email;

      const therapyCourseRef = doc(
        firestore,
        'ServiceProvider',
        userProviderData.id,
        'UserData',
        userEmail,
        'TherapyCourse',
        therapyCourseId
      );
      setUpdating(true);
      updateDoc(therapyCourseRef, {
        phases: newCourse.phases,
      })
        .then(async () => {
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          console.error('Error updating document: ', error);
          setUpdating(false);
        });
    },
    [
      course,
      email,
      firestore,
      patientTaskOrder,
      phase,
      phasePatient,
      therapyCourseId,
      therapyCoursePatientId,
      userProviderData?.id,
    ]
  );

  const [enabled] = useStrictDroppable(!taskPoolData || !taskPoolOptions);

  const [sleepRestrictionDialogOpen, setSleepRestrictionDialogOpen] =
    useState(false);

  const handleOpenSleepRestrictionDialog = useCallback((taskId: string) => {
    setSleepRestrictionDialogOpen(true);
  }, []);

  const renderSleepRestrictionTask = useCallback(
    (taskPoolTask: TaskPoolTask, phaseTaskId: string) => {
      const isTaskEnabled =
        phaseTaskId in patientActiveTasks
          ? patientActiveTasks[phaseTaskId]
          : true;

      const { text: translatedTitle } = getTranslatableFieldWithStatus(
        taskPoolTask.title,
        defaultLanguage
      );
      return (
        <Grid container key={phaseTaskId} sx={{ mt: 2, mb: 2 }}>
          <Grid item xs={6}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    disabled={updating}
                    checked={isTaskEnabled}
                    onChange={(e) => handleCheckboxChange(e, phaseTaskId)}
                  />
                }
                label={translatedTitle}
              />
            </FormGroup>
            {isTaskEnabled &&
              appUser?.sleepRestriction?.targetLayDownTime &&
              appUser?.sleepRestriction?.targetWakeupTime && (
                <Typography variant="body2" color="textSecondary">
                  {t('Target time in bed: {{layDownTime}} - {{wakeUpTime}}', {
                    layDownTime: localeFormatTime(
                      i18n.language,
                      new Date(
                        `2000-01-01T${appUser.sleepRestriction.targetLayDownTime}:00`
                      )
                    ),
                    wakeUpTime: localeFormatTime(
                      i18n.language,
                      new Date(
                        `2000-01-01T${appUser.sleepRestriction.targetWakeupTime}:00`
                      )
                    ),
                  })}
                </Typography>
              )}
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {approved && isTaskEnabled && (
              <Button
                variant="outlined"
                name="view-sleep-restriction"
                onClick={() => handleOpenSleepRestrictionDialog(phaseTaskId)}
                startIcon={<VisibilityIcon />}
              >
                {t('View')}
              </Button>
            )}
          </Grid>
        </Grid>
      );
    },
    [
      patientActiveTasks,
      defaultLanguage,
      updating,
      appUser?.sleepRestriction?.targetLayDownTime,
      appUser?.sleepRestriction?.targetWakeupTime,
      t,
      i18n.language,
      approved,
      handleCheckboxChange,
      handleOpenSleepRestrictionDialog,
    ]
  );

  const [selectedTask, setSelectedTask] = useState<TaskPoolTask | null>(null);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);

  const handleOpenHistory = useCallback((task: TaskPoolTask) => {
    setSelectedTask(task);
    setHistoryDialogOpen(true);
  }, []);

  const handleCloseHistory = useCallback(() => {
    setHistoryDialogOpen(false);
    setSelectedTask(null);
  }, []);

  if (
    categoryStatus === 'loading' ||
    taskPoolStatus === 'loading' ||
    !taskPoolOptions
  ) {
    return <CircularProgress />;
  }

  // console.log('phaseTasks', phaseTasks);
  return (
    taskPoolData && (
      <>
        <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
          <Trans>Tasks for this Phase</Trans>
        </Typography>
        <TaskPoolSelector
          taskPoolOptions={taskPoolOptions}
          phaseTasksValue={phaseTasksValue}
          onChangePhaseTasks={onChangePhaseTasks}
        />
        <Box sx={{ mt: 3 }}>
          {phaseTasks
            .filter((phaseTask) => {
              const taskPoolTask = taskPoolData.find(
                (tpt) => tpt.id === phaseTask.id
              );
              return taskPoolTask && taskPoolTask.type === 'sleepRestriction';
            })
            .map((phaseTask) => {
              const taskPoolTask = taskPoolData.find(
                (tpt) => tpt.id === phaseTask.id
              );
              return (
                taskPoolTask &&
                renderSleepRestrictionTask(taskPoolTask, phaseTask.id)
              );
            })}

          <DragDropContext onDragEnd={onDragEnd}>
            {enabled && (
              <Droppable droppableId="tasks" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {phaseTasks
                      .sort((a, b) => {
                        const orderA = Array.isArray(patientTaskOrder)
                          ? patientTaskOrder.find((task) => task.id === a.id)
                              ?.order || 999
                          : patientTaskOrder[a.id] || 999;
                        const orderB = Array.isArray(patientTaskOrder)
                          ? patientTaskOrder.find((task) => task.id === b.id)
                              ?.order || 999
                          : patientTaskOrder[b.id] || 999;
                        return orderA - orderB;
                      })
                      .filter((phaseTask) => {
                        const taskPoolTask = taskPoolData.find(
                          (tpt) => tpt.id === phaseTask.id
                        );
                        return (
                          taskPoolTask &&
                          taskPoolTask.type !== 'sleepRestriction'
                        );
                      })
                      .map((phaseTask, index) => {
                        const phaseTaskId = phaseTask.id;
                        const taskPoolTask = taskPoolData.find(
                          (tpt) => tpt.id === phaseTaskId
                        );
                        const { log } = taskHistory[phaseTaskId] || { log: [] };
                        const lastCompletedTime = log
                          .filter((l) => l.phaseId === phase.id)
                          .reduce<Timestamp | null>((acc, cur) => {
                            if (
                              !acc ||
                              cur.completedTime.toDate() > acc.toDate()
                            ) {
                              return cur.completedTime;
                            }
                            return acc;
                          }, null);
                        const taskLastDoneStr = lastCompletedTime
                          ? lastCompletedTime.toDate().toLocaleDateString()
                          : '';
                        const {
                          text: translatedTitle,
                          foundInRequestedLanguage,
                        } = getTranslatableFieldWithStatus(
                          taskPoolTask?.title || {},
                          defaultLanguage
                        );
                        return taskPoolTask ? (
                          <Draggable
                            key={phaseTaskId}
                            draggableId={phaseTaskId}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <div
                                  {...provided.dragHandleProps}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <DragIndicatorIcon
                                    sx={{ mr: 1, color: 'text.secondary' }}
                                  />
                                </div>
                                <Grid container>
                                  <Grid item xs={8} key={phaseTaskId}>
                                    <FormGroup key={phaseTaskId} row>
                                      <FormControlLabel
                                        control={
                                          <Switch
                                            disabled={updating}
                                            checked={
                                              phaseTaskId in patientActiveTasks
                                                ? patientActiveTasks[
                                                    phaseTaskId
                                                  ]
                                                : true
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                e,
                                                phaseTaskId
                                              )
                                            }
                                          />
                                        }
                                        label={
                                          <Box
                                            sx={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              gap: 1,
                                            }}
                                          >
                                            {!foundInRequestedLanguage && (
                                              <Tooltip
                                                title={t(
                                                  'Task not available in selected language'
                                                )}
                                              >
                                                <WarningIcon
                                                  color="warning"
                                                  sx={{ mr: 1 }}
                                                />
                                              </Tooltip>
                                            )}
                                            {translatedTitle}
                                            {approved &&
                                              taskHistory[phaseTaskId]?.log
                                                ?.length > 0 && (
                                                <Button
                                                  size="small"
                                                  variant="outlined"
                                                  onClick={() =>
                                                    handleOpenHistory(
                                                      taskPoolTask
                                                    )
                                                  }
                                                  startIcon={<HistoryIcon />}
                                                >
                                                  {t('History')}
                                                </Button>
                                              )}
                                          </Box>
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={4}
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'flex-end',
                                      gap: 1,
                                    }}
                                  >
                                    {approved ? (
                                      taskPoolTask.type ===
                                      'sleepRestriction' ? (
                                        <Button
                                          variant="outlined"
                                          name="view-sleep-restriction"
                                          onClick={() =>
                                            handleOpenSleepRestrictionDialog(
                                              phaseTaskId
                                            )
                                          }
                                          startIcon={<VisibilityIcon />}
                                        >
                                          {t('View')}
                                        </Button>
                                      ) : taskLastDoneStr ? (
                                        <Typography
                                          variant="body1"
                                          color="textSecondary"
                                        >
                                          {t('Done')}: {taskLastDoneStr}
                                        </Typography>
                                      ) : (
                                        <Typography
                                          variant="body1"
                                          color="error"
                                        >
                                          {t('Not done')}
                                        </Typography>
                                      )
                                    ) : (
                                      <></>
                                    )}
                                    <TaskCategoryDot
                                      color={
                                        categoryData?.find(
                                          (cat) =>
                                            cat.id === taskPoolTask.category?.id
                                        )?.color || null
                                      }
                                      categoryName={
                                        getTranslatableField(
                                          categoryData?.find(
                                            (cat) =>
                                              cat.id ===
                                              taskPoolTask.category?.id
                                          )?.title ?? {}
                                        ) || t('Uncategorized')
                                      }
                                      sx={{ ml: 1 }}
                                    />
                                    <TaskTypeIcon type={taskPoolTask.type} />
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </Draggable>
                        ) : null;
                      })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </DragDropContext>
        </Box>
        <Snackbar
          open={updateSuccessful}
          autoHideDuration={6000}
          onClose={() => setUpdateSuccessful(false)}
        >
          <Alert onClose={() => setUpdateSuccessful(false)} severity="success">
            <Trans>Patient data updated</Trans>
          </Alert>
        </Snackbar>

        <Snackbar
          open={!!collectionError}
          autoHideDuration={6000}
          onClose={() => setUpdateSuccessful(false)}
        >
          <Alert onClose={() => setUpdateSuccessful(false)} severity="error">
            <AlertTitle>
              <Trans>Error fetching task pool data</Trans>
            </AlertTitle>
            <Trans>{collectionError?.message}</Trans>
          </Alert>
        </Snackbar>
        {/* <Snackbar
        open={!!updateError}
        autoHideDuration={6000}
        onClose={resetUpdateStatus}
      >
        <Alert onClose={resetUpdateStatus} severity="error">
          <AlertTitle>
            <Trans>Error updating patient data</Trans>
          </AlertTitle>
          <Trans>{updateError?.message}</Trans>
        </Alert>
      </Snackbar> */}
        <SleepRestrictionDialog
          open={sleepRestrictionDialogOpen}
          onClose={() => setSleepRestrictionDialogOpen(false)}
          appUser={appUser}
          therapyCoursePatientId={therapyCoursePatientId}
          phasePatient={phasePatient}
        />
        <Dialog
          open={historyDialogOpen}
          onClose={handleCloseHistory}
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle>
            {patient ? (
              <>
                {patient.licenceCode}
                {patient.remark ? ` - ${patient.remark}` : ''}:{' '}
                {selectedTask?.title
                  ? getTranslatableField(selectedTask.title)
                  : ''}
              </>
            ) : selectedTask?.title ? (
              getTranslatableField(selectedTask.title)
            ) : (
              ''
            )}
          </DialogTitle>
          <DialogContent>
            <TaskMarkingHistory
              userId={appUser?.id || ''}
              task={selectedTask}
              patient={patient}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  );
}
