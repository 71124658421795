import { useContext } from 'react';
import { SharedTherapyCourseContext, TSharedCourseData } from '../contexts/SharedTherapyCourseContext';

export const useSharedTherapyCourses = (): TSharedCourseData => {
  const context = useContext(SharedTherapyCourseContext);

  if (!context) {
    throw new Error(
      'useSharedTherapyCourses must be used within a SharedTherapyCourseProvider'
    );
  }

  return context;
}; 