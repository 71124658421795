import { Trans, useTranslation } from 'react-i18next';
import { Alert, AlertTitle, CircularProgress, Typography } from '@mui/material';
import {
  DataGrid,
  GridToolbar,
  GridColDef,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { getTranslatableField } from '../services/i18n';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useCallback, useMemo, useState } from 'react';
import { deleteDoc, doc } from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import { TaskPoolTaskCategory } from '../firebase/firebaseModels';
import { htmlToText } from 'html-to-text';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';
import useUserProvider from '../firebase/useUserProvider';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import Tooltip from '@mui/material/Tooltip';
import usePortalUser from '../firebase/usePortalUser';
import TaskCategoryDot from '../components/TaskCategoryDot';

type TaskPoolTaskCategoriesProps = {
  taskcategorytype: string;
};

export default function TaskPoolTaskCategories(
  props: TaskPoolTaskCategoriesProps
) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const firestore = useFirestore();

  const { email } = useFirebaseUser();
  const {
    data: portalUserData,
    isSuperAdmin,
    isOrganizationAdmin,
    providerRef,
  } = usePortalUser();

  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [dialogTitle, setDialogTitle] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const { taskcategorytype } = props;

  const canAdd = useMemo(() => {
    return (
      (taskcategorytype === 'default' && isSuperAdmin) ||
      (taskcategorytype === 'organization' && isOrganizationAdmin) ||
      taskcategorytype === 'own'
    );
  }, [taskcategorytype, isSuperAdmin, isOrganizationAdmin]);

  const therapyLanguages = useMemo(() => {
    return portalUserData?.therapyLanguages || [];
  }, [portalUserData]);

  const {
    status,
    data,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection(taskcategorytype, therapyLanguages);

  const handleDelete = useCallback(
    (therapyPlanId: string, therapyPlanTitle: string) => {
      setDeleteId(therapyPlanId);
      setDialogTitle(t('Delete Category') + ' ' + therapyPlanTitle);
      setDialogContent(t('Are you sure you want to delete this category?'));
      setDialogOpen(true);
    },
    [t]
  );

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  const confirmDelete = useCallback(() => {
    setDialogOpen(false);
    if (!deleteId || !userProviderData?.id || !email) {
      return;
    }
    const ref = doc(firestore, 'TaskPoolTaskCategories', deleteId);
    deleteDoc(ref)
      .then(() => {
        console.log('Document successfully deleted!');
      })
      .catch((error) => {
        console.error('Error removing document: ', error);
        setError(error);
      });
  }, [deleteId, email, firestore, userProviderData?.id]);

  const columns: GridColDef<TaskPoolTaskCategory>[] = useMemo(
    () => [
      {
        field: 'color',
        headerName: t('Color'),
        width: 40,
        renderCell: ({ value, colDef }) => (
          <div
            style={{
              display: 'inline-flex',
              width: colDef.computedWidth,
              alignItems: 'center',
              height: '100%',
            }}
          >
            <TaskCategoryDot
              color={value || null}
              categoryName={value}
              size={12}
              sx={{ ml: 1 }}
            />
          </div>
        ),
      },
      {
        field: 'title',
        headerName: t('Title'),
        flex: 1,
        valueGetter: (_, row) => getTranslatableField(row.title),
      },
      {
        field: 'description',
        headerName: t('Description'),
        flex: 1,
        valueGetter: (_, row) =>
          htmlToText(getTranslatableField(row.description)),
      },
      { field: 'languages', headerName: t('Languages'), width: 150 },

      {
        field: 'actions',
        type: 'actions',
        width: 100,
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        getActions: ({ id, row }) => {
          const route = `/admin/taskcategories/${taskcategorytype}/${row.id}`;
          const { amOwner, provider } = row;
          const canEdit =
            amOwner ||
            isSuperAdmin ||
            (provider?.id === providerRef?.id && isOrganizationAdmin);

          return canEdit
            ? [
                <Tooltip title={t('Edit')}>
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<EditIcon />}
                    label={t('Edit')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
                <Tooltip title={t('Delete')}>
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<DeleteIcon />}
                    label={t('Delete')}
                    onClick={() =>
                      handleDelete(
                        row.id || '',
                        getTranslatableField(row.title)
                      )
                    }
                    color="inherit"
                  />
                </Tooltip>,
              ]
            : [
                <Tooltip title={t('View')}>
                  <GridActionsCellItem
                    disabled={!row.id}
                    icon={<LinkIcon />}
                    label={t('View')}
                    className="textPrimary"
                    color="inherit"
                    onClick={() => navigate(route)}
                  />
                </Tooltip>,
              ];
        },
      },
    ],
    [
      t,
      taskcategorytype,
      isSuperAdmin,
      providerRef?.id,
      isOrganizationAdmin,
      navigate,
      handleDelete,
    ]
  );

  if (status === 'loading' || userProviderStatus === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      {error && <Typography color="error">{error.message}</Typography>}
      {collectionError && (
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error Getting Categories</Trans>
          </AlertTitle>
          <Trans>{collectionError.message}</Trans>
        </Alert>
      )}
      <Typography variant="h5" component="h2" sx={{ mb: 2 }}>
        <Trans>Categories</Trans>
      </Typography>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: 'title', sort: 'asc' }],
          },
        }}
        autoHeight
        rows={data || []}
        columns={columns}
        rowSelection={false}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      {canAdd && (
        <Button
          component={Link}
          to={`/admin/taskcategories/${taskcategorytype}/new`}
          variant="contained"
          startIcon={<AddIcon />}
          sx={{ mt: 2 }}
        >
          {t('New Category')}
        </Button>
      )}
      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle || <Trans>Delete Category</Trans>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent || (
              <Trans>Are you sure you want to delete this category?</Trans>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={confirmDelete} color="primary" autoFocus>
            <Trans>Confirm</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
