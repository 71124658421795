import Color from 'color';
import { TimestampProperty } from './components/TherapyCourseOverview/types';
import { Timestamp } from 'firebase/firestore';
export const DAY_IN_MILLIS = 24 * 60 * 60 * 1000;

export function deepEqual(object1: object | null, object2: object | null) {
  const keys1: string[] = isObject(object1) ? Object.keys(object1) : [];
  const keys2: string[] = isObject(object2) ? Object.keys(object2) : [];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1: any = isObject(object1) && object1[key];
    const val2: any = isObject(object2) && object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }

  return true;
}

interface Indexable {
  [key: string]: any;
}

function isObject(object: Indexable | null): object is Indexable {
  return object != null && typeof object === 'object';
}

export function getFormattedDate(date: Date) {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return `${year}-${fixedLengthNumber(month)}-${fixedLengthNumber(day)}`;
}

export const fixedLengthNumber = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

/**
 * Interface representing a time of day relative to a specific date
 */
export interface RelativeTimeOfDay {
  /** The time of day as a Date object */
  timeofday: Date | null;
  /** The reference date as a string */
  marking_date: string;
}

/**
 * Compares two relative times of day
 * @param rtod1 First relative time of day
 * @param rtod2 Second relative time of day
 * @returns true if rtod1 is earlier than rtod2, false otherwise, null if invalid input
 */
export function compareTimeOfDay(
  rtod1: RelativeTimeOfDay,
  rtod2: RelativeTimeOfDay
) {
  if (!rtod1.timeofday || !(rtod1.timeofday instanceof Date)) {
    return null;
  }
  if (!rtod2.timeofday || !(rtod2.timeofday instanceof Date)) {
    return null;
  }
  const timeofday1 = rtod1.timeofday;
  const marking_date1 = rtod1.marking_date;
  const timeofday2 = rtod2.timeofday;
  const marking_date2 = rtod2.marking_date;

  const date1 = new Date(marking_date1);
  const date2 = new Date(marking_date2);

  const time1 = timeofday1.getTime() - date1.getTime();
  const time2 = timeofday2.getTime() - date2.getTime();

  return time1 < time2;
}

/**
 * Interface representing a time of day as milliseconds since midnight
 */
export interface RelativeTimeOfDayAsNumber {
  /** Number of milliseconds since midnight */
  timeofdayAsNumber: number;
  /** The reference date as a string */
  marking_date: string;
}

/**
 * Converts a RelativeTimeOfDay to milliseconds since midnight
 * @param rtod The relative time of day to convert
 * @returns The time as milliseconds since midnight, or null if invalid input
 */
export function getRelativeTimeAsNumber(
  rtod: RelativeTimeOfDay
): RelativeTimeOfDayAsNumber | null {
  if (rtod.timeofday === null || !(rtod.timeofday instanceof Date)) {
    return null;
  }
  const { marking_date, timeofday } = rtod;
  const markingDateObj = new Date(marking_date);
  const relativeTime = timeofday.getTime() - markingDateObj.getTime();
  return {
    timeofdayAsNumber: relativeTime,
    marking_date,
  };
}

export function getRelativeTimeOfDayFromNumber(
  rtodan: RelativeTimeOfDayAsNumber
): RelativeTimeOfDay | null {
  if (
    rtodan.timeofdayAsNumber === null ||
    typeof rtodan.timeofdayAsNumber !== 'number'
  ) {
    return null;
  }

  const { marking_date, timeofdayAsNumber } = rtodan;
  const markingDateObj = new Date(marking_date);
  const timeofday = new Date(markingDateObj.getTime() + timeofdayAsNumber);
  return {
    timeofday,
    marking_date,
  };
}

export function minutesToHoursAndMinutes(minutes: number) {
  if (minutes === null || minutes === undefined) {
    return '';
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = Math.floor(minutes % 60);
  const hoursStr = hours > 0 ? `${hours}h` : '';
  const minutesStr = `${remainingMinutes}m`;
  return `${hoursStr} ${minutesStr}`;
}
export const formatTime = (locale: string, timestamp: TimestampProperty) => {
  const date = timestampPropertyAsDate(timestamp);
  return date
    ? date.toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      })
    : undefined;
};

export function timestampPropertyAsDate(property: TimestampProperty) {
  if (!property) {
    return null;
  }
  if (property instanceof Date || typeof property === 'string') {
    return new Date(property);
  }
  return property.toDate && property.toDate();
}

export function localeFormatTime(locale: string, time?: number | Date | null) {
  return time
    ? new Date(time).toLocaleTimeString(locale, {
        hour: '2-digit',
        minute: '2-digit',
      })
    : '';
}

export function localeFormatDate(locale: string, date?: string | Date | null) {
  return date
    ? new Date(date).toLocaleDateString(locale, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
    : '';
}

/**
 * Recursively remove any unserialized data from an object
 */
export function getClearedObject(
  obj: Record<string, any>
): Record<string, any> {
  const clearedObj: Record<string, any> = { ...obj };
  for (const key in clearedObj) {
    if (clearedObj[key] instanceof Date) {
      clearedObj[key] = clearedObj[key].toISOString();
    } else if (clearedObj[key] instanceof Object) {
      clearedObj[key] = getClearedObject(clearedObj[key]);
    } else if (clearedObj[key] instanceof Function) {
      delete clearedObj[key];
    } else if (clearedObj[key] === undefined) {
      delete clearedObj[key];
    }
  }
  return clearedObj;
}

export function samePageLinkNavigation(
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) {
  if (
    event.defaultPrevented ||
    event.button !== 0 || // ignore everything but left-click
    event.metaKey ||
    event.ctrlKey ||
    event.altKey ||
    event.shiftKey
  ) {
    return false;
  }
  return true;
}

export function contrastColor(color: string): string {
  try {
    const inputColor = Color(color);

    // Counting the perceptive luminance - human eye favors green color...
    const luminance = inputColor.luminosity();

    // bright colors - black font, dark colors - white font
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  } catch (error) {
    console.error('Invalid color format:', error);
    return '#000000'; // Default to black if color is invalid
  }
}

export function stripHtml(s: string) {
  if (!s) {
    return '';
  }

  // First replace common line break tags with spaces
  const withoutBreaks = s
    .replace(/<br\s*\/?>/gi, ' ')
    .replace(/<\/p>/gi, ' ')
    .replace(/<p>/gi, '');

  // Create a DOMParser to safely parse the HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(withoutBreaks, 'text/html');

  // Get text content and normalize whitespace
  return doc.body.textContent?.replace(/\s+/g, ' ').trim() || '';
}

export function getFilenameFromUrl(url: string | null) {
  // eg. https://example.com/x/file.pdf?o=12345&foo=bar -> file.pdf
  // valid url ?
  if (!url || !url.includes('/')) {
    return null;
  }
  const parts = url.split('/');
  const filename = parts[parts.length - 1];
  const filenameParts = filename.split('?');
  return decodeURIComponent(filenameParts[0]);
}

/**
 * Formats a timestamp in YYYY-MM-DD format using the specified timezone
 * @param timestamp The Firebase Timestamp to format
 * @param timezone Optional timezone (defaults to local timezone)
 * @param defaultString Optional default string to return if timestamp is null
 * @returns Formatted date string in YYYY-MM-DD format or default string if timestamp is null
 */
export const getFormattedDateWithTimezone = (
  timestamp: Timestamp | null,
  defaultString?: string | null,
  timezone?: string
): string | null => {
  if (!timestamp) return defaultString || null;
  const date = timestamp.toDate();
  const defaultTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const tz = timezone || defaultTimeZone;

  // Create a date formatter for the specific timezone
  const formatter = new Intl.DateTimeFormat('en-CA', {
    timeZone: tz,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  // Get the formatted parts and create YYYY-MM-DD format
  // The en-CA locale uses YYYY-MM-DD format by default
  return formatter.format(date);
};
