import { useTranslation } from 'react-i18next';
import {
  Card,
  CardHeader,
  CardContent,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  Tooltip,
  Divider,
  Button,
  Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingIcon from '@mui/icons-material/Pending';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCallback, useState, useMemo } from 'react';
import {
  TherapyCourseInvitation,
  PortalUser,
  TherapyCourse,
} from '../../firebase/firebaseModels';
import { useTherapyCourseInvitations } from '../../firebase/useTherapyCourseInvitations';
import InviteTherapistDialog from './InviteTherapistDialog';
import { DocumentReference } from 'firebase/firestore';
import { usePortalUsers } from '../../firebase/usePortalUsers';

interface TherapyCourseMembersProps {
  therapyCourseRef: DocumentReference<TherapyCourse>;
  therapists?: PortalUser[];
  onRemoveTherapist?: (therapistId: string) => void;
  pendingInvites: TherapyCourseInvitation[];
  setPendingInvites: (invites: TherapyCourseInvitation[]) => void;
  pendingCancellations: string[];
  setPendingCancellations: (cancellations: string[]) => void;
  pendingMemberRemovals: string[];
  setPendingMemberRemovals: (removals: string[]) => void;
}

export default function TherapyCourseMembers({
  therapyCourseRef,
  therapists = [],
  onRemoveTherapist,
  pendingInvites,
  setPendingInvites,
  pendingCancellations,
  setPendingCancellations,
  pendingMemberRemovals,
  setPendingMemberRemovals,
}: TherapyCourseMembersProps) {
  const { t } = useTranslation();
  const { data: portalUsers } = usePortalUsers();
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const { invitingInvitations: pendingInvitations } =
    useTherapyCourseInvitations();
  // Memoize owner-related calculations
  const ownerEmail = useMemo(
    () => therapyCourseRef.path.split('/')[3],
    [therapyCourseRef.path]
  );

  const owner = useMemo(
    () => portalUsers?.find((user: PortalUser) => user.email === ownerEmail),
    [portalUsers, ownerEmail]
  );

  // Memoize all therapists array
  const allTherapists = useMemo(
    () => [
      ...(!therapists.some((t) => t.email === ownerEmail)
        ? [
            {
              id: ownerEmail,
              email: ownerEmail,
              name: `${owner?.name} (Primary Therapist)`,
            },
          ]
        : []),
      ...therapists,
    ],
    [therapists, ownerEmail, owner?.name]
  );

  // console.log('allTherapists', allTherapists);

  const handleCancelInvitation = useCallback(
    (invitationId: string) => {
      setPendingCancellations([...pendingCancellations, invitationId]);
    },
    [setPendingCancellations, pendingCancellations]
  );

  // Memoize combined invitations
  const allDisplayedInvitations = useMemo(
    () => [...pendingInvitations, ...pendingInvites],
    [pendingInvitations, pendingInvites]
  );

  const handleRemoveTherapist = useCallback(
    (therapistId: string) => {
      setPendingMemberRemovals([...pendingMemberRemovals, therapistId]);
    },
    [setPendingMemberRemovals, pendingMemberRemovals]
  );

  // Memoize displayed therapists with pending removal status
  const displayedTherapists = useMemo(
    () =>
      allTherapists.map((therapist) => ({
        ...therapist,
        pendingRemoval: pendingMemberRemovals.includes(therapist.id!),
      })),
    [allTherapists, pendingMemberRemovals]
  );

  // Check if we should show compact view
  const showCompactView = useMemo(() => {
    const nonOwnerTherapists = displayedTherapists.filter(
      (therapist) => therapist.email !== ownerEmail
    );
    return (
      nonOwnerTherapists.length === 0 && allDisplayedInvitations.length === 0
    );
  }, [displayedTherapists, ownerEmail, allDisplayedInvitations]);

  if (showCompactView) {
    return (
      <>
        <Button
          variant="outlined"
          onClick={() => setInviteDialogOpen(true)}
          sx={{ mt: 2 }}
        >
          {t('Add Therapist to Course')}
        </Button>
        <InviteTherapistDialog
          open={inviteDialogOpen}
          onClose={() => setInviteDialogOpen(false)}
          therapyCourseRef={therapyCourseRef}
          pendingInvites={pendingInvites}
          setPendingInvites={setPendingInvites}
        />
      </>
    );
  }

  return (
    <Card sx={{ mt: 2 }} variant="outlined">
      <CardHeader
        title={t('Access Management')}
        action={
          <Button variant="outlined" onClick={() => setInviteDialogOpen(true)}>
            {t('Add Therapist to Course')}
          </Button>
        }
      />
      <CardContent>
        {(pendingInvites.length > 0 ||
          pendingCancellations.length > 0 ||
          pendingMemberRemovals.length > 0) && (
          <Alert severity="info" sx={{ mb: 2 }}>
            {t('Changes will be processed when you save the therapy course')}
          </Alert>
        )}

        <Typography variant="subtitle1" gutterBottom>
          {t('Current Therapists')}
        </Typography>
        <List>
          {displayedTherapists.map((therapist) => (
            <ListItem
              key={therapist.id}
              divider
              sx={{
                opacity: therapist.pendingRemoval ? 0.5 : 1,
                textDecoration: therapist.pendingRemoval
                  ? 'line-through'
                  : 'none',
                bgcolor:
                  therapist.email === ownerEmail ? 'action.hover' : 'inherit',
              }}
            >
              <ListItemText
                primary={
                  <Typography
                    component="div"
                    sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                  >
                    {therapist.name}
                    {therapist.email === ownerEmail && (
                      <Typography
                        variant="caption"
                        sx={{
                          bgcolor: 'primary.main',
                          color: 'primary.contrastText',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1,
                        }}
                      >
                        {t('Primary Therapist')}
                      </Typography>
                    )}
                    {therapist.pendingRemoval && (
                      <Typography
                        variant="body2"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 0.5,
                          color: 'warning.main',
                        }}
                      >
                        <SaveIcon fontSize="small" />
                        {t('Pending removal')}
                      </Typography>
                    )}
                  </Typography>
                }
                secondary={therapist.email}
              />
              {onRemoveTherapist && therapist.email !== ownerEmail && (
                <ListItemSecondaryAction>
                  <Tooltip
                    title={
                      therapist.pendingRemoval
                        ? t('Undo removal')
                        : t('Remove therapist')
                    }
                  >
                    <IconButton
                      edge="end"
                      onClick={() => {
                        if (therapist.pendingRemoval) {
                          // Undo pending removal
                          setPendingMemberRemovals(
                            pendingMemberRemovals.filter(
                              (id) => id !== therapist.id
                            )
                          );
                        } else {
                          // Add to pending removals
                          handleRemoveTherapist(therapist.id!);
                        }
                      }}
                    >
                      {therapist.pendingRemoval ? (
                        <CancelIcon color="warning" />
                      ) : (
                        <DeleteIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
          {allTherapists.length === 0 && (
            <ListItem>
              <ListItemText secondary={t('No therapists assigned')} />
            </ListItem>
          )}
        </List>

        <Divider sx={{ my: 2 }} />

        <Typography variant="subtitle1" gutterBottom>
          {t('Pending Invitations')}
        </Typography>
        <List>
          {allDisplayedInvitations.map(
            (invitation: TherapyCourseInvitation) => {
              const isUnsavedInvite = pendingInvites.some(
                (invite) => invite.id === invitation.id
              );
              const isPendingCancellation = pendingCancellations.includes(
                invitation.id!
              );
              return (
                <ListItem
                  key={invitation.id}
                  divider
                  sx={{
                    opacity: isPendingCancellation ? 0.5 : 1,
                    textDecoration: isPendingCancellation
                      ? 'line-through'
                      : 'none',
                  }}
                >
                  <ListItemText
                    primary={invitation.invitedTherapistEmail}
                    secondary={
                      <Typography
                        variant="body2"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          color: isUnsavedInvite
                            ? 'warning.main'
                            : 'text.secondary',
                        }}
                      >
                        {isUnsavedInvite ? (
                          <SaveIcon fontSize="small" />
                        ) : (
                          <PendingIcon fontSize="small" />
                        )}
                        {isUnsavedInvite
                          ? t('Added to invitation queue')
                          : isPendingCancellation
                          ? t('Pending cancellation')
                          : t('Pending')}
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Tooltip
                      title={
                        isPendingCancellation
                          ? t('Undo cancellation')
                          : t('Cancel invitation')
                      }
                    >
                      <IconButton
                        edge="end"
                        onClick={() => {
                          if (isPendingCancellation) {
                            setPendingCancellations(
                              pendingCancellations.filter(
                                (id) => id !== invitation.id
                              )
                            );
                          } else if (isUnsavedInvite) {
                            // Directly remove unsent invitations
                            setPendingInvites(
                              pendingInvites.filter(
                                (invite) => invite.id !== invitation.id
                              )
                            );
                          } else {
                            // Queue cancellation for sent invitations
                            invitation.id &&
                              handleCancelInvitation(invitation.id);
                          }
                        }}
                      >
                        {isPendingCancellation ? (
                          <CancelIcon color="warning" />
                        ) : (
                          <DeleteIcon />
                        )}
                      </IconButton>
                    </Tooltip>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
          )}
          {allDisplayedInvitations.length === 0 && (
            <ListItem>
              <ListItemText secondary={t('No pending invitations')} />
            </ListItem>
          )}
        </List>
      </CardContent>
      <InviteTherapistDialog
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        therapyCourseRef={therapyCourseRef}
        pendingInvites={pendingInvites}
        setPendingInvites={setPendingInvites}
      />
    </Card>
  );
}
