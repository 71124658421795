import { Alert, AlertTitle, Box, CircularProgress } from '@mui/material';
import { Timestamp } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import {
  TherapyCourse,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import { useParams } from 'react-router-dom';
import usePatientCollection from '../../firebase/usePatientCollection';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel } from '@mui/lab';
import PatientView from './PatientView';
import { Trans } from 'react-i18next';

type PhasePatientsProps = {
  therapyCourseId: string;
  phase: TherapyCoursePhase;
  phaseIndex: number;
  course: TherapyCourse;
  currentPatient: number | null;
  setCurrentPatient: (index: number | null) => void;
  defaultLanguage: string;
};

/**
 * Tab for each patient in the course;
 * date range selector for statistics (prefill with previous phase dates if available)
 * statistics for the patient
 * @param props
 */
export default function PhasePatients(props: PhasePatientsProps) {
  const { patientId } = useParams<{ patientId: string }>();
  const {
    phase,
    phaseIndex,
    course,
    therapyCourseId,
    currentPatient,
    setCurrentPatient,
    defaultLanguage,
  } = props;
  const {
    data: patientCollectionData,
    status: patientCollectionStatus,
    error: patientCollectionError,
  } = usePatientCollection();

  const [currentStartDate, setCurrentStartDate] = useState<Timestamp | null>(
    null
  );
  const [currentEndDate, setCurrentEndDate] = useState<Timestamp | null>(null);
  const [initDone, setInitDone] = useState(false);
  useEffect(() => {
    if (!course.patients) {
      return;
    }
    if (currentPatient === null && !!patientId) {
      const initialPatient = course.patients.findIndex(
        (patient) => patient.id === patientId
      );
      setCurrentPatient(initialPatient);
    }
  }, [course, course.patients, currentPatient, patientId, setCurrentPatient]);

  useEffect(() => {
    if (initDone) {
      return;
    }

    // Set default date range to last 7 days if not already set
    if (currentStartDate === null && currentEndDate === null) {
      const now = new Date();
      const endOfDay = new Date();
      endOfDay.setHours(17, 59, 59, 999);
      const weekAgo = new Date();
      weekAgo.setDate(now.getDate() - 6);
      weekAgo.setHours(0, 0, 0, 0);

      setCurrentStartDate(Timestamp.fromDate(weekAgo));
      setCurrentEndDate(Timestamp.fromDate(endOfDay));
    }
    setInitDone(true);
  }, [
    currentStartDate,
    currentEndDate,
    setCurrentStartDate,
    setCurrentEndDate,
    initDone,
  ]);

  if (patientCollectionStatus === 'loading') {
    return <CircularProgress />;
  }
  if (patientCollectionStatus === 'error') {
    return (
      <Alert severity="error">
        <AlertTitle>
          <Trans>Error loading patient collection</Trans>
        </AlertTitle>
        {patientCollectionError?.message}
      </Alert>
    );
  }
  // Add this: filter valid patients before rendering
  const validPatients =
    course.patients?.filter((p) => {
      const isValid = patientCollectionData?.find(
        (patient) => patient.id === p.id && patient.licenceCode
      );
      if (!isValid) {
        console.warn('Invalid patient found:', {
          patientId: p.id,
          reason: 'Missing from collection or no licence code',
        });
      }
      return isValid;
    }) || [];

  return (
    <TabContext value={`${currentPatient || 0}`}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          backgroundColor: 'background.paper',
          mt: 1,
        }}
      >
        <Tabs
          aria-label="patients"
          value={`${currentPatient || 0}`}
          variant="scrollable"
          sx={{ overflowX: 'auto' }}
        >
          {validPatients.map((p, index) => {
            const patient = patientCollectionData?.find(
              (patient) => patient.id === p.id
            );
            return (
              <Tab
                key={`${patient!.id}-tab`}
                label={`${patient!.licenceCode} - ${patient!.remark}`}
                onClick={() => setCurrentPatient(index)}
                value={`${index}`}
                sx={{ minWidth: 100 }}
              />
            );
          })}
        </Tabs>
      </Box>
      <Box sx={{ backgroundColor: 'background.paper' }}>
        {validPatients.map((p, index) => {
          const patient = patientCollectionData?.find(
            (patient) => patient.id === p.id
          );
          return (
            <TabPanel value={`${index}`} key={patient!.id}>
              <PatientView
                key={`${patient!.id}-statistics`}
                therapyCourseId={therapyCourseId}
                therapyCoursePatientId={p.id}
                patient={patient!}
                phase={phase}
                currentStartDate={currentStartDate}
                setCurrentStartDate={setCurrentStartDate}
                currentEndDate={currentEndDate}
                setCurrentEndDate={setCurrentEndDate}
                course={course}
                phaseIndex={phaseIndex}
                defaultLanguage={defaultLanguage}
              />
            </TabPanel>
          );
        })}
      </Box>
    </TabContext>
  );
}
