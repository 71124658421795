import { Typography, SxProps, Theme, Box } from '@mui/material';
import usePortalUser from '../firebase/usePortalUser';
import useUserProvider from '../firebase/useUserProvider';
interface UserNameProps {
  sx?: SxProps<Theme>;
}

/**
 * Displays the current user's name.
 *
 * @returns  JSX.Element
 */
export default function UserName({ sx }: UserNameProps) {
  const { data: portalUserData, status: userStatus } = usePortalUser();
  const { data: userProviderData, status: userProviderStatus } =
    useUserProvider();

  if (userStatus !== 'success' || userProviderStatus !== 'success') {
    return null;
  }

  return (
    <Box sx={sx}>
      {portalUserData?.name && (
        <Typography
          variant="body2"
          component="p"
          sx={{ justifySelf: 'flex-end' }}
        >
          {portalUserData?.name}
        </Typography>
      )}
      {portalUserData?.name !== userProviderData?.name && (
        <Typography
          variant="caption"
          component="p"
          sx={{ justifySelf: 'flex-end' }}
        >
          {userProviderData?.name}
        </Typography>
      )}
    </Box>
  );
}
