import { useTranslation } from 'react-i18next';
import { Typography, Box, Button } from '@mui/material';
import { useMemo, useCallback, useRef } from 'react';
import { StatisticDay } from './types';
import { DATE_LABEL_WIDTH, DayView } from '../DayStatistics/DayView';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { Patient } from '../../firebase/firebaseModels';
import { getFormattedDateWithTimezone } from '../../utils';
import { Timestamp } from 'firebase/firestore';

type DayGraphTabProps = {
  statisticsSlice: StatisticDay[] | null;
  isUpdating: boolean;
  patient?: Patient;
  fromDateString?: string;
  toDateString?: string;
};

export default function DayGraphTab(props: DayGraphTabProps) {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const { statisticsSlice, patient, fromDateString, toDateString } = props;

  const dayWidth = 100; // Adjust as needed
  const hourWidth = dayWidth / 24;
  const dayHeight = 30; // Adjust as needed
  const fullWidth = dayWidth;

  // first date is one day earlier than the first date in statisticsSlice
  const firstDateString = useMemo(() => {
    if (!statisticsSlice?.[0]?.marking_date) return undefined;
    const firstDate = new Date(statisticsSlice[0].marking_date);
    firstDate.setDate(firstDate.getDate() - 1);
    return getFormattedDateWithTimezone(
      Timestamp.fromDate(firstDate)
    ) as string;
  }, [statisticsSlice]);

  const lastDateString = useMemo(() => {
    return statisticsSlice?.[statisticsSlice.length - 1]?.marking_date;
  }, [statisticsSlice]);

  // calculate total number of days between fromDateString and toDateString
  const totalNumberOfDays = useMemo(() => {
    const firstDate = new Date(firstDateString || '');
    const lastDate = new Date(lastDateString || '');
    return (
      Math.ceil(
        (lastDate.getTime() - firstDate.getTime()) / (1000 * 60 * 60 * 24)
      ) + 1
    );
  }, [firstDateString, lastDateString]);

  const range = useMemo(() => {
    return Array.from(
      { length: totalNumberOfDays },
      (_, idx) => totalNumberOfDays - 1 - idx
    );
  }, [totalNumberOfDays]);

  const dayRows = useMemo(
    () =>
      statisticsSlice
        ? range
            .map((daysSince, idx) => {
              const statisticsDate = new Date(lastDateString || '');
              statisticsDate.setDate(statisticsDate.getDate() - daysSince);
              const dateLabel = statisticsDate.toLocaleDateString();

              // Skip invalid dates
              if (isNaN(statisticsDate.getTime())) {
                return null;
              }

              return (
                <Box
                  key={`day-row-${dateLabel}`}
                  sx={{
                    width: `${dayWidth}%`,
                    height: dayHeight,
                    position: 'relative',
                    overflow: 'hidden',
                    mb: '2px',
                  }}
                >
                  <DayView
                    key={`dv-${idx}`}
                    statisticDate={statisticsDate}
                    dateLabel={dateLabel}
                    statistics={statisticsSlice}
                    fullWidth={fullWidth}
                    dayWidth={dayWidth}
                    hourWidth={hourWidth}
                    dayHeight={dayHeight}
                  />
                </Box>
              );
            })
            .filter((dr) => dr != null)
        : [],
    [fullWidth, hourWidth, range, statisticsSlice, lastDateString]
  );

  const handleExportChart = useCallback(() => {
    const chartDiv = containerRef.current?.querySelector(
      '#day-graph-content'
    ) as HTMLElement;
    if (!chartDiv) {
      return;
    }

    import('html2canvas').then((html2canvas) => {
      const exportContainer = document.createElement('div');
      exportContainer.style.backgroundColor = '#ffffff';
      exportContainer.style.padding = '20px';
      document.body.appendChild(exportContainer);

      const header = document.createElement('div');
      header.style.paddingBottom = '20px';
      header.style.fontSize = '12px';
      header.style.fontFamily = 'Arial';

      let headerText = '';
      if (patient) {
        headerText += `${patient.licenceCode}${
          patient.remark ? ` - ${patient.remark}` : ''
        }`;
      }
      if (fromDateString || toDateString || firstDateString || lastDateString) {
        headerText += headerText ? ' | ' : '';
        headerText += `${t('Date Range')}: ${
          fromDateString || firstDateString || t('Start')
        } - ${toDateString || lastDateString || t('End')}`;
      }
      header.textContent = headerText;

      exportContainer.appendChild(header);

      const chartClone = chartDiv.cloneNode(true) as HTMLElement;
      exportContainer.appendChild(chartClone);

      requestAnimationFrame(() => {
        html2canvas
          .default(exportContainer, {
            backgroundColor: '#ffffff',
            scale: 2,
            logging: false,
          })
          .then((canvas) => {
            const link = document.createElement('a');
            let filename = t('sleep-day-graph');
            if (patient?.licenceCode) {
              filename = `${patient.licenceCode}-${filename}`;
            }
            if (fromDateString && toDateString) {
              filename += `-${fromDateString}-to-${toDateString}`;
            } else if (firstDateString && lastDateString) {
              filename += `-${firstDateString}-to-${lastDateString}`;
            }
            filename += '.png';

            link.download = filename;
            link.href = canvas.toDataURL('image/png');
            link.click();

            exportContainer.remove();
          });
      });
    });
  }, [
    firstDateString,
    lastDateString,
    fromDateString,
    toDateString,
    patient,
    t,
  ]);

  return (
    <Box ref={containerRef}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <Typography
          variant="subtitle1"
          component="h2"
          sx={{ color: 'text.secondary' }}
        >
          {t('Daily Sleep Pattern')}
        </Typography>
        <Button
          variant="outlined"
          startIcon={<FileDownloadIcon />}
          onClick={handleExportChart}
          size="small"
        >
          {t('Export Chart')}
        </Button>
      </Box>

      <div id="day-graph-content">
        {dayRows.length > 0 ? dayRows : <></>}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: `${dayWidth}%`,
            ml: `${DATE_LABEL_WIDTH}px`,
            pr: `${DATE_LABEL_WIDTH}px`,
          }}
        >
          {['00', '04', '08', '12', '16', '20', '24'].map((label) => (
            <Typography key={`hour-label-${label}`} variant="caption">
              {label}
            </Typography>
          ))}
        </Box>
      </div>
    </Box>
  );
}
