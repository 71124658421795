import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useFirestore } from 'reactfire';
import {
  collection,
  onSnapshot,
  where,
  query,
  getDocs,
  orderBy,
} from 'firebase/firestore';
import { PortalUser, ServiceProvider } from '../firebase/firebaseModels';
import usePortalUser from '../firebase/usePortalUser';
import { FirebaseError } from 'firebase/app';
import useUserProvider from '../firebase/useUserProvider';

/**
 * Represents the structure of admin-related data and state
 * @typedef {Object} TAdminData
 * @property {'loading' | 'success' | 'error'} status - Current state of admin data loading
 * @property {PortalUser[] | null} data - Array of portal users or null if not loaded
 * @property {FirebaseError | null} error - Error object if status is 'error', null otherwise
 * @property {ServiceProvider[] | null} providers - Array of service providers or null if not loaded
 * @property {ServiceProvider[] | null} allProviders - Array of all service providers or null if not loaded
 */
export type TAdminData = {
  status: 'loading' | 'success' | 'error';
  data: PortalUser[] | null;
  error: FirebaseError | null;
  providers: ServiceProvider[] | null;
  allProviders: ServiceProvider[] | null;
};

/**
 * Context for managing admin-level access to portal user data
 * Initial value is null and should be accessed only within AdminProvider
 */
export const AdminContext = createContext<TAdminData | null>(null);

/**
 * Provider component that manages portal user data access based on admin privileges
 * Handles real-time updates, error states, and automatic retries on failure
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components that will have access to admin data
 */
export const AdminProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const firestore = useFirestore();
  const { isSuperAdmin, isOrganizationAdmin, providerRef } = usePortalUser();

  const [nTimeouts, setNTimeouts] = useState(0);
  const [portalUserData, setPortalUserData] = useState<PortalUser[] | null>(
    null
  );
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
    'loading'
  );
  const [error, setError] = useState<FirebaseError | null>(null);
  const [providers, setProviders] = useState<ServiceProvider[] | null>(null);
  const [allProviders, setAllProviders] = useState<ServiceProvider[] | null>(
    null
  );
  const { data: userProviderData } = useUserProvider();

  useEffect(() => {
    if (!providerRef) {
      return;
    }

    // Check admin privileges
    if (!isSuperAdmin && !isOrganizationAdmin) {
      console.log('User does not have admin privileges');
      setStatus('error');
      setError(
        new FirebaseError(
          'permission-denied',
          'User does not have admin privileges'
        )
      );
      return;
    }

    setStatus('loading');
    setError(null);

    const portalUsersRef = collection(firestore, 'PortalUser');

    // Query configuration:
    // - For organization admins: filter users by providerRef
    // - For super admins: access all users
    const portalUsersQuery = isOrganizationAdmin
      ? query(portalUsersRef, where('provider', '==', providerRef))
      : portalUsersRef;

    // Set up real-time listener for portal users
    const unsubscribe = onSnapshot(
      portalUsersQuery,
      (snapshot) => {
        // Transform Firestore documents into PortalUser objects
        const users = snapshot.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id } as PortalUser)
        );

        setPortalUserData(users);
        setStatus('success');
        setError(null);
        setNTimeouts(0);
      },
      (error) => {
        // Error handling with exponential backoff retry
        setStatus('error');
        setError(error as FirebaseError);
        setPortalUserData(null);

        console.error('Error getting PortalUser collection', error);
        const retryDelay = 1000 * (nTimeouts + 1); // Exponential backoff
        setTimeout(() => {
          // console.log('AdminProvider retrying after delay');
          setNTimeouts((prevTimeouts) => prevTimeouts + 1);
        }, retryDelay);
      }
    );

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, [firestore, nTimeouts, isSuperAdmin, isOrganizationAdmin, providerRef]);

  useEffect(() => {
    // Check admin privileges
    if (!isSuperAdmin && !isOrganizationAdmin) {
      return;
    }

    if (isOrganizationAdmin) {
      if (userProviderData) {
        const provider = {
          ...userProviderData,
          id: userProviderData.id,
        } as ServiceProvider;
        setProviders([provider]);
        setAllProviders([provider]);
      }
      return;
    }

    const fetchProviders = async () => {
      try {
        const providersRef = collection(firestore, 'ServiceProvider');
        const providersQuery = query(providersRef, orderBy('name'));
        const providersSnapshot = await getDocs(providersQuery);
        const providersData = providersSnapshot.docs.map(
          (doc) => ({ ...doc.data(), id: doc.id } as ServiceProvider)
        );

        // Store all providers
        setAllProviders(providersData);

        // Filter out trial providers for default view
        const nonTrialProviders = providersData.filter(
          (provider) => !provider.trialProvider
        );
        setProviders(nonTrialProviders);
      } catch (error) {
        console.error('Error fetching providers:', error);
      }
    };

    fetchProviders();
  }, [firestore, isOrganizationAdmin, isSuperAdmin, userProviderData]);

  const value = useMemo(
    () => ({
      status,
      data: portalUserData,
      error,
      providers,
      allProviders,
    }),
    [status, portalUserData, error, providers, allProviders]
  );

  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
