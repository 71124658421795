import React, { createContext, useContext, useState } from 'react';

type LogAction =
  | 'view'
  | 'create'
  | 'update'
  | 'delete'
  | 'share'
  | 'invite'
  | 'changePassword';

export interface AuditLogData {
  action: LogAction;
  resourceType: string; // e.g., 'patient', 'therapyCourse', 'assessment'
  resourceId: string;
  performedBy: string; // email of the portal user performing the action
  details?: Record<string, any>;
}

interface AuditLogContextType {
  lastLogData: { data: AuditLogData; timestamp: number } | null;
  setLastLogData: (
    data: { data: AuditLogData; timestamp: number } | null
  ) => void;
}

const AuditLogContext = createContext<AuditLogContextType | undefined>(
  undefined
);

export function AuditLogProvider({ children }: { children: React.ReactNode }) {
  const [lastLogData, setLastLogData] = useState<{
    data: AuditLogData;
    timestamp: number;
  } | null>(null);

  return (
    <AuditLogContext.Provider value={{ lastLogData, setLastLogData }}>
      {children}
    </AuditLogContext.Provider>
  );
}

export function useAuditLogContext() {
  const context = useContext(AuditLogContext);
  if (context === undefined) {
    throw new Error(
      'useAuditLogContext must be used within an AuditLogProvider'
    );
  }
  return context;
}
