import { useMemo } from 'react';
import { getDateDiaryData, getTimeAsDecimal } from './statUtil';
import { timestampPropertyAsDate } from '../../utils';
import { StatisticDay } from '../TherapyCourseOverview/types';
import { DiaryPeriod } from './DiaryPeriod';

interface NapPeriodsProps {
  statisticDate: Date;
  cutDateAsStr: string;
  statistics: StatisticDay[];
  dayHeight: number;
  hourWidth: number;
}

/**
 * Renders the nap periods for a given date.
 *
 * This component displays multiple Box components, each representing a nap
 * taken on the specified date. It calculates the necessary time values and positions
 * for each nap period based on the provided statistics and layout parameters.
 */
export function NapPeriods({
  statisticDate,
  cutDateAsStr,
  statistics,
  dayHeight,
  hourWidth,
}: NapPeriodsProps) {
  const napPeriods = useMemo(() => {
    const { eveningData } = getDateDiaryData(statisticDate, statistics);

    return (
      eveningData?.raw_data?.naps?.map((nap, index) => {
        const bedtime = timestampPropertyAsDate(nap.bedtime);
        const sleeptime = timestampPropertyAsDate(nap.sleeptime);
        const wakeuptime = timestampPropertyAsDate(nap.wakeuptime);
        const gettinguptime = timestampPropertyAsDate(nap.gettinguptime);

        // calculate the decimal values for the nap period data
        const bedtimeDecimal = getTimeAsDecimal(bedtime, cutDateAsStr, 0);
        const sleeptimeDecimal = getTimeAsDecimal(sleeptime, cutDateAsStr, 0);
        const wakeuptimeDecimal = getTimeAsDecimal(wakeuptime, cutDateAsStr, 0);
        const gettinguptimeDecimal = getTimeAsDecimal(
          gettinguptime,
          cutDateAsStr,
          0
        );

        /**
         * Render nap period with:
         * bedtime → sleeptime (falling asleep period)
         * sleeptime → waketime (sleep period)
         * waketime → gettinguptime (getting up period)
         */
        return (
          <DiaryPeriod
            key={`${cutDateAsStr}-nap-${index}`}
            bedtimeDecimal={bedtimeDecimal}
            gettinguptimeDecimal={gettinguptimeDecimal}
            dayHeight={dayHeight}
            hourWidth={hourWidth}
            sleeptimeDecimal={sleeptimeDecimal}
            wakeuptimeDecimal={wakeuptimeDecimal}
            laydownDecimal={null}
          />
        );
      }) || []
    );
  }, [cutDateAsStr, dayHeight, hourWidth, statisticDate, statistics]);

  return <>{napPeriods}</>;
}
