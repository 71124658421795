import { useTranslation, Trans } from 'react-i18next';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import DeleteIcon from '@mui/icons-material/Delete';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs from 'dayjs';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinkIcon from '@mui/icons-material/Link';
import { Link as MuiLink, Slide } from '@mui/material';
import { Link } from 'react-router-dom';
import {
  doc,
  collection,
  updateDoc,
  addDoc,
  deleteDoc,
  DocumentReference,
  Timestamp,
  DocumentData,
  query,
  where,
  getDocs,
} from 'firebase/firestore';
import {
  Patient,
  TaskPoolTask,
  TherapyCourse,
  TherapyCoursePhasePatient,
  TherapyCoursePhase,
  TranslatableField,
  TherapyCourseInvitation,
  PortalUser,
} from '../firebase/firebaseModels';
import {
  Button,
  CardHeader,
  CircularProgress,
  FormGroup,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  Chip,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import LanguageSelector from '../components/LanguageSelector';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useFirestore } from 'reactfire';
import useTherapyCourseDoc from '../firebase/useTherapyCourseDoc';
import useUserProvider from '../firebase/useUserProvider';
import useTaskPoolCollection from '../firebase/useTaskPoolCollection';
import { getCurrentLanguageCode } from '../services/i18n';
import usePatientCollection from '../firebase/usePatientCollection';
import TherapyCourseEditorPhase from '../components/TherapyCourseEditor/TherapyCourseEditorPhase';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';
import TranslatableTextField from '../components/TranslatableTextField';
import TranslatableEditor from '../components/TranslatableEditor';
import usePortalUser from '../firebase/usePortalUser';
import uuid from 'react-native-uuid';
import { useTaskPoolOptions } from '../hooks/useTaskPoolOptions';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useStrictDroppable } from '../hooks/useStrictDroppable';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import PatientManager from '../components/PatientManager';
import WarningIcon from '@mui/icons-material/Warning';
import TherapyCourseMembers from '../components/TherapyCourseEditor/TherapyCourseMembers';
import { usePortalUsers } from '../firebase/usePortalUsers';
import { useBeforeUnload } from '../hooks/useBeforeUnload';
import { useTherapyCourseRef } from '../hooks/useTherapyCourseRef';
import { useSharedTherapyCourses } from '../firebase/useSharedTherapyCourses';
import UndoIcon from '@mui/icons-material/Undo';
import SaveIcon from '@mui/icons-material/Save';
import useAppropriateLanguage from '../hooks/useAppropriateLanguage';

// Helper function for accessibility props on tabs
function a11yProps(index: number) {
  return {
    id: `phase-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// Ensures all phases have unique IDs by adding UUIDs where missing
function addMissingIdsToPhases(phases: TherapyCoursePhase[]) {
  // if no ids are missing, return same object
  if (phases.every((phase) => phase.id)) {
    return phases;
  }
  // otherwise add missing ids
  return phases.map((phase, index) => {
    if (!phase.id) {
      return { ...phase, id: uuid.v4() } as TherapyCoursePhase;
    }
    return phase;
  });
}

function validatePhaseDates(phases: TherapyCoursePhase[]): {
  hasError: boolean;
  errorPhases: number[];
} {
  const errorPhases: number[] = [];

  for (let i = 0; i < phases.length - 1; i++) {
    const currentPhase = phases[i];
    const nextPhase = phases[i + 1];

    // Only validate if both phases have start dates
    if (currentPhase.startDate && nextPhase.startDate) {
      const currentStartDate = currentPhase.startDate.toDate();
      const nextStartDate = nextPhase.startDate.toDate();

      // Compare dates and mark both phases if current start date is after next start date
      if (currentStartDate >= nextStartDate) {
        errorPhases.push(i, i + 1);
      }
    }
  }

  return {
    hasError: errorPhases.length > 0,
    errorPhases: Array.from(new Set(errorPhases)), // Remove duplicates
  };
}

// Add new validation function
function validatePhases(phases: TherapyCoursePhase[]): {
  hasDateError: boolean;
  dateErrorPhases: number[];
  hasTitleError: boolean;
  titleErrorPhases: number[];
  hasTaskError: boolean;
  taskErrorPhases: number[];
} {
  const dateValidation = validatePhaseDates(phases);
  const titleErrorPhases: number[] = [];
  const taskErrorPhases: number[] = [];

  phases.forEach((phase, index) => {
    // Check for missing titles in default language
    if (
      !phase.title ||
      !Object.values(phase.title).some((title) => title.trim())
    ) {
      titleErrorPhases.push(index);
    }

    // Removed check for missing tasks - phases without tasks are now allowed
  });

  return {
    hasDateError: dateValidation.hasError,
    dateErrorPhases: dateValidation.errorPhases,
    hasTitleError: titleErrorPhases.length > 0,
    titleErrorPhases,
    hasTaskError: false, // Always false since we no longer consider this an error
    taskErrorPhases: [], // Always empty since we no longer track this
  };
}

type TherapyCourseEditorProps = {
  therapycoursetype: 'group' | 'individual' | null;
};
export default function TherapyCourseEditor(props: TherapyCourseEditorProps) {
  const { t } = useTranslation();

  const { therapyCourseId } = useParams<{ therapyCourseId: string }>();
  const firestore = useFirestore();
  const { email } = useFirebaseUser();

  const { data: portalUserData } = usePortalUser();
  const { data: portalUsers } = usePortalUsers();

  const [startImmediately, setStartImmediately] = useState(false);
  const [therapycoursetype, setTherapyCourseType] = useState<
    'group' | 'individual'
  >(props.therapycoursetype !== null ? props.therapycoursetype : 'group');
  const { data: userProviderData, error: userProviderError } =
    useUserProvider();

  const { data: patientCollectionData, error: patientCollectionError } =
    usePatientCollection();

  const initialLang = getCurrentLanguageCode();
  const [defaultLanguage, setDefaultLanguage] = useState(initialLang);

  const [title, setTitle] = useState<TranslatableField>({
    [initialLang]: '',
  });
  const [language, setLanguage] = useState(initialLang);

  const [description, setDescription] = useState<TranslatableField>({
    [initialLang]: '',
  });
  const [defaultInterval, setDefaultInterval] = useState<number>(7);
  const [therapistName, setTherapistName] = useState('');
  const [phases, setPhases] = useState<TherapyCoursePhase[]>([]);
  const [patients, setPatients] = useState<DocumentReference<Patient>[]>([]);
  const [currentPhase, setCurrentPhase] = useState(0);

  // Move therapyCourseRef creation before useTherapyCourseDoc
  const { therapyCourseRef, isShared } = useTherapyCourseRef(therapyCourseId);

  const {
    status,
    error,
    data: course,
  } = useTherapyCourseDoc(therapyCourseRef, props.therapycoursetype || 'group');

  // Simplify therapy languages to use only what's available to the user
  const therapyLanguages = useMemo(() => {
    return portalUserData?.therapyLanguages || [];
  }, [portalUserData]);

  const courseLanguages = useMemo(() => {
    if (!course) return [];

    // Use course's explicit languages if available
    if (course.languages && course.languages.length > 0) {
      return course.languages;
    }

    // Otherwise use languages from title
    return Object.keys(course.title || {}).filter(
      (lang) => !!course.title[lang]
    );
  }, [course]);

  // Use our hook to determine the appropriate language
  const appropriateLanguage = useAppropriateLanguage(
    defaultLanguage,
    therapyLanguages,
    courseLanguages
  );

  // Create a stable dependency string for the useMemo hook by converting task IDs to a sorted JSON string.
  // This prevents unnecessary recalculations when the phase array reference changes but the actual task IDs remain the same.
  // Without this, changes to other phase properties would trigger unnecessary recalculations of phaseTaskIds.

  const phaseTaskIdsAsJson = JSON.stringify(
    phases.map((phase) => phase.phaseTasks?.map((task) => task.id)).sort()
  );

  const phaseTaskIds = useMemo(() => {
    const ids = new Set<string>();
    phases.forEach((phase) => {
      phase.phaseTasks?.forEach((task) => {
        if (task.id) {
          ids.add(task.id);
        }
      });
    });
    return Array.from(ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phaseTaskIdsAsJson]);

  const { data: taskPoolData, error: taskPoolError } = useTaskPoolCollection(
    null,
    therapyLanguages,
    phaseTaskIds
  );

  const { data: categoryData, error: collectionError } =
    useTaskPoolTaskCategoryCollection();

  const [updating, setUpdating] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [ongoingPhase, setOngoingPhase] = useState<number | null>(null);
  const [completed, setCompleted] = useState(false);
  const [completedDate, setCompletedDate] = useState<Timestamp | null>(null);
  const courseIsActive = course?.ongoingPhase !== null;
  const courseIsCompleted = course?.completed || false;

  // Replace the dateValidation state with phaseValidation
  const [phaseValidation, setPhaseValidation] = useState<
    ReturnType<typeof validatePhases>
  >({
    hasDateError: false,
    dateErrorPhases: [],
    hasTitleError: false,
    titleErrorPhases: [],
    hasTaskError: false,
    taskErrorPhases: [],
  });

  const [pendingInvites, setPendingInvites] = useState<
    TherapyCourseInvitation[]
  >([]);
  const [pendingCancellations, setPendingCancellations] = useState<string[]>(
    []
  );
  const [pendingMemberRemovals, setPendingMemberRemovals] = useState<string[]>(
    []
  );

  // Convert therapist references to PortalUser objects
  const therapistUsers = useMemo(
    () =>
      (course?.therapists || [])
        .map((ref) => portalUsers?.find((user) => user.id === ref.id))
        .filter((user): user is PortalUser => !!user),
    [course?.therapists, portalUsers]
  );

  // Add state to track if there are unsaved changes
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Add new state for delayed banner visibility
  const [showUnsavedBanner, setShowUnsavedBanner] = useState(false);

  // Show browser warning when trying to leave with unsaved changes
  useBeforeUnload(hasUnsavedChanges);

  // Update useEffect to track changes
  useEffect(() => {
    if (course && status === 'success') {
      const courseDefaultLang = course.defaultLanguage;
      const bestLanguage = appropriateLanguage;

      setTitle(course.title || { [bestLanguage]: '' });
      setDescription(course.description || { [bestLanguage]: '' });
      setPhases(course.phases ? addMissingIdsToPhases(course.phases) : []);
      setPatients(course.patients || []);
      setDefaultInterval(course.defaultInterval || 7);
      setOngoingPhase(
        course.ongoingPhase === undefined ? null : course.ongoingPhase
      );
      setCurrentPhase(course.ongoingPhase || 0);
      setTherapyCourseType(course.type || 'group');
      setCompleted(course.completed || false);
      setCompletedDate(course.completedDate || null);
      setTherapistName(course.therapistName || '');
      setLanguage(bestLanguage);
      setDefaultLanguage(courseDefaultLang || bestLanguage);
      // Reset unsaved changes after loading
      setHasUnsavedChanges(false);
      setShowUnsavedBanner(false);
    }
  }, [status, course, appropriateLanguage]);

  // Update the useEffect that tracks changes
  useEffect(() => {
    if (status === 'success' && course) {
      // Helper function to compare phases deeply
      const arePhasesEqual = (
        phases1: TherapyCoursePhase[],
        phases2: TherapyCoursePhase[]
      ) => {
        if (phases1.length !== phases2.length) return false;

        return phases1.every((phase1, index) => {
          const phase2 = phases2[index];

          // Compare basic phase properties
          if (JSON.stringify(phase1.title) !== JSON.stringify(phase2.title))
            return false;

          if (
            JSON.stringify(phase1.startDate) !==
            JSON.stringify(phase2.startDate)
          )
            return false;
          if (JSON.stringify(phase1.endDate) !== JSON.stringify(phase2.endDate))
            return false;

          // Compare phaseTasks
          if (
            (phase1.phaseTasks?.length || 0) !==
            (phase2.phaseTasks?.length || 0)
          )
            return false;

          const phase1TaskIds = phase1.phaseTasks
            ?.map((task) => task.id)
            .sort();
          const phase2TaskIds = phase2.phaseTasks
            ?.map((task) => task.id)
            .sort();

          const phaseTasksDiffer =
            JSON.stringify(phase1TaskIds) !== JSON.stringify(phase2TaskIds);

          if (phaseTasksDiffer) return false;

          // Compare patients and their tasks
          if ((phase1.patients?.length || 0) !== (phase2.patients?.length || 0))
            return false;

          const phasePatientsDiffer = phase1.patients?.some(
            (patient1, index) => {
              const patient2 = phase2.patients?.[index];
              return (
                patient1.id !== patient2?.id ||
                JSON.stringify(patient1.taskOrder) !==
                  JSON.stringify(patient2?.taskOrder) ||
                JSON.stringify(patient1.activeTasks) !==
                  JSON.stringify(patient2?.activeTasks)
              );
            }
          );

          if (phasePatientsDiffer) return false;

          return true;
        });
      };

      const hasChanges =
        JSON.stringify(title) !== JSON.stringify(course.title) ||
        JSON.stringify(description) !== JSON.stringify(course.description) ||
        !arePhasesEqual(phases, course.phases || []) ||
        JSON.stringify(patients) !== JSON.stringify(course.patients) ||
        defaultInterval !== (course.defaultInterval || 7) ||
        therapistName !== (course.therapistName || '') ||
        therapycoursetype !== (course.type || 'group') ||
        defaultLanguage !==
          (course.defaultLanguage || getCurrentLanguageCode()) ||
        pendingInvites.length > 0 ||
        pendingCancellations.length > 0 ||
        pendingMemberRemovals.length > 0;

      setHasUnsavedChanges(hasChanges);

      // Add delay for showing the banner
      if (hasChanges) {
        const timer = setTimeout(() => {
          setShowUnsavedBanner(true);
        }, 500);
        return () => clearTimeout(timer);
      } else {
        setShowUnsavedBanner(false);
      }
    }
  }, [
    course,
    title,
    description,
    phases,
    patients,
    defaultInterval,
    therapistName,
    therapycoursetype,
    defaultLanguage,
    pendingInvites,
    pendingCancellations,
    pendingMemberRemovals,
    status,
  ]);

  // Prepare data for updating the therapy course
  const updateData = useMemo(() => {
    if (!title || !description || !userProviderData?.id || !email) {
      return null;
    }

    // Get all languages that have content in either title or description
    let languages = Object.keys(title).filter((lang) => !!title[lang]);
    Object.keys(description).forEach((lang) => {
      if (!!description[lang] && !languages.includes(lang)) {
        languages.push(lang);
      }
    });

    // Update phases to include task order for each patient
    const updatedPhases = phases.map((phase) => ({
      ...phase,
      patients: phase.patients.map((patient) => ({
        ...patient,
      })),
    }));

    // Construct update object with all course data
    const updateData: Partial<TherapyCourse> = {
      title,
      description,
      phases: updatedPhases,
      languages,
      modified: Timestamp.now(),
      modifiedBy: email,
      patients,
      defaultInterval,
      type: therapycoursetype,
      ongoingPhase: startImmediately ? 0 : ongoingPhase,
      completed: startImmediately ? false : completed,
      completedDate: startImmediately ? null : completedDate,
      therapistName,
      defaultLanguage,
      // Add owner email when creating new therapy course
      ...(therapyCourseId === 'new' && { ownerEmail: email }),
    };
    return updateData;
  }, [
    title,
    description,
    userProviderData?.id,
    email,
    phases,
    patients,
    defaultInterval,
    therapycoursetype,
    startImmediately,
    ongoingPhase,
    completed,
    completedDate,
    therapistName,
    defaultLanguage,
    therapyCourseId, // Add therapyCourseId to dependencies
  ]);

  // Then the onSubmit callback
  const onSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (
        !title ||
        !description ||
        !userProviderData?.id ||
        !email ||
        !updateData
      ) {
        return;
      }
      setUpdating(true);
      setUpdateSuccessful(false);

      try {
        // First update the course
        if (therapyCourseId && therapyCourseId !== 'new') {
          // Remove therapists that are pending removal
          const updatedTherapists = (course?.therapists || []).filter(
            (ref) => !pendingMemberRemovals.includes(ref.id)
          );

          // Update the course document with all changes including updated therapists array
          await updateDoc(therapyCourseRef!, {
            ...updateData,
            therapists: updatedTherapists,
          });

          if (pendingMemberRemovals.length > 0) {
            // Handle invitation updates for removed therapists
            const invitationsQuery = query(
              collection(firestore, 'TherapyCourseInvitation'),
              where('therapyCourse', '==', therapyCourseRef),
              where('status', '==', 'accepted'),
              where('invitedTherapistEmail', 'in', pendingMemberRemovals),
              where('invitingTherapistEmail', '==', email)
            );

            const invitationsSnapshot = await getDocs(invitationsQuery);

            // Update invitations for removed therapists to 'removed' status
            const invitationUpdates = invitationsSnapshot.docs.map((doc) =>
              updateDoc(doc.ref, {
                status: 'removed',
                modified: Timestamp.now(),
                modifiedBy: email,
              })
            );

            await Promise.all(invitationUpdates);
          }
        }

        // Then handle invitations
        // Process cancellations
        await Promise.all(
          pendingCancellations.map((invitationId) =>
            deleteDoc(doc(firestore, 'TherapyCourseInvitation', invitationId))
          )
        );

        // Process new invitations
        await Promise.all(
          pendingInvites.map((invitation) => {
            // Count only valid patients
            const validPatientCount = patients.filter((patientRef) => {
              const patient = patientCollectionData?.find(
                (p) => p.id === patientRef.id
              );
              return patient && !patient.pending;
            }).length;

            let completeInvitation: TherapyCourseInvitation = {
              ...invitation,
              courseInfo: {
                title,
                type: therapycoursetype,
                patientCount: validPatientCount, // Use validPatientCount instead of patients.length
                status:
                  ongoingPhase !== null
                    ? 'active'
                    : completed
                    ? 'completed'
                    : 'not_started',
              },
            };

            delete completeInvitation.id;

            return addDoc(
              collection(firestore, 'TherapyCourseInvitation'),
              completeInvitation
            );
          })
        );

        // Clear all pending changes
        setPendingMemberRemovals([]);
        setPendingInvites([]);
        setPendingCancellations([]);

        setHasUnsavedChanges(false);
        setUpdating(false);
        setUpdateSuccessful(true);
      } catch (error) {
        console.error('Error updating course:', error);
        setUpdating(false);
      }
    },
    [
      title,
      description,
      userProviderData?.id,
      email,
      updateData,
      therapyCourseId,
      pendingCancellations,
      pendingInvites,
      course?.therapists,
      therapyCourseRef,
      pendingMemberRemovals,
      firestore,
      patients,
      therapycoursetype,
      ongoingPhase,
      completed,
      patientCollectionData,
    ]
  );

  const onChangeTherapistName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      if (therapistName !== value) {
        setTherapistName(value);
      }
    },
    [therapistName]
  );

  const onChangeDefaultInterval = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      if (defaultInterval !== value) {
        setDefaultInterval(value);
      }
    },
    [defaultInterval]
  );

  // Update patients in all phases when patient list changes
  const updatePhasePatients = useCallback(
    (newPatients: DocumentReference<Patient, DocumentData>[]) => {
      setPhases((phases) => {
        return phases.map((phase) => {
          const newPhasePatients = newPatients.map((patient) => {
            const existingPatient = phase.patients.find(
              (p) => p.id === patient.id
            );
            return {
              id: patient.id,
              patient: patient,
              activeTasks: existingPatient?.activeTasks || {},
              taskOrder: existingPatient?.taskOrder || {},
            };
          });
          return {
            ...phase,
            patients: newPhasePatients,
          };
        });
      });
    },
    []
  );

  useEffect(() => {
    updatePhasePatients(patients);
  }, [patients, updatePhasePatients]);

  const handleStartImmediately = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setStartImmediately(event.target.checked);
  };

  const created = course?.created?.toDate();
  const modified = course?.modified?.toDate();

  const taskPoolOptions = useTaskPoolOptions(
    taskPoolData,
    categoryData,
    portalUserData,
    language
  );
  const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

  const handleChangeTherapyCourseType = useCallback(
    (event: React.MouseEvent<HTMLElement>, newTherapyCourseType: string) => {
      if (newTherapyCourseType) {
        setTherapyCourseType(newTherapyCourseType as 'group' | 'individual');
      }
    },
    []
  );

  const changeTherapyCourseTypeDisabled = useMemo(() => {
    return (
      ongoingPhase !== null ||
      completed ||
      (therapycoursetype === 'group' && patients.length > 1)
    );
  }, [completed, ongoingPhase, patients.length, therapycoursetype]);

  const [enabled] = useStrictDroppable(status !== 'success');

  const [tabsKey, setTabsKey] = useState(0);

  // Handle drag and drop reordering of phases
  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }

      const scrollPosition = window.scrollY;

      requestAnimationFrame(() => {
        // Reorder phases array based on drag result
        const newPhases = Array.from(phases);
        const [reorderedItem] = newPhases.splice(result.source.index, 1);
        newPhases.splice(result.destination.index, 0, reorderedItem);

        setPhases(newPhases);
        setCurrentPhase(result.destination.index);
        setTabsKey((prevKey) => prevKey + 1);

        window.scrollTo(0, scrollPosition);
      });
    },
    [phases]
  );

  // Update useEffect to use new validation
  useEffect(() => {
    const validation = validatePhases(phases);
    setPhaseValidation(validation);
  }, [phases]);

  const handleRemoveTherapist = useCallback(
    async (therapistId: string) => {
      if (!therapyCourseRef || !email) return;

      try {
        console.log('therapyCourseRef', therapyCourseRef);
        console.log('email', email);
        // Remove the therapist from the therapists array
        const updatedTherapists = (course?.therapists || []).filter(
          (ref) => ref.id !== therapistId
        );

        // First update the course document
        await updateDoc(therapyCourseRef, {
          therapists: updatedTherapists,
          modified: Timestamp.now(),
          modifiedBy: email,
        });

        // Then find and update the corresponding invitation
        const invitationsQuery = query(
          collection(firestore, 'TherapyCourseInvitation'),
          where('therapyCourse', '==', therapyCourseRef),
          where('status', '==', 'accepted')
        );

        console.log('invitationsQuery', invitationsQuery);
        const invitationsSnapshot = await getDocs(invitationsQuery);

        console.log('invitationsSnapshot', invitationsSnapshot);

        // Find the invitation for this specific therapist
        const therapistInvitation = invitationsSnapshot.docs.find((doc) => {
          const data = doc.data() as TherapyCourseInvitation;
          // Find the invitation where the therapist's document ID matches
          const invitedTherapist = portalUsers?.find(
            (user) => user.email === data.invitedTherapistEmail
          );
          return invitedTherapist?.id === therapistId;
        });

        // Only update the invitation if we found it
        if (therapistInvitation) {
          await updateDoc(therapistInvitation.ref, {
            status: 'removed',
            modified: Timestamp.now(),
            modifiedBy: email,
          });
        }
      } catch (error) {
        console.error('Error removing therapist:', error);
      }
    },
    [email, course?.therapists, therapyCourseRef, firestore, portalUsers]
  );

  const { getOwnerEmail } = useSharedTherapyCourses();

  // const isShared = isTherapyCourseShared(therapyCourseId || null);
  const ownerEmail = isShared ? getOwnerEmail(therapyCourseId || null) : email;

  if (!userProviderData || !taskPoolData || !categoryData || !taskPoolOptions) {
    return <CircularProgress />;
  }
  return (
    <>
      {showUnsavedBanner && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '1em',
            left: '1em',
            right: '1em',
            zIndex: 1500,
          }}
        >
          <Slide in={showUnsavedBanner} direction="up">
            <Alert
              severity="warning"
              sx={{
                borderRadius: 0,
                py: 1,
                px: 2,
                border: '1px solid',
                borderColor: 'warning.main',
                color: 'warning',
                '& .MuiAlert-icon': {
                  color: 'warning',
                },
              }}
              action={
                <Box sx={{ display: 'flex', gap: 1, my: -0.5, p: 0, mr: 1 }}>
                  <Button
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      if (course) {
                        // Restore all form fields to their last saved state
                        setTitle(course.title);
                        setDescription(course.description);
                        setPhases(
                          course.phases
                            ? addMissingIdsToPhases(course.phases)
                            : []
                        );
                        setPatients(course.patients || []);
                        setDefaultInterval(course.defaultInterval || 7);
                        setOngoingPhase(
                          course.ongoingPhase === undefined
                            ? null
                            : course.ongoingPhase
                        );
                        setCurrentPhase(course.ongoingPhase || 0);
                        setTherapyCourseType(course.type || 'group');
                        setCompleted(course.completed || false);
                        setCompletedDate(course.completedDate || null);
                        setTherapistName(course.therapistName || '');
                        setLanguage(
                          course.defaultLanguage || getCurrentLanguageCode()
                        );
                        setDefaultLanguage(
                          course.defaultLanguage || getCurrentLanguageCode()
                        );
                        setPendingInvites([]);
                        setPendingCancellations([]);
                        setPendingMemberRemovals([]);
                        setStartImmediately(false);
                        setHasUnsavedChanges(false);
                      }
                    }}
                    sx={{
                      minWidth: 120,
                    }}
                    startIcon={<UndoIcon />}
                  >
                    {t('Revert Changes')}
                  </Button>
                  <Button
                    loading={updating}
                    variant="contained"
                    onClick={(e) => {
                      e.preventDefault();
                      const form = document.querySelector('form');
                      if (form) form.requestSubmit();
                    }}
                    disabled={
                      updating ||
                      !titleExistsInPrimaryLanguage ||
                      (completed && !startImmediately) ||
                      phaseValidation.hasDateError ||
                      phaseValidation.hasTitleError
                    }
                    sx={{
                      backgroundColor: 'warning.contrastText',
                      color: 'warning.main',
                      '&:hover': {
                        backgroundColor: 'warning.contrastText',
                        opacity: 0.9,
                      },
                      minWidth: 120,
                    }}
                    startIcon={<SaveIcon />}
                  >
                    {t('Save')}
                  </Button>
                </Box>
              }
            >
              <Typography
                variant="body1"
                sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
              >
                <Trans>
                  You have unsaved changes. Please save your work before leaving
                  this page.
                </Trans>
              </Typography>
            </Alert>
          </Slide>
        </Box>
      )}
      <Box>
        {status === 'loading' && <CircularProgress sx={{ mt: 2 }} />}
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error.message}
          </Alert>
        )}
        {collectionError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {collectionError.message}
          </Alert>
        )}
        {taskPoolError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {taskPoolError.message}
          </Alert>
        )}
        {userProviderError && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {userProviderError.message}
          </Alert>
        )}
        {patientCollectionError && (
          <Alert severity="error">{patientCollectionError.message}</Alert>
        )}
        {status === 'success' && (
          <>
            <Box
              sx={{
                mt: 2,
                mb: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="h4" component="h1">
                  {t('Therapy Course Editor')}
                </Typography>
                {isShared && (
                  <Chip
                    label={<Trans>Shared</Trans>}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                )}
              </Box>
              {therapyCourseId !== 'new' && (
                <MuiLink
                  component={Link}
                  to={`/overview/${therapyCourseId}`}
                  sx={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    fontSize: '0.875rem',
                    ml: 2,
                  }}
                >
                  <LinkIcon sx={{ fontSize: '1rem', mr: 0.5 }} />
                  {t('Session View')}
                </MuiLink>
              )}
            </Box>
            {isShared && ownerEmail && (
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                {t('Primary Therapist: {{ownerEmail}}', {
                  ownerEmail: ownerEmail,
                })}
              </Typography>
            )}
            <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
              <Card sx={{ mt: 2 }} variant="outlined">
                <CardHeader
                  title={t('Basics')}
                  subheader={
                    courseIsActive
                      ? t('Active Therapy Course')
                      : t('Inactive Therapy Course')
                  }
                  action={
                    <LanguageSelector
                      language={language}
                      defaultLanguage={defaultLanguage}
                      setLanguage={setLanguage}
                      setDefaultLanguage={setDefaultLanguage}
                    />
                  }
                />
                <CardContent>
                  <ToggleButtonGroup
                    value={therapycoursetype || 'group'}
                    exclusive
                    color="primary"
                    onChange={handleChangeTherapyCourseType}
                    sx={{ marginBottom: '1em' }}
                  >
                    <ToggleButton
                      value="group"
                      disabled={changeTherapyCourseTypeDisabled}
                    >
                      <Trans>Group Therapy</Trans>
                    </ToggleButton>
                    <ToggleButton
                      value="individual"
                      disabled={changeTherapyCourseTypeDisabled}
                    >
                      <Trans>Individual Therapy</Trans>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <TranslatableTextField
                    key={`course-title-${therapyCourseId}-${language}`}
                    name={`course-title-${therapyCourseId}-${language}`}
                    label={t('Therapy Course Name')}
                    helperText={
                      !titleExistsInPrimaryLanguage
                        ? t('Name is required in primary language')
                        : undefined
                    }
                    error={!titleExistsInPrimaryLanguage}
                    language={language}
                    type="title"
                    setValue={setTitle}
                    value={title}
                    placeholder={t('Enter Name')}
                    defaultLanguage={defaultLanguage}
                  />
                  <TextField
                    key={`course-therapist-name`}
                    name="therapist-name"
                    label={t("Therapist's Name")}
                    placeholder={t('Enter Name')}
                    value={therapistName}
                    onChange={onChangeTherapistName}
                    fullWidth
                    sx={{ mt: 2 }}
                  />
                  <TranslatableEditor
                    key={`course-description-${therapyCourseId}-${language}`}
                    name={`course-description-${therapyCourseId}-${language}`}
                    placeholder={t('Description')}
                    value={description}
                    setValue={setDescription}
                    language={language}
                    type="description"
                    defaultLanguage={defaultLanguage}
                  />
                  <Tooltip
                    title={t(
                      'The number of days that will be automatically added to the start date of each phase.'
                    )}
                  >
                    <TextField
                      label={t('Default Interval (Days)')}
                      name="default-interval"
                      type="number"
                      value={defaultInterval}
                      onChange={onChangeDefaultInterval}
                      required
                      sx={{ mt: 2 }}
                    />
                  </Tooltip>
                </CardContent>
              </Card>
              <Card sx={{ mt: 2 }} variant="outlined">
                <CardHeader
                  title={t('Patients')}
                  subheader={t('Select the patients for the group')}
                />
                <CardContent>
                  <PatientManager
                    patients={patients}
                    setPatients={setPatients}
                    email={email}
                    isGroup={therapycoursetype === 'group'}
                    disabled={courseIsCompleted}
                    defaultInvitationLanguage={defaultLanguage}
                  />
                </CardContent>
              </Card>
              <Box
                sx={{
                  borderBottom: 1,
                  borderColor: 'divider',
                  backgroundColor: 'background.paper',
                  mt: 2,
                }}
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  {enabled && (
                    <Droppable droppableId="phases" direction="horizontal">
                      {(provided) => (
                        <Tabs
                          key={tabsKey}
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          aria-label="sessions"
                          value={currentPhase}
                          variant="scrollable"
                          sx={{ overflowX: 'auto' }}
                        >
                          {phases.map((phase, index) => (
                            <Draggable
                              key={phase.id}
                              draggableId={phase.id}
                              index={index}
                            >
                              {(provided) => (
                                <Tab
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  sx={{
                                    fontWeight:
                                      ongoingPhase === index
                                        ? 'bold'
                                        : 'normal',
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    paddingLeft: 1,
                                    color:
                                      phaseValidation.dateErrorPhases.includes(
                                        index
                                      ) ||
                                      phaseValidation.titleErrorPhases.includes(
                                        index
                                      )
                                        ? 'error.main'
                                        : 'inherit',
                                    '& .MuiSvgIcon-root': {
                                      color:
                                        phaseValidation.dateErrorPhases.includes(
                                          index
                                        ) ||
                                        phaseValidation.titleErrorPhases.includes(
                                          index
                                        )
                                          ? 'error.main'
                                          : 'inherit',
                                    },
                                  }}
                                  label={
                                    <>
                                      <span
                                        {...provided.dragHandleProps}
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          cursor: 'grab',
                                        }}
                                        data-drag-handle
                                      >
                                        <DragIndicatorIcon />
                                      </span>
                                      {`${t('Phase')} ${index + 1}`}
                                      {(phaseValidation.dateErrorPhases.includes(
                                        index
                                      ) ||
                                        phaseValidation.titleErrorPhases.includes(
                                          index
                                        )) && (
                                        <Tooltip
                                          title={
                                            <>
                                              {phaseValidation.dateErrorPhases.includes(
                                                index
                                              ) && t('Erroneous date')}
                                              {phaseValidation.dateErrorPhases.includes(
                                                index
                                              ) &&
                                                phaseValidation.titleErrorPhases.includes(
                                                  index
                                                ) &&
                                                ' & '}
                                              {phaseValidation.titleErrorPhases.includes(
                                                index
                                              ) && t('Missing title')}
                                            </>
                                          }
                                        >
                                          <WarningIcon
                                            fontSize="small"
                                            sx={{ ml: 1 }}
                                          />
                                        </Tooltip>
                                      )}
                                    </>
                                  }
                                  {...a11yProps(index)}
                                  onClick={(event) => {
                                    event.preventDefault();
                                    // Check if the click originated from the drag handle
                                    if (
                                      !(event.target as HTMLElement).closest(
                                        '[data-drag-handle]'
                                      )
                                    ) {
                                      setCurrentPhase(index);
                                    }
                                  }}
                                />
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                          <Tab
                            label={t('Add Phase')}
                            {...a11yProps(phases.length)}
                            onClick={() => {
                              // if first phase, startDate is null, otherwise startDate is startDate of previous phase plus defaultInterval at defaultSessionTime
                              const previousPhase =
                                phases.length > 0
                                  ? phases[phases.length - 1]
                                  : null;
                              const startDate =
                                phases.length === 0
                                  ? null
                                  : previousPhase?.startDate
                                  ? Timestamp.fromDate(
                                      dayjs(previousPhase!.startDate!.toDate())
                                        .add(defaultInterval, 'days')
                                        .set('hour', 0)
                                        .set('minute', 0)
                                        .toDate()
                                    )
                                  : null;
                              // end date is startDate plus defaultInterval
                              const endDate = startDate
                                ? Timestamp.fromDate(
                                    dayjs(startDate!.toDate())
                                      .add(defaultInterval, 'days')
                                      .toDate()
                                  )
                                : null;
                              const newId = uuid.v4() as string;

                              setPhases((phases) => [
                                ...phases,
                                {
                                  id: newId,
                                  title: { [defaultLanguage]: '' },
                                  description: { [defaultLanguage]: '' },
                                  startDate,
                                  endDate,
                                  order: phases.length,
                                  patients: patients.map((patient) => ({
                                    id: patient.id,
                                    patient: patient,
                                    activeTasks: {},
                                  })) as TherapyCoursePhasePatient[],
                                  phaseTasks:
                                    [] as DocumentReference<TaskPoolTask>[],
                                },
                              ]);
                              setCurrentPhase(phases.length);
                            }}
                          />
                        </Tabs>
                      )}
                    </Droppable>
                  )}
                </DragDropContext>
              </Box>

              <Card elevation={0}>
                <CardHeader
                  title={
                    phases.length > 0
                      ? t('Phase') + ' ' + (currentPhase + 1)
                      : t('')
                  }
                  subheader={
                    ongoingPhase !== null
                      ? ongoingPhase === currentPhase
                        ? t('Ongoing')
                        : ongoingPhase < currentPhase
                        ? t('Upcoming phase')
                        : t('Completed phase')
                      : courseIsCompleted
                      ? t('Completed course')
                      : t('Not started course')
                  }
                  action={
                    <IconButton
                      disabled={
                        ongoingPhase === currentPhase || phases.length <= 1
                      }
                      onClick={() => {
                        // we need to move the ongoingPhase to the previous phase, if the phase being deleted
                        // is before the ongoingPhase, because it is an index, not an id
                        if (ongoingPhase && ongoingPhase > currentPhase) {
                          setOngoingPhase(ongoingPhase - 1);
                        }
                        // if phase being deleted is the last one, set currentPhase to last phase - 1
                        if (currentPhase === phases.length - 1) {
                          setCurrentPhase(phases.length - 2);
                        }
                        // finally, remove the phase from the phases array
                        setPhases((phases) =>
                          phases.filter((_, i) => i !== currentPhase)
                        );
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                />
                <CardContent>
                  {phases[currentPhase] && (
                    <TherapyCourseEditorPhase
                      key={`tcep-${phases[currentPhase].id}`}
                      phases={phases}
                      currentPhase={currentPhase}
                      language={language}
                      setPhases={setPhases}
                      taskPoolOptions={taskPoolOptions || []}
                      patients={patients}
                      patientCollectionData={patientCollectionData}
                      ongoingPhase={ongoingPhase}
                      defaultLanguage={defaultLanguage}
                    />
                  )}
                </CardContent>
              </Card>
              {(phaseValidation.hasDateError ||
                phaseValidation.hasTitleError) && (
                <Box sx={{ mt: 1 }}>
                  {phaseValidation.hasDateError && (
                    <Alert severity="warning" sx={{ mb: 1 }}>
                      <Trans>
                        Warning: Some phases are not in chronological order.
                        Please check the dates of the highlighted phases.
                      </Trans>
                    </Alert>
                  )}
                  {phaseValidation.hasTitleError && (
                    <Alert severity="warning" sx={{ mb: 1 }}>
                      <Trans>
                        Warning: Some phases are missing titles. Please add
                        titles to all phases.
                      </Trans>
                    </Alert>
                  )}
                </Box>
              )}
              {updateSuccessful && (
                <Alert
                  severity="success"
                  sx={{ mt: 2 }}
                  onClose={() => setUpdateSuccessful(false)}
                >
                  <Trans>Save successful</Trans>
                </Alert>
              )}
              {ongoingPhase === null && (
                <Card elevation={0} sx={{ mt: 2 }}>
                  <CardHeader title={t('Options')} />
                  <CardContent>
                    {completedDate && (
                      <TextField
                        name="completed-date"
                        label={t('Completed Date')}
                        placeholder={t('Choose Completed Date')}
                        value={completedDate.toDate().toLocaleDateString()}
                        disabled
                        fullWidth
                      />
                    )}
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={startImmediately}
                            onChange={handleStartImmediately}
                          />
                        }
                        label={
                          completed
                            ? t('Restart course')
                            : t('Start course immediately')
                        }
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
              )}
              <Box
                sx={{ display: 'flex', gap: 2, mt: 2, alignItems: 'center' }}
              >
                <Button
                  loading={updating}
                  type="submit"
                  variant="contained"
                  disabled={
                    updating ||
                    !titleExistsInPrimaryLanguage ||
                    (completed && !startImmediately) ||
                    phaseValidation.hasDateError ||
                    phaseValidation.hasTitleError
                  }
                >
                  {t('Save')}
                </Button>
                {hasUnsavedChanges && (
                  <>
                    <Button
                      variant="outlined"
                      color="warning"
                      onClick={() => {
                        if (course) {
                          // Restore all form fields to their last saved state
                          setTitle(course.title);
                          setDescription(course.description);
                          setPhases(
                            course.phases
                              ? addMissingIdsToPhases(course.phases)
                              : []
                          );
                          setPatients(course.patients || []);
                          setDefaultInterval(course.defaultInterval || 7);
                          setOngoingPhase(
                            course.ongoingPhase === undefined
                              ? null
                              : course.ongoingPhase
                          );
                          setCurrentPhase(course.ongoingPhase || 0);
                          setTherapyCourseType(course.type || 'group');
                          setCompleted(course.completed || false);
                          setCompletedDate(course.completedDate || null);
                          setTherapistName(course.therapistName || '');
                          setLanguage(
                            course.defaultLanguage || getCurrentLanguageCode()
                          );
                          setDefaultLanguage(
                            course.defaultLanguage || getCurrentLanguageCode()
                          );
                          setPendingInvites([]);
                          setPendingCancellations([]);
                          setPendingMemberRemovals([]);
                          setStartImmediately(false);
                          setHasUnsavedChanges(false);
                        }
                      }}
                    >
                      {t('Revert Changes')}
                    </Button>
                    <Typography
                      variant="body2"
                      color="warning.main"
                      sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                    >
                      <WarningIcon fontSize="small" />
                      {t('Unsaved changes')}
                    </Typography>
                  </>
                )}
              </Box>
              <Table
                sx={{ minWidth: 650, mt: 2 }}
                size="small"
                aria-label="info table"
                padding="none"
              >
                <TableBody
                  sx={{
                    '& .MuiTableCell-root': {
                      borderBottom: 0,
                    },
                  }}
                >
                  {therapyCourseId !== 'new' && created && (
                    <TableRow>
                      <TableCell
                        sx={{ minWidth: 80, borderBottom: 0 }}
                        size="small"
                        variant="head"
                      >
                        {t('Created')}
                      </TableCell>
                      <TableCell
                        sx={{ minWidth: 130, width: '100%', borderBottom: 0 }}
                        size="small"
                      >
                        {created.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  )}
                  {modified && (
                    <TableRow>
                      <TableCell
                        size="small"
                        variant="head"
                        sx={{ borderBottom: 0 }}
                      >
                        {t('Modified')}
                      </TableCell>
                      <TableCell sx={{ borderBottom: 0 }} size="small">
                        {modified.toLocaleString()}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              {therapyCourseRef && (
                <TherapyCourseMembers
                  therapyCourseRef={therapyCourseRef}
                  therapists={therapistUsers}
                  onRemoveTherapist={handleRemoveTherapist}
                  pendingInvites={pendingInvites}
                  setPendingInvites={setPendingInvites}
                  pendingCancellations={pendingCancellations}
                  setPendingCancellations={setPendingCancellations}
                  pendingMemberRemovals={pendingMemberRemovals}
                  setPendingMemberRemovals={setPendingMemberRemovals}
                />
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
