import { useContext, useCallback, useMemo } from 'react';
import { PatientContext, TPatientData } from '../contexts/PatientContext';
import { SharedPatientContext } from '../contexts/SharedPatientContext';
import { Patient } from './firebaseModels';

export interface TCombinedPatientData extends Omit<TPatientData, 'data'> {
  data: (Patient & { isShared?: boolean })[] | null;
  isPatientShared: (patientId: string | null) => boolean;
}

export default function usePatientCollection(): TCombinedPatientData {
  const ownPatientsContext = useContext(PatientContext);
  const sharedPatientsContext = useContext(SharedPatientContext);

  if (ownPatientsContext === null) {
    throw new Error(
      'usePatientCollection must be used within a PatientProvider'
    );
  }

  if (sharedPatientsContext === null) {
    throw new Error(
      'usePatientCollection must be used within a SharedPatientProvider'
    );
  }
  const { data: ownPatientsData, status: ownPatientsStatus, error: ownPatientsError, appUsers: ownAppUsers, appUsersStatus: ownAppUsersStatus, appUsersError: ownAppUsersError } = ownPatientsContext;
  const { data: sharedPatientsData, status: sharedPatientsStatus, error: sharedPatientsError, appUsers: sharedAppUsers, appUsersStatus: sharedAppUsersStatus, appUsersError: sharedAppUsersError } = sharedPatientsContext;

  // Combine the data and mark shared patients
  const combinedData = useMemo(() => {

    const ownPatients = ownPatientsData || [];
    
    const sharedPatients = (sharedPatientsData || []).map(sharedPatient => ({
      ...sharedPatient,
      isShared: true
    }));

    return [...ownPatients, ...sharedPatients];
  }, [ownPatientsData, sharedPatientsData]);

  // Memoize error combination
  const error = useMemo(() => 
    (ownPatientsError || sharedPatientsError)
      ,
    [ ownPatientsError, sharedPatientsError]
  );

  // Memoize app users combination
  const appUsers = useMemo(() => {
    return [
      ...(ownAppUsers || []),
      ...(sharedAppUsers || [])
    ];
  }, [ ownAppUsers, sharedAppUsers]);

  // Memoize app users status
  const appUsersStatus: 'loading' | 'success' | 'error' = useMemo(() => 
     (ownAppUsersStatus === 'error' || sharedAppUsersStatus === 'error'
          ? 'error'
          : ownAppUsersStatus === 'loading' || sharedAppUsersStatus === 'loading'
          ? 'loading'
          : 'success')
      ,[ ownAppUsersStatus, sharedAppUsersStatus]
  );

  // Memoize app users error
  const appUsersError = useMemo(() => 
  ownAppUsersError || sharedAppUsersError
     ,[ownAppUsersError, sharedAppUsersError]
  );

  const isPatientShared = useCallback(
    (patientId: string | null) => {
      if (!patientId || !combinedData) {
        return false;
      }
      const patient = combinedData.find((p) => p.id === patientId);
      return !!patient?.isShared;
    },[combinedData]
  );


  // Memoize status calculation
  const status: 'loading' | 'success' | 'error' = useMemo(() => 
    (
      (ownPatientsStatus === 'error' || sharedPatientsStatus === 'error' || appUsersStatus === 'error')
          ? 'error'
          : (ownPatientsStatus === 'loading' || sharedPatientsStatus === 'loading' || appUsersStatus === 'loading')
          ? 'loading'
          : 'success') 
      ,
    [ownPatientsStatus, sharedPatientsStatus, appUsersStatus]
  );

  // Memoize the return object to prevent unnecessary rerenders
  const returnValue = useMemo(() => ({
    status,
    data: combinedData,
    error,
    appUsers,
    appUsersStatus,
    appUsersError,
    isPatientValid: ownPatientsContext.isPatientValid,
    isPatientValidById: ownPatientsContext.isPatientValidById,
    isPatientShared,
  }), [
    status,
    combinedData,
    error,
    appUsers,
    appUsersStatus,
    appUsersError,
    ownPatientsContext.isPatientValid,
    ownPatientsContext.isPatientValidById,
    isPatientShared,
  ]);

  return returnValue;
}
