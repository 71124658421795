import uuid from 'react-native-uuid';
import { useNavigate } from 'react-router-dom';
import { useCallback, useState } from 'react';
import {
  doc,
  collection,
  addDoc,
  getDoc,
  Timestamp,
  DocumentReference,
} from 'firebase/firestore';
import { useFirestore } from 'reactfire';
import {
  TherapyPlan,
  TherapyCourse,
  TherapyCoursePhase,
  TranslatableField,
  ServiceProvider,
  Patient,
} from '../firebase/firebaseModels';
import useUserProvider from '../firebase/useUserProvider';
import { getEmptyTherapyPlan } from '../firebase/useTherapyPlanDoc';

export function useGenerateCourseFromPlan(
  therapyStartDatetime: Timestamp | null,
  userProviderData: ServiceProvider | null,
  email: string | null,
  therapyPlanId: string | null,
  generatedCourseTitle: TranslatableField,
  patient: DocumentReference<Patient> | null
) {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [error, setError] = useState<Error | null>(null);

  const { status: userProviderStatus, providerRef } = useUserProvider();

  const generateCourseFromPlan = useCallback(
    (therapycoursetype: 'group' | 'individual', defaultLanguage: string) => {
      async function doGenerateCourseFromPlan() {
        const startDate = therapyStartDatetime;
        if (
          !userProviderData?.id ||
          !providerRef ||
          !email ||
          userProviderStatus !== 'success'
        ) {
          return;
        }

        let planData: TherapyPlan;
        if (therapyPlanId) {
          const planRef = doc(firestore, 'TherapyPlan', therapyPlanId);
          const planDoc = await getDoc(planRef);
          if (!planDoc.exists()) {
            setError(new Error('TherapyPlan not found'));
            return;
          }
          planData = planDoc.data() as TherapyPlan;
        } else {
          planData = getEmptyTherapyPlan(
            email,
            defaultLanguage,
            userProviderData.id,
            providerRef
          );
        }

        const therapyCoursePatients = patient
          ? [
              {
                id: patient.id,
                patient: patient,
                activeTasks: {},
              },
            ]
          : [];
        const newCourse: TherapyCourse = {
          title: generatedCourseTitle,
          description: planData.description,
          defaultInterval: planData.defaultInterval,
          ongoingPhase: null,
          completed: false,
          type: therapycoursetype,
          patients:
            therapycoursetype === 'individual' && patient ? [patient] : [],
          phases: planData.phases.map((phase, idx) => {
            const phaseDate = startDate?.toDate() || null;
            startDate &&
              phaseDate?.setDate(startDate.toDate().getDate() + idx * 7);
            const phaseId = uuid.v4() as string;
            const isLastPhase = idx === planData.phases.length - 1;
            let endDate: Timestamp | null = null;
            if (isLastPhase) {
              // Last phase ends 7 days after the start date of the last phase
              const endDateAsDate = phaseDate ? new Date(phaseDate) : null;
              endDateAsDate &&
                endDateAsDate.setDate(endDateAsDate.getDate() + 7);
              endDate = endDateAsDate
                ? Timestamp.fromDate(endDateAsDate)
                : null;
            }
            const newPhase: TherapyCoursePhase = {
              id: phaseId,
              title: phase.title,
              order: phase.order,
              patients: therapyCoursePatients,
              startDate: phaseDate ? Timestamp.fromDate(phaseDate) : null,
              endDate,
              phaseTasks: phase.phaseTasks,
            };
            return newPhase;
          }),
          serviceProviderId: userProviderData.id,
          defaultLanguage,
          languages: planData.languages,
          created: Timestamp.now(),
          createdBy: email,
        };
        const courseRef = collection(
          firestore,
          'ServiceProvider',
          userProviderData.id,
          'UserData',
          email,
          'TherapyCourse'
        );
        addDoc(courseRef, newCourse)
          .then((docRef) => {
            // console.log('Document written with ID: ', docRef.id);
            navigate(`/therapies/${docRef.id}`);
          })
          .catch((error) => {
            console.error('Error adding document: ', error);
            setError(error);
          });
      }
      doGenerateCourseFromPlan();
    },
    [
      therapyStartDatetime,
      userProviderData?.id,
      providerRef,
      email,
      userProviderStatus,
      generatedCourseTitle,
      therapyPlanId,
      patient,
      firestore,
      navigate,
    ]
  );

  return { generateCourseFromPlan, generateError: error };
}
