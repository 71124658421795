import {
  Dialog,
  Tabs,
  Tab,
  Box,
  Checkbox,
  List,
  ListItem,
  ListItemText,
  Button,
  DialogTitle,
  DialogContent,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';
import { CategoryGroupHeader } from '../TherapyPlanEditor/TherapyPlanEditorPhase';
import CloseIcon from '@mui/icons-material/Close';
import { SelectChangeEvent } from '@mui/material/Select';
import { TaskTypeIcon } from '../TaskTypeIcon';
import { getTaskTypeLabels } from '../../constants/taskTypes';
import { useTaskFilter } from '../../hooks/useTaskFilter';
import { TaskFilter } from '../TaskFilter';
import TaskCategoryDot from '../TaskCategoryDot';
import AddTaskIcon from '@mui/icons-material/AddTask';
import WarningIcon from '@mui/icons-material/Warning';

export type TaskPoolSelectorProps = {
  taskPoolOptions: TaskPoolOption[];
  phaseTasksValue: TaskPoolOption[] | null;
  onChangePhaseTasks: (_: any, newValue: TaskPoolOption[]) => void;
};

export function TaskPoolSelector(props: TaskPoolSelectorProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedTaskType, setSelectedTaskType] = useState<string>('all');
  const [selectedCategory, setSelectedCategory] = useState<string>('all');
  const { taskPoolOptions, phaseTasksValue, onChangePhaseTasks } = props;

  // Get unique doc types for tabs instead of task types
  const docTypes = ['default', 'organization', 'own'];

  // Filter tasks based on active tab and other filters
  const filteredTasks = useTaskFilter({
    tasks: taskPoolOptions || [],
    searchQuery,
    selectedTaskType,
    selectedCategory,
    excludeTaskTypes: ['homework', 'morning', 'evening'],
    // Add docType filter based on active tab
    docType: docTypes[activeTab],
  });

  // Group options by docType and category instead of taskType
  const groupedOptions = taskPoolOptions.reduce((acc, option) => {
    if (!acc[option.docType]) {
      acc[option.docType] = {};
    }
    if (!acc[option.docType][option.categoryTitle]) {
      acc[option.docType][option.categoryTitle] = [];
    }
    acc[option.docType][option.categoryTitle].push(option);
    return acc;
  }, {} as Record<string, Record<string, TaskPoolOption[]>>);

  // Map doc types to translated labels
  const docTypeLabels: Record<string, string> = {
    default: t('Standard'),
    own: t('My Own'),
    organization: t('My Clinic'),
  };

  const handleToggleTask = (task: TaskPoolOption) => {
    if (!phaseTasksValue) return;
    const isSelected = phaseTasksValue.some((t) => t.id === task.id);
    const newValue = isSelected
      ? phaseTasksValue.filter((t) => t.id !== task.id)
      : [...phaseTasksValue, task];
    onChangePhaseTasks(null, newValue);
  };

  // Add task type filter handler
  const handleTaskTypeChange = (event: SelectChangeEvent) => {
    setSelectedTaskType(event.target.value);
  };

  // Add category filter handler
  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
  };

  // Get unique categories
  const categories = Array.from(
    new Set(taskPoolOptions.map((option) => option.categoryTitle))
  );

  // Get unique task types for dropdown
  const taskTypes = Array.from(
    new Set(taskPoolOptions.map((option) => option.taskType))
  );

  // Map task types to translated labels
  const taskTypeLabels = getTaskTypeLabels(t);

  if (!taskPoolOptions || phaseTasksValue === null) {
    return null;
  }

  return (
    <>
      <Button
        variant="contained"
        onClick={() => setOpen(true)}
        sx={{
          mb: 2,
          py: 2,
          px: 4,
        }}
        startIcon={<AddTaskIcon />}
      >
        {t('Select Tasks')}
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{ sx: { maxHeight: '80vh' } }}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, borderBottom: 1, borderColor: 'divider' }}
        >
          {t('Select Tasks')}
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ p: 0 }}>
          <Box
            sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}
          >
            <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider' }}>
              <TaskFilter
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                selectedTaskType={selectedTaskType}
                selectedCategory={selectedCategory}
                handleTaskTypeChange={handleTaskTypeChange}
                handleCategoryChange={handleCategoryChange}
                taskTypes={taskTypes}
                categories={categories.map((cat) => ({
                  id: cat,
                  title: cat,
                  color: taskPoolOptions.find(
                    (opt) => opt.categoryTitle === cat
                  )?.categoryColor,
                }))}
                taskTypeLabels={taskTypeLabels}
              />
            </Box>

            <Tabs
              value={activeTab}
              onChange={(_, newValue) => setActiveTab(newValue)}
              variant="scrollable"
              sx={{ px: 2 }}
            >
              {docTypes.map((type) => (
                <Tab key={type} label={docTypeLabels[type] || t(type)} />
              ))}
            </Tabs>

            <Box sx={{ flex: 1, overflow: 'auto' }}>
              {docTypes.map((docType, index) => {
                // Check if there are any filtered tasks for this docType
                const hasFilteredTasks = filteredTasks.some(
                  (task) => task.docType === docType
                );

                return (
                  <Box
                    key={docType}
                    role="tabpanel"
                    hidden={activeTab !== index}
                    sx={{ p: 2 }}
                  >
                    {!hasFilteredTasks ? (
                      <Box
                        sx={{
                          textAlign: 'center',
                          py: 2,
                          color: 'text.secondary',
                        }}
                      >
                        {t('No tasks found matching the current filters')}
                      </Box>
                    ) : (
                      Object.entries(groupedOptions[docType] || {}).map(
                        ([category, tasks]) => {
                          // Filter tasks for this category using the filtered results
                          const categoryTasks = filteredTasks.filter(
                            (task) => task.categoryTitle === category
                          );

                          if (categoryTasks.length === 0) {
                            return null;
                          }

                          return (
                            <Box key={category} sx={{ mb: 3 }}>
                              <CategoryGroupHeader>
                                <div
                                  style={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    backgroundColor: 'white',
                                    height: '2em',
                                    fontWeight: 'bold',
                                    padding: '0 8px',
                                    borderRadius: 4,
                                  }}
                                >
                                  <TaskCategoryDot
                                    color={
                                      taskPoolOptions.find(
                                        (opt) => opt.categoryTitle === category
                                      )?.categoryColor || null
                                    }
                                    categoryName={category}
                                    size={14}
                                  />
                                  {category}
                                </div>
                              </CategoryGroupHeader>
                              <List dense>
                                {categoryTasks.map((task) => (
                                  <ListItem
                                    key={task.id}
                                    dense
                                    component="div"
                                    onClick={() => handleToggleTask(task)}
                                    sx={{ py: 0 }}
                                  >
                                    <Checkbox
                                      edge="start"
                                      checked={phaseTasksValue.some(
                                        (t) => t.id === task.id
                                      )}
                                    />
                                    {task.languageNotAvailable && (
                                      <Tooltip
                                        title={t(
                                          'Task not available in selected language'
                                        )}
                                      >
                                        <WarningIcon
                                          color="warning"
                                          sx={{ mr: 1 }}
                                        />
                                      </Tooltip>
                                    )}

                                    <ListItemText
                                      primary={task.translatedTitle}
                                    />
                                    <Box component="span">
                                      <TaskTypeIcon type={task.taskType} />
                                    </Box>
                                  </ListItem>
                                ))}
                              </List>
                            </Box>
                          );
                        }
                      )
                    )}
                  </Box>
                );
              })}
            </Box>
          </Box>
        </DialogContent>
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setOpen(false)}>{t('OK')}</Button>
        </Box>
      </Dialog>
    </>
  );
}
