import { useState, useCallback, useMemo } from 'react';
import { useAuth } from 'reactfire';
import {
  Container,
  Box,
  Button,
  Typography,
  TextField,
  Alert,
  Paper,
  LinearProgress,
  IconButton,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import { updatePassword } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { updateDoc } from 'firebase/firestore';
import usePortalUser from '../firebase/usePortalUser';
import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import * as zxcvbnCommonPackage from '@zxcvbn-ts/language-common';
import * as zxcvbnEnPackage from '@zxcvbn-ts/language-en';
import * as zxcvbnFiPackage from '@zxcvbn-ts/language-fi';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type PasswordChangeForm = {
  newPassword: string;
  confirmPassword: string;
};

export default function RequirePasswordChange({
  children,
}: {
  children: JSX.Element;
}) {
  const { t, i18n } = useTranslation();
  const auth = useAuth();
  const { data: userData, userRef } = usePortalUser();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordChangeForm>();

  const currentLanguage = i18n.language.split('-')[0];

  const options = useMemo(
    () => ({
      translations:
        currentLanguage === 'fi'
          ? zxcvbnFiPackage.translations
          : zxcvbnEnPackage.translations,
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...(currentLanguage === 'fi'
          ? zxcvbnFiPackage.dictionary
          : zxcvbnEnPackage.dictionary),
      },
    }),
    [currentLanguage]
  );

  zxcvbnOptions.setOptions(options);

  const watchedPassword = watch('newPassword') || '';
  const zxcvbnResult = zxcvbn(watchedPassword);
  const passwordStrength = (zxcvbnResult?.score || 0) * 25;

  const onSubmit: SubmitHandler<PasswordChangeForm> = useCallback(
    async (data) => {
      if (!auth.currentUser || !userRef) return;

      setLoading(true);
      setError(null);

      try {
        // Update password in Firebase Auth
        await updatePassword(auth.currentUser, data.newPassword);

        // Update requirePasswordChange flag in Firestore
        await updateDoc(userRef, {
          requirePasswordChange: false,
          passwordExpiresAt: null,
        });
      } catch (err) {
        const fbError = err as FirebaseError;
        setError(fbError.message);
        setLoading(false);
        return;
      }

      setLoading(false);
    },
    [auth.currentUser, userRef]
  );

  if (!userData?.requirePasswordChange) {
    return children;
  }

  return (
    <Container component="main" maxWidth="sm">
      <Paper elevation={3} sx={{ mt: 8, p: 4 }}>
        <Typography component="h1" variant="h5" gutterBottom>
          <Trans>Password Change Required</Trans>
        </Typography>
        <Typography color="text.secondary" paragraph>
          <Trans>
            For security reasons, you need to change your password before
            continuing.
          </Trans>
        </Typography>

        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <TextField
            sx={{ display: 'none' }}
            type="text"
            autoComplete="username"
            value={auth.currentUser?.email || ''}
          />

          <TextField
            margin="normal"
            required
            fullWidth
            type={showPassword ? 'text' : 'password'}
            label={t('New Password')}
            {...register('newPassword', {
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
            })}
            error={!!errors.newPassword}
            helperText={errors.newPassword?.message}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />

          {watchedPassword && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {t('Password Strength')}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={passwordStrength}
                color={
                  passwordStrength < 50
                    ? 'error'
                    : passwordStrength < 75
                    ? 'warning'
                    : 'success'
                }
                sx={{ height: 8, borderRadius: 4 }}
              />
              {watchedPassword && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mt: 1, display: 'block' }}
                >
                  {zxcvbnResult.feedback.warning}
                </Typography>
              )}
            </Box>
          )}

          <TextField
            margin="normal"
            required
            fullWidth
            type={showConfirmPassword ? 'text' : 'password'}
            label={t('Confirm New Password')}
            {...register('confirmPassword', {
              required: t('Please confirm your password'),
              validate: (val: string) => {
                if (watchedPassword !== val) {
                  return t('Passwords do not match');
                }
              },
            })}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          <Button
            type="submit"
            fullWidth
            variant="contained"
            loading={loading}
            sx={{ mt: 3 }}
          >
            <Trans>Change Password</Trans>
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
