import { useState, useEffect } from 'react';

const getSHA256Hash = async (input: string) => {
  const textAsBuffer = new TextEncoder().encode(input);
  const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  return hashArray.map((item) => item.toString(16).padStart(2, '0')).join('');
};
export function useCalculateStatisticsDataHash(
  therapyCoursePatientId: string | null
) {
  const [hash, setHash] = useState<string | null>(null);

  useEffect(() => {
    if (!therapyCoursePatientId) {
      setHash(null);
      return;
    }

    const message = `console-cache-${therapyCoursePatientId}`;
    getSHA256Hash(message).then((hash) => {
      setHash(hash);
    });
  }, [therapyCoursePatientId]);

  return hash;
}
