import { useMemo } from 'react';

/**
 * Hook to determine the appropriate language to use based on the following criteria:
 * 1. If defaultLanguage is in the user's therapyLanguages, use it
 * 2. Otherwise, use the first therapyLanguage that's found in the task's languages
 * 3. If the task has no languages yet, use the first therapyLanguage
 * 4. If all else fails, use English ('en')
 * 
 * @param defaultLanguage The current default language
 * @param therapyLanguages User's therapy languages array
 * @param taskLanguages Languages available in the task (obtained from task.languages or Object.keys(task.title))
 * @returns The appropriate language code
 */
export default function useAppropriateLanguage(
  defaultLanguage: string,
  therapyLanguages: string[],
  taskLanguages: string[]
): string {
  return useMemo(() => {
    // If defaultLanguage is in therapyLanguages, use it
    if (therapyLanguages.includes(defaultLanguage)) {
      return defaultLanguage;
    }

    // If task has languages, find the first therapyLanguage that's in taskLanguages
    if (taskLanguages.length > 0) {
      for (const lang of therapyLanguages) {
        if (taskLanguages.includes(lang)) {
          return lang;
        }
      }
    }

    // If task has no languages or no match found, use the first therapyLanguage
    if (therapyLanguages.length > 0) {
      return therapyLanguages[0];
    }

    // If all else fails, use English
    return 'en';
  }, [defaultLanguage, therapyLanguages, taskLanguages]);
} 