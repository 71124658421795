import { ref, uploadBytes } from 'firebase/storage';
import { useState, useCallback } from 'react';
import { useStorage } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import usePortalUser from '../firebase/usePortalUser';
import { CDN_URL, useEmulator } from '../consts';
export function useUpload(
  file: File | null,
  basePath: string,
  onError?: (error: Error) => void
) {
  const [publicUrl, setPublicUrl] = useState<string | null>(null);

  const storage = useStorage();
  const { data: userProviderData } = useUserProvider();
  const { data: portalUserData } = usePortalUser();

  const uploadFile = useCallback(
    (callback?: (url: string | null) => void) => {
      if (!userProviderData?.id || !portalUserData?.adminUid) {
        const error = new Error('Upload failed: Missing user data');
        onError?.(error);
        console.warn(error.message);
        return;
      }

      if (file) {
        const imageRef = ref(
          storage,
          `${userProviderData.id}/${portalUserData?.adminUid}/${basePath}/${file.name}`
        );

        const metadata = {
          contentType: file.type,
        };
        uploadBytes(imageRef, file, metadata)
          .then((snapshot) => {
            // Construct CDN URL directly instead of using getDownloadURL
            const url = useEmulator
              ? `${CDN_URL}/${userProviderData.id}%2F${portalUserData.adminUid}%2F${basePath}%2F${file.name}?alt=media`
              : `${CDN_URL}/${userProviderData.id}/${portalUserData.adminUid}/${basePath}/${file.name}`;
            setPublicUrl(url);
            callback && callback(url);
          })
          .catch((err) => {
            onError?.(err);
            console.error(`Upload failed for ${file.name}:`, err);
            callback && callback(null);
          });
      } else {
        console.warn('Upload skipped: No file provided');
        callback && callback(null);
      }
    },
    [userProviderData, portalUserData, file, storage, basePath, onError]
  );

  return { publicUrl, uploadFile };
}
