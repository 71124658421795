import { useCallback, useState } from 'react';
import { useAuth } from 'reactfire';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Trans, useTranslation } from 'react-i18next';
import SVGLogo from '../components/SVGLogo';
import { useTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { sendPasswordResetEmail } from 'firebase/auth';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Alert, AlertTitle } from '@mui/material';
import { FirebaseError } from 'firebase/app';

type TForgotPasswordForm = {
  email: string;
};

export default function ForgotPassword(): JSX.Element {
  const auth = useAuth();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { register, handleSubmit } = useForm<TForgotPasswordForm>();

  const [error, setError] = useState<FirebaseError | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  const resetPassword = useCallback(
    (email: string) => {
      setError(null);
      setMessage(null);
      setSuccess(false);
      sendPasswordResetEmail(auth, email.trim().toLowerCase(), {
        url: window.location.origin,
      })
        .then(() => {
          setMessage('Password reset email sent!');
          setSuccess(true);
        })
        .catch((error) => {
          setError(error as FirebaseError);
        });
    },
    [auth]
  );

  const onSubmit: SubmitHandler<TForgotPasswordForm> = useCallback(
    async (data) => {
      resetPassword(data.email);
    },
    [resetPassword]
  );

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <SVGLogo
          style={{
            width: '334px',
            height: '58px',
            marginBottom: '1.125rem',
          }}
          fill={palette.primary.main}
        />
        <Typography variant="h5" sx={{ mt: 1 }} color="text.secondary">
          <Trans>Forgot Password?</Trans>
        </Typography>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Email Address')}
            placeholder={t('Enter Email Address')}
            autoComplete="email"
            autoFocus
            {...register('email')}
          />

          <Button
            type="submit"
            disabled={success}
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 2 }}
          >
            <Trans>Request Password Reset</Trans>
          </Button>
          {error && (
            <Alert severity="error">
              <Trans>{error.code}</Trans>
            </Alert>
          )}
          {success && (
            <Alert severity="success">
              <AlertTitle>{t('Success')}</AlertTitle>
              <Trans>{message}</Trans>
            </Alert>
          )}
        </Box>
        <Box mt={2}>
          <Link href="/login" variant="body2">
            {t('Back to Sign In')}
          </Link>
        </Box>
      </Box>
    </Container>
  );
}
