import { FirebaseError } from 'firebase/app';
import { useState, useMemo, useEffect, useCallback } from 'react';
import { stripHtml } from '../utils';
import { TranslatableField } from './firebaseModels';
import { useVertexAI } from '../contexts/VertexAIContext';

const LANGUAGE_NAMES: {
  [key: string]: string;
} = {
  fi: 'Finnish',
  en: 'English',
  sv: 'Swedish',
};

let translationSuggestionCache: {
  [key: string]: string;
} = {};

interface ImagePlaceholder {
  placeholder: string;
  original: string;
}

function prepareTextForTranslation(text: string): { 
  preparedText: string; 
  imagePlaceholders: ImagePlaceholder[] 
} {
  const imagePlaceholders: ImagePlaceholder[] = [];
  let counter = 0;

  // Replace base64 image sources with placeholders
  const preparedText = text.replace(
    /<img[^>]+src="data:image\/[^>]+base64,[^>"]+"[^>]*>/g,
    (match) => {
      const placeholder = `[[IMAGE_PLACEHOLDER_${counter}]]`;
      imagePlaceholders.push({ placeholder, original: match });
      counter++;
      return placeholder;
    }
  );

  return { preparedText, imagePlaceholders };
}

function restoreImagesInTranslation(
  translation: string, 
  imagePlaceholders: ImagePlaceholder[]
): string {
  let restoredText = translation;
  imagePlaceholders.forEach(({ placeholder, original }) => {
    restoredText = restoredText.replace(placeholder, original);
  });
  return restoredText;
}

export function useTranslationSuggestion(
  field: TranslatableField | null,
  language: string,
  type: 'title' | 'description' | 'content',
  fetch: boolean,
  defaultLanguage: string,
  additionalReferenceMaterial?: string
) {
  const [loading, setLoading] = useState(false);
  const [translationSuggestion, setTranslationSuggestion] = useState('');
  const [error, setError] = useState<FirebaseError | null>(null);

  const { loading: aiLoading, error: aiError, vertexAI, model } = useVertexAI();

  const fieldInDefaultLanguage =
    field && defaultLanguage && field[defaultLanguage];

  const defaultLanguageName =
    defaultLanguage && LANGUAGE_NAMES[defaultLanguage];

  const languageName = LANGUAGE_NAMES[language];
  const currentStrippedValue =
    field && field[language] ? stripHtml(field[language]) : '';

  const strippedFieldInDefaultLanguage = useMemo(() => {
    return fieldInDefaultLanguage ? stripHtml(fieldInDefaultLanguage) : '';
  }, [fieldInDefaultLanguage]);

  const cacheKey = useMemo(() => {
    return strippedFieldInDefaultLanguage
      ? `${fieldInDefaultLanguage}§${language}`
      : '';
  }, [fieldInDefaultLanguage, language, strippedFieldInDefaultLanguage]);

  const canFetch = useMemo(() => {
    return (
      !loading &&
      !!vertexAI &&
      !!model &&
      !!strippedFieldInDefaultLanguage &&
      !!cacheKey
    );
  }, [loading, vertexAI, model, strippedFieldInDefaultLanguage, cacheKey]);

  useEffect(() => {
    if (
      aiLoading ||
      aiError ||
      !vertexAI ||
      !model ||
      !strippedFieldInDefaultLanguage ||
      !cacheKey ||
      !field
    ) {
      return;
    }

    const generate = async () => {
      try {
        // Only proceed if there are existing translations
        const translations = Object.entries(field).filter(([_, text]) => text.trim() !== '');
        if (translations.length === 0) {
          setError(new FirebaseError('no-translations', 'No existing translations found'));
          return;
        }

        // Prepare translations by replacing base64 images with placeholders
        const preparedTranslations = translations.map(([lang, text]) => {
          const { preparedText, imagePlaceholders } = prepareTextForTranslation(text);
          return { lang, preparedText, imagePlaceholders };
        });

        // Ensure default language is included and limit to max 3 translations
        const MAX_TRANSLATIONS = 3;
        let selectedTranslations = preparedTranslations.filter(t => t.lang === defaultLanguage);
        const otherTranslations = preparedTranslations.filter(t => t.lang !== defaultLanguage);
        
        // Add other translations up to the limit
        if (otherTranslations.length > 0) {
          selectedTranslations.push(...otherTranslations.slice(0, MAX_TRANSLATIONS - selectedTranslations.length));
        }

        // Create a string of selected translations with prepared text
        const existingTranslations = selectedTranslations
          .map(({ lang, preparedText }) => 
            `-------- ${LANGUAGE_NAMES[lang]} --------\n${preparedText}`
          )
          .join('\n');

        let prompt = `Translate the following ${type} into professional ${languageName}. Do not add any explanations or labels, just give the translation in ${languageName} without any additional text. If there is html present, keep it in the translation. The source material is:\n${existingTranslations}`;
        // console.log(prompt);
        if(additionalReferenceMaterial) {
          prompt += `\n\n-------- Additional reference material --------\n${additionalReferenceMaterial}`;
        }
        
        const result = await model.generateContent(prompt);
        const { response } = result;
        let translatedText = response.text();

        // console.log('translatedText', translatedText);
        // Restore images in the translation using placeholders from the default language
        const defaultLangPreparation = selectedTranslations.find(
          t => t.lang === defaultLanguage
        );
        if (defaultLangPreparation) {
          translatedText = restoreImagesInTranslation(
            translatedText, 
            defaultLangPreparation.imagePlaceholders
          );
        }

        setTranslationSuggestion(translatedText);
        translationSuggestionCache[cacheKey] = translatedText;
        setError(null);
      } catch (e) {
        console.error(e);
        setError(e as FirebaseError);
        setTranslationSuggestion('');
      } finally {
        setLoading(false);
      }
    };

    if (!currentStrippedValue && fieldInDefaultLanguage) {
      // check cache
      if (translationSuggestionCache[cacheKey]) {
        setTranslationSuggestion(translationSuggestionCache[cacheKey]);
      } else if (!loading && fetch) {
        setTranslationSuggestion('');
        setLoading(true);
        generate();
      }
    } else {
      setTranslationSuggestion('');
      setLoading(false);
    }
  }, [
    aiLoading,
    aiError,
    vertexAI,
    model,
    field,
    language,
    loading,
    type,
    fetch,
    defaultLanguage,
    languageName,
    defaultLanguageName,
    cacheKey,
    fieldInDefaultLanguage,
    strippedFieldInDefaultLanguage,
    currentStrippedValue,
  ]);

  const reset = useCallback(() => {
    setTranslationSuggestion('');
    setLoading(false);
    setError(null);
  }, []);

  return {
    loading,
    aiLoading,
    aiError,
    translationSuggestion,
    canFetch,
    fieldInDefaultLanguage,
    defaultLanguageName,
    reset,
    error,
  };
}
