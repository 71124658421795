/**
 * @file TherapyCourseEditorPhase.tsx
 * Component to edit a single phase of a therapy course
 * @module TherapyCourseEditor/TherapyCourseEditorPhase
 */
import { Trans, useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Grid from '@mui/material/Grid';
import { AutocompleteValue } from '@mui/material/Autocomplete';
import dayjs, { Dayjs } from 'dayjs';
import { doc, DocumentReference, Timestamp } from 'firebase/firestore';
import {
  Patient,
  TaskPoolTask,
  TherapyCoursePhase,
} from '../../firebase/firebaseModels';
import {
  Alert,
  AlertTitle,
  CircularProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useFirestore } from 'reactfire';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import { PhasePatient } from './PhasePatient';
import useTaskPoolTaskCategoryCollection from '../../firebase/useTaskPoolTaskCategoryCollection';
import TranslatableTextField from '../TranslatableTextField';
import { TaskPoolOption } from '../../hooks/useTaskPoolOptions';
import { TaskPoolSelector } from './TaskPoolSelector';
import { usePhaseTasksValue } from './usePhaseTasksValue';

type TherapyCourseEditorPhaseProps = {
  phases: TherapyCoursePhase[];
  currentPhase: number;
  setPhases: Dispatch<SetStateAction<TherapyCoursePhase[]>>;
  taskPoolOptions: TaskPoolOption[];
  patients: DocumentReference<Patient>[];
  patientCollectionData: Patient[] | null;
  ongoingPhase: number | null;
  language: string;
  defaultLanguage: string;
};

export default function TherapyCourseEditorPhase(
  props: TherapyCourseEditorPhaseProps
) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const { status: taskPoolStatus, data: taskPoolData } =
    useTaskPoolCollection();
  const {
    phases,
    currentPhase,
    setPhases,
    taskPoolOptions,
    patients,
    patientCollectionData,
    language,
    defaultLanguage,
  } = props;

  const phaseTasks = useMemo(
    () => phases[currentPhase]?.phaseTasks || [],
    [phases, currentPhase]
  );

  const isLastPhase = currentPhase === phases.length - 1;

  const currentPhaseObj = phases[currentPhase];

  const startTimeValue = useMemo<Dayjs | null>(
    () =>
      currentPhaseObj.startDate
        ? dayjs(currentPhaseObj.startDate.toDate())
        : null,
    [currentPhaseObj.startDate]
  );

  const endTimeValue = useMemo(
    () =>
      phases[currentPhase].endDate
        ? dayjs(phases[currentPhase].endDate?.toDate())
        : null,
    [phases, currentPhase]
  );

  const {
    status: categoryStatus,
    data: categoryData,
    error: collectionError,
  } = useTaskPoolTaskCategoryCollection();

  const phaseTasksValue: TaskPoolOption[] | null = usePhaseTasksValue(
    phaseTasks,
    taskPoolData,
    categoryData,
    language
  );

  const handleStartTimeChange = useCallback(
    (dayjsDate: Dayjs | null) => {
      const newPhase = phases[currentPhase];
      newPhase.startDate = dayjsDate
        ? Timestamp.fromDate(dayjsDate.toDate())
        : null;
      setPhases([
        ...phases.slice(0, currentPhase),
        newPhase,
        ...phases.slice(currentPhase + 1),
      ]);
    },
    [currentPhase, phases, setPhases]
  );

  const handleEndTimeChange = useCallback(
    (dayjsDate: Dayjs | null) => {
      const newPhase = phases[currentPhase];
      newPhase.endDate =
        dayjsDate !== null ? Timestamp.fromDate(dayjsDate?.toDate()) : null;
      setPhases([
        ...phases.slice(0, currentPhase),
        newPhase,
        ...phases.slice(currentPhase + 1),
      ]);
    },
    [currentPhase, phases, setPhases]
  );

  const onChangePhaseTasks = useCallback(
    (
      _: any,
      newValue: AutocompleteValue<
        {
          id: string;
          translatedTitle: string;
          categoryTitle: string;
          categoryColor: string;
        },
        true,
        false,
        false
      >
    ) => {
      setPhases((phases) => {
        const newPhase = phases[currentPhase];
        const newPhaseTasks = newValue.map(
          (v) =>
            doc(
              firestore,
              'TaskPool',
              v.id as string
            ) as DocumentReference<TaskPoolTask>
        );

        // Update phase tasks
        newPhase.phaseTasks = newPhaseTasks;

        // Update task order for each patient
        newPhase.patients = newPhase.patients.map((patient) => {
          const currentTaskOrder = patient.taskOrder || {};
          const newTaskOrder: Record<string, number> = {};

          // Keep existing order for existing tasks
          newPhaseTasks.forEach((task) => {
            if (task.id in currentTaskOrder) {
              newTaskOrder[task.id] = currentTaskOrder[task.id];
            } else {
              // For new tasks, assign order based on their position
              const taskIndex = newValue.findIndex((v) => v.id === task.id);
              newTaskOrder[task.id] = (taskIndex + 1) * 10;
            }
          });

          return {
            ...patient,
            taskOrder: newTaskOrder,
          };
        });

        return [
          ...phases.slice(0, currentPhase),
          newPhase,
          ...phases.slice(currentPhase + 1),
        ];
      });
    },
    [currentPhase, firestore, setPhases]
  );
  if (categoryStatus === 'loading' || taskPoolStatus === 'loading') {
    return <CircularProgress />;
  }
  return (
    <>
      {collectionError && (
        <Alert severity="error">
          <AlertTitle>
            <Trans>Error Getting Task Categories</Trans>
          </AlertTitle>
          {collectionError.message}
        </Alert>
      )}
      <TranslatableTextField
        key={`phase-title-${language}-${currentPhase}`}
        name={`phase-title-${language}-${currentPhase}`}
        label={t('Phase Name')}
        placeholder={t('Enter phase name')}
        value={phases[currentPhase].title}
        language={language}
        type="title"
        onChange={(e) => {
          const { value } = e.target;
          const newPhase = phases[currentPhase];
          newPhase.title = {
            ...newPhase.title,
            [language]: value,
          };
          setPhases([
            ...phases.slice(0, currentPhase),
            newPhase,
            ...phases.slice(currentPhase + 1),
          ]);
        }}
        defaultLanguage={defaultLanguage}
      />
      <Grid
        container
        spacing={2}
        sx={{ mt: 0 }}
        columns={{ xs: 6, md: 12, lg: 12, xl: 12 }}
      >
        <Grid item xs={6} md={4} lg={3} xl={3}>
          <Tooltip
            title={t(
              'Therapy session date is optional. If the date is left blank, it will be determined automatically based on when the phase is started.'
            )}
          >
            <div style={{ display: 'inline-flex' }}>
              <DatePicker
                label={t('Therapy Session Date')}
                value={startTimeValue}
                onChange={handleStartTimeChange}
              />
            </div>
          </Tooltip>
        </Grid>
        {isLastPhase && (
          <Grid item xs={6} md={4} lg={3} xl={3}>
            <Tooltip
              title={t(
                'Therapy course end date is optional. If the date is left blank, it will be determined automatically based on the last phase end date.'
              )}
            >
              <div style={{ display: 'inline-flex' }}>
                <DatePicker
                  label={t('Course End Date')}
                  value={endTimeValue}
                  onChange={handleEndTimeChange}
                />
              </div>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        <Trans>Tasks for this Phase</Trans>
      </Typography>
      <TaskPoolSelector
        taskPoolOptions={taskPoolOptions}
        phaseTasksValue={phaseTasksValue}
        onChangePhaseTasks={onChangePhaseTasks}
      />
      {patients.length > 0 && phaseTasks.length > 0 && (
        <>
          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            <Trans>Patient Specific Selections For the Phase</Trans>
          </Typography>
          {patients.map((patientRef) => {
            const patient = patientCollectionData?.find(
              (pcp) => pcp.id === patientRef.id
            );
            return (
              patient && (
                <PhasePatient
                  key={patient?.id}
                  phase={phases[currentPhase]}
                  patientRef={patientRef}
                  patient={patient}
                  currentPhase={currentPhase}
                  setPhases={setPhases}
                  taskPoolOptions={taskPoolOptions}
                  language={language}
                />
              )
            );
          })}
        </>
      )}
    </>
  );
}
