import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import { getFormattedDate } from '../../utils';
import { MainDiaryPeriod } from './MainDiaryPeriod';
import { NapPeriods } from './NapPeriods';
import { WakePeriod } from './WakePeriod';
// import HomeworkDot from './HomeworkDot';
import HourDividers from './HourDividers';
import {
  GenericEntry,
  StatisticDay,
  SleepEfficiencyGenericData,
  SleepEfficiencyMorningData,
  SleepEfficiencyEveningData,
} from '../TherapyCourseOverview/types';
import HomeworkDot from './HomeworkDot';
import useTaskPoolCollection from '../../firebase/useTaskPoolCollection';
import useTaskPoolTaskCategoryCollection from '../../firebase/useTaskPoolTaskCategoryCollection';
import { getTranslatableField } from '../../services/i18n';
import { useTranslation } from 'react-i18next';
export const DATE_LABEL_WIDTH = 70;

interface DayViewProps {
  statisticDate: Date;
  dateLabel: string;
  statistics: StatisticDay[];
  fullWidth: number;
  dayWidth: number;
  hourWidth: number;
  dayHeight: number;
}

type GenericMarkingDot = {
  marking: GenericEntry;
  color?: string;
  label?: string;
};

export function DayView({
  statisticDate,
  dateLabel,
  statistics,
  fullWidth,
  dayWidth,
  hourWidth,
  dayHeight,
}: DayViewProps) {
  const { t } = useTranslation();
  const { data: taskPoolData } = useTaskPoolCollection();
  const { data: categoryData } = useTaskPoolTaskCategoryCollection();

  const cutDateAsStr = getFormattedDate(statisticDate);

  const nextDate = useMemo(() => {
    const nextDateTmp = new Date(statisticDate);
    nextDateTmp.setDate(nextDateTmp.getDate() + 1);
    return nextDateTmp;
  }, [statisticDate]);

  const dateStr = getFormattedDate(statisticDate);

  // find the day's statistics
  const dayStatistics = statistics.find((s) => s.marking_date === dateStr);

  // Safely handle the sleep efficiency data
  const genericMarkingDots = useMemo<GenericMarkingDot[]>(() => {
    // Exit early if data is missing or malformed
    if (!dayStatistics?.sleep_efficiency_data) return [];

    const data = dayStatistics.sleep_efficiency_data;
    if (!Array.isArray(data)) return [];

    // Cast to unknown first and then to an array type we can work with
    // This is necessary because sleep_efficiency_data is a union type
    const dataArray = data as unknown as Array<
      | SleepEfficiencyMorningData
      | SleepEfficiencyEveningData
      | SleepEfficiencyGenericData
    >;

    // Filter for generic entries only
    const genericEntries = dataArray.filter(
      (item): item is SleepEfficiencyGenericData =>
        item !== null &&
        typeof item === 'object' &&
        'type' in item &&
        item.type === 'generic'
    );

    return genericEntries.map((entry: SleepEfficiencyGenericData) => {
      const genericTaskEntry = entry.raw_data;

      // Use optional chaining to safely navigate potentially undefined properties
      const genericTask = taskPoolData?.find(
        (task) => task.id === genericTaskEntry.genericTaskId
      );

      const taskCategory = categoryData?.find(
        (category) => category.id === genericTask?.category?.id
      );

      // Create and return a new object without mutating anything
      return {
        marking: genericTaskEntry,
        color: taskCategory?.color,
        label: getTranslatableField(
          genericTask?.title ?? { fi: 'Kotitehtävä', en: 'Homework' }
        ),
      };
    });
  }, [dayStatistics, taskPoolData, categoryData]);

  const memoizedHomeworkDots = useMemo(
    () =>
      genericMarkingDots.map((dot: GenericMarkingDot, i: number) => (
        <HomeworkDot
          key={`dot-${dateStr}-${i}`}
          marking={dot.marking}
          cutDateAsStr={cutDateAsStr}
          hourWidth={hourWidth}
          dayHeight={dayHeight}
          dotColor={dot.color}
          label={dot.label}
        />
      )),
    [genericMarkingDots, dateStr, cutDateAsStr, hourWidth, dayHeight]
  );

  return (
    <Box
      key={`dvc-${cutDateAsStr}`}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: dayHeight,
        mb: 0.25,
      }}
    >
      {/* Date label */}
      <Box
        sx={{
          display: 'inline-flex',
          position: 'absolute',
          width: `${DATE_LABEL_WIDTH}px`,
          height: dayHeight,
          alignItems: 'center',
        }}
      >
        <Typography variant="caption">{dateLabel}</Typography>
      </Box>

      {/* Chart area containing all sleep and activity indicators */}
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          overflow: 'hidden',
          height: dayHeight,
          width: `${dayWidth}%`,
          ml: `${DATE_LABEL_WIDTH}px`,
        }}
      >
        {/* Main sleep period for the current day */}
        <MainDiaryPeriod
          key={`maindiaryperiod-${cutDateAsStr}`}
          statisticDate={statisticDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
          isNextDay={false}
        />

        {/* Wake period */}
        <WakePeriod
          key={`wakeperiod-${cutDateAsStr}`}
          statisticDate={statisticDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
        />

        {/* Main sleep period extending into the next day */}
        <MainDiaryPeriod
          key={`maindiaryperiod-${cutDateAsStr}-next`}
          statisticDate={nextDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
          isNextDay={true}
        />

        {/* Nap periods */}
        <NapPeriods
          key={`napperiods-${cutDateAsStr}`}
          statisticDate={statisticDate}
          cutDateAsStr={cutDateAsStr}
          statistics={statistics}
          dayHeight={dayHeight}
          hourWidth={hourWidth}
        />

        {/* Visual dividers for hours */}
        <HourDividers hourWidth={hourWidth} />

        {/* Indicator for homework completion */}
        <HomeworkDot
          statistics={statistics}
          cutDateAsStr={cutDateAsStr}
          hourWidth={hourWidth}
          dayHeight={dayHeight}
          label={t('Homework')}
        />
        {memoizedHomeworkDots}
      </Box>
    </Box>
  );
}
