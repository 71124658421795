import { ref, uploadBytesResumable } from 'firebase/storage';
import { useState, useCallback } from 'react';
import { useStorage } from 'reactfire';
import useUserProvider from '../firebase/useUserProvider';
import usePortalUser from '../firebase/usePortalUser';
import { CDN_URL, useEmulator } from '../consts';

type ProgressiveUploadParams = {
  onFinished?: (url: string) => void;
  onError?: (error: Error) => void;
  onProgress?: (progress: number) => void;
};
export function useProgressiveUpload(file: File | null, baseUrl: string) {
  const storage = useStorage();
  const [progress, setProgress] = useState<number | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const { data: userData } = usePortalUser();

  const { data: userProviderData } = useUserProvider();

  const progressiveUpload = useCallback(
    async (params?: ProgressiveUploadParams) => {
      if (!userProviderData?.id || !file || !userData?.adminUid) {
        return;
      }
      const { onFinished, onError, onProgress } = params || {};

      const adminUid = userData?.adminUid;

      const storageRef = ref(
        storage,
        `${userProviderData.id}/${adminUid}/${baseUrl}/${file.name}`
      );

      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setProgress(progress);
          onProgress && onProgress(progress);
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          onError && onError(error);
          setError(error);
          console.error('Error uploading audio', error);
        },
        () => {
          setProgress(null);
        }
      );
      await uploadTask;

      const url = useEmulator
        ? `${CDN_URL}/${userProviderData.id}%2F${adminUid}%2F${baseUrl}%2F${file.name}?alt=media`
        : `${CDN_URL}/${userProviderData.id}/${adminUid}/${baseUrl}/${file.name}`;
      onFinished && onFinished(url);
      return url;
    },
    [userProviderData?.id, file, userData?.adminUid, storage, baseUrl]
  );

  return { progressiveUpload, progress, error };
}
