import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFirestore, useFunctions } from 'reactfire';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Snackbar,
} from '@mui/material';
import {
  doc,
  updateDoc,
  setDoc,
  DocumentReference,
  Timestamp,
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { PortalUser, ServiceProvider } from '../firebase/firebaseModels';
import usePortalUser from '../firebase/usePortalUser';
import { usePortalUsers } from '../firebase/usePortalUsers';
import CreatedModifiedTable from '../components/CreatedModifiedTable';
import { UI_LANGUAGE_OPTIONS } from '../services/i18n';
import Autocomplete from '@mui/material/Autocomplete';
import { useAuth } from 'reactfire';
import uuid from 'react-native-uuid';
import LinearProgress from '@mui/material/LinearProgress';
import { zxcvbn } from '@zxcvbn-ts/core';
import { useRemoteLog } from '../firebase/useRemoteLog';
import { Visibility, VisibilityOff } from '@mui/icons-material';

type UserLevel = 'user' | 'admin' | 'superadmin' | 'reseller';

export default function PortalUserEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{ portalUserId: string }>();
  const { portalUserId } = params;
  const functions = useFunctions();
  const location = useLocation();
  const auth = useAuth();

  const firestore = useFirestore();
  const { isSuperAdmin, isOrganizationAdmin, providerRef } = usePortalUser();
  const {
    data: portalUsers,
    status: portalUsersStatus,
    providers,
  } = usePortalUsers();

  const [email, setEmail] = useState('');
  const [level, setLevel] = useState<UserLevel>('user');
  const [provider, setProvider] = useState<string | null>(null);
  const [updating, setUpdating] = useState(false);
  const [updateSuccessful, setUpdateSuccessful] = useState(false);
  const [invitationSent, setInvitationSent] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [language, setLanguage] = useState('en');
  const [sendingInvitation, setSendingInvitation] = useState(false);
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [changingPassword, setChangingPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCopiedSnackbar, setShowCopiedSnackbar] = useState(false);

  const zxcvbnResult = zxcvbn(newPassword);
  const passwordStrength = (zxcvbnResult?.score || 0) * 25;

  // Find existing user data if editing
  const existingUser =
    portalUserId !== 'new'
      ? portalUsers?.find((user) => user.id === portalUserId)
      : null;

  const { sendAuditLog } = useRemoteLog();

  useEffect(() => {
    if (existingUser) {
      setEmail(existingUser.email || '');
      setLevel((existingUser.level as UserLevel) || 'user');
      setProvider(existingUser.provider?.id || null);
      setName(existingUser.name || '');
      setPhone(existingUser.phone || '');
      setLanguage(existingUser.language || '');
    } else if (!!location.state?.selectedProvider && isSuperAdmin) {
      // Set provider from navigation state if available
      setProvider(location.state.selectedProvider);
    } else if (providerRef?.id) {
      // Fallback to default provider
      setProvider(providerRef.id);
    }
  }, [
    existingUser,
    providerRef,
    location.state?.selectedProvider,
    isSuperAdmin,
  ]);

  const updatePortalUser = useCallback(async () => {
    if (!email || updating) {
      return;
    }

    setUpdating(true);
    setUpdateSuccessful(false);
    setInvitationSent(false);
    setError(null);

    try {
      const trimmedEmail = email.trim().toLowerCase();
      const docData: Partial<PortalUser> = {
        email: trimmedEmail,
        level,
        name,
        phone,
        language,
        provider: provider
          ? (doc(
              firestore,
              'ServiceProvider',
              provider
            ) as DocumentReference<ServiceProvider>)
          : undefined,
        modified: Timestamp.now(),
        modifiedBy: auth.currentUser?.email || '',
      };

      if (portalUserId === 'new') {
        // Add creation metadata for new users
        docData.created = Timestamp.now();
        docData.createdBy = auth.currentUser?.email || '';
        docData.adminUid = uuid.v4() as string;

        const docRef = doc(firestore, 'PortalUser', trimmedEmail);
        await setDoc(docRef, docData);

        // Log user creation
        sendAuditLog({
          action: 'create',
          resourceType: 'portalUser',
          resourceId: trimmedEmail,
          performedBy: auth.currentUser?.email || '',
          details: {
            level,
            provider,
            name,
            language,
          },
        });

        navigate(`/settings/portalusers/${trimmedEmail}`);
      } else {
        const docRef = doc(firestore, 'PortalUser', portalUserId!);
        await updateDoc(docRef, docData);

        // Log user update
        sendAuditLog({
          action: 'update',
          resourceType: 'portalUser',
          resourceId: portalUserId || '',
          performedBy: auth.currentUser?.email || '',
          details: {
            level,
            provider,
            name,
            language,
          },
        });
      }

      setUpdateSuccessful(true);
    } catch (err) {
      setError(err as Error);
      console.error('Error updating portal user:', err);
    } finally {
      setUpdating(false);
    }
  }, [
    email,
    updating,
    firestore,
    portalUserId,
    level,
    provider,
    name,
    phone,
    language,
    navigate,
    auth.currentUser?.email,
    sendAuditLog,
  ]);

  const handleSendInvitation = useCallback(async () => {
    if (!email || sendingInvitation) {
      return;
    }

    setSendingInvitation(true);
    setError(null);
    setUpdateSuccessful(false);
    setInvitationSent(false);

    try {
      const sendInvitation = httpsCallable(
        functions,
        'sendconsoleuserinvitation'
      );
      await sendInvitation({ portalUserId });

      // Log invitation sent
      sendAuditLog({
        action: 'invite',
        resourceType: 'portalUser',
        resourceId: portalUserId || '',
        performedBy: auth.currentUser?.email || '',
      });

      setInvitationSent(true);
    } catch (err) {
      setError(err as Error);
      console.error('Error sending invitation:', err);
    } finally {
      setSendingInvitation(false);
    }
  }, [
    email,
    sendingInvitation,
    functions,
    portalUserId,
    sendAuditLog,
    auth.currentUser?.email,
  ]);

  const handleChangePassword = useCallback(async () => {
    if (!newPassword || changingPassword) return;

    setChangingPassword(true);
    setError(null);

    try {
      const changePassword = httpsCallable(
        functions,
        'changeportaluserpassword'
      );
      await changePassword({ portalUserId, newPassword });

      // Log password change
      sendAuditLog({
        action: 'changePassword',
        resourceType: 'portalUser',
        resourceId: portalUserId || '',
        performedBy: auth.currentUser?.email || '',
      });

      setPasswordDialogOpen(false);
      setNewPassword('');
      setUpdateSuccessful(true);
    } catch (err) {
      setError(err as Error);
      console.error('Error changing password:', err);
    } finally {
      setChangingPassword(false);
    }
  }, [
    newPassword,
    changingPassword,
    functions,
    portalUserId,
    sendAuditLog,
    auth.currentUser?.email,
  ]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      updatePortalUser();
    },
    [updatePortalUser]
  );

  const languageValue = useMemo(
    () => UI_LANGUAGE_OPTIONS.find((l) => l.code === language),
    [language]
  );

  const handleRevertChanges = useCallback(() => {
    if (existingUser) {
      setEmail(existingUser.email || '');
      setLevel((existingUser.level as UserLevel) || 'user');
      setProvider(existingUser.provider?.id || null);
      setName(existingUser.name || '');
      setPhone(existingUser.phone || '');
      setLanguage(existingUser.language || '');
    }
  }, [existingUser]);

  const generateStrongPassword = useCallback(() => {
    const length = 16;
    const charset =
      'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNPQRSTUVWXYZ123456789!@#$%&*+-=.?';
    let password = '';
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }
    setNewPassword(password);
    setShowPassword(true);
    navigator.clipboard.writeText(password);
    setShowCopiedSnackbar(true);
  }, []);

  if (portalUsersStatus === 'loading') {
    return <CircularProgress />;
  }

  if (!isSuperAdmin && !isOrganizationAdmin) {
    return (
      <Alert severity="error">
        {t('You do not have permission to edit users')}
      </Alert>
    );
  }

  return (
    <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
      <Card sx={{ mt: 2 }} variant="outlined">
        <CardHeader
          title={t('User Details')}
          subheader={
            existingUser && (
              <Box sx={{ mt: 1 }}>
                {existingUser.lastLoginAt ? (
                  <Typography variant="body2" color="text.primary">
                    {t('Active User')} - {t('Last login')}:{' '}
                    {existingUser.lastLoginAt.toDate().toLocaleString()}
                  </Typography>
                ) : existingUser.consoleInvitationSent ? (
                  <Typography variant="body2" color="text.secondary">
                    {t('Invitation sent by {{name}} at {{time}}', {
                      name: existingUser.consoleInvitationSentBy,
                      time: existingUser.consoleInvitationSentAt
                        ?.toDate()
                        .toLocaleString(),
                    })}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    {t('Not Invited')}
                  </Typography>
                )}
              </Box>
            )
          }
        />
        <CardContent>
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Name')}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label={t('Email')}
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            error={!email}
            helperText={!email ? t('Email is required') : ''}
            disabled={existingUser !== null}
          />
          <TextField
            margin="normal"
            fullWidth
            label={t('Phone')}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <Autocomplete
            sx={{ mt: 2, width: '100%' }}
            options={UI_LANGUAGE_OPTIONS}
            blurOnSelect
            disableClearable
            getOptionLabel={(option) => t(option.name)}
            isOptionEqualToValue={(option, value) => option.code === value.code}
            value={languageValue}
            onChange={(_, newValue) => {
              if (newValue) {
                setLanguage(newValue.code);
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                name="ui-language"
                label={t('UI Language')}
                placeholder={t('Select UI Language')}
              />
            )}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel>{t('Role')}</InputLabel>
            <Select
              value={level}
              onChange={(e) => setLevel(e.target.value as UserLevel)}
              label={t('Role')}
            >
              <MenuItem value="user">{t('user')}</MenuItem>
              <MenuItem
                value="admin"
                disabled={!(isSuperAdmin || isOrganizationAdmin)}
              >
                {t('admin')}
              </MenuItem>
              {provider === '4XrQNcZJIUJHOExPvE8H' && (
                <MenuItem value="superadmin" disabled={!isSuperAdmin}>
                  {t('superadmin')}
                </MenuItem>
              )}
              {(isSuperAdmin || level === 'reseller') && (
                <MenuItem value="reseller" disabled={!isSuperAdmin}>
                  {t('reseller')}
                </MenuItem>
              )}
              {!['user', 'admin', 'superadmin', 'reseller'].includes(level) && (
                <MenuItem value={level}>{t(level)}</MenuItem>
              )}
            </Select>
          </FormControl>

          {isSuperAdmin && (
            <FormControl fullWidth margin="normal">
              <InputLabel>{t('Provider')}</InputLabel>
              <Select
                value={provider || ''}
                onChange={(e) => setProvider(e.target.value)}
                label={t('Provider')}
              >
                <MenuItem value="">{t('None')}</MenuItem>
                {providers?.map((provider: ServiceProvider) => (
                  <MenuItem
                    key={provider.id}
                    value={provider.id}
                    sx={
                      provider.trialProvider
                        ? {
                            fontStyle: 'italic',
                            color: 'text.secondary',
                          }
                        : undefined
                    }
                  >
                    {provider.name}
                    {provider.trialProvider && ` (${t('Trial')})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </CardContent>
      </Card>

      {error && (
        <Alert severity="error" sx={{ mt: 2 }}>
          {error.message}
        </Alert>
      )}

      {updateSuccessful && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {portalUserId === 'new'
            ? t('User created successfully')
            : t('User updated successfully')}
        </Alert>
      )}

      {invitationSent && (
        <Alert severity="success" sx={{ mt: 2 }}>
          {t('Invitation sent successfully')}
        </Alert>
      )}

      <Button
        type="submit"
        variant="contained"
        sx={{ mt: 2 }}
        disabled={updating || !email}
      >
        {updating ? <CircularProgress size={24} /> : t('Save')}
      </Button>

      {existingUser && (
        <>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleRevertChanges}
            sx={{ mt: 2, ml: 1 }}
          >
            {t('Revert Changes')}
          </Button>
          <Button
            variant="outlined"
            onClick={handleSendInvitation}
            disabled={sendingInvitation || !email}
            sx={{ mt: 2, ml: 2 }}
          >
            {sendingInvitation ? (
              <CircularProgress size={24} />
            ) : (
              t('Send Email Invitation')
            )}
          </Button>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setPasswordDialogOpen(true)}
            sx={{ mt: 2, ml: 2 }}
          >
            {t('Change Password')}
          </Button>
        </>
      )}

      {existingUser && (
        <CreatedModifiedTable isNew={false} data={existingUser} />
      )}

      <Dialog
        open={passwordDialogOpen}
        onClose={() => setPasswordDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('Change Password')}</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {t('The password will be changed immediately after confirmation.')}
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label={t('New Password')}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            helperText={t('Enter the new password for this user')}
            InputProps={{
              endAdornment: (
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <Button
            variant="outlined"
            size="small"
            onClick={generateStrongPassword}
            sx={{ mt: 1 }}
          >
            {t('Generate Strong Password')}
          </Button>
          {newPassword && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                {t('Password Strength')}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={passwordStrength}
                color={
                  passwordStrength < 50
                    ? 'error'
                    : passwordStrength < 75
                    ? 'warning'
                    : 'success'
                }
                sx={{ height: 8, borderRadius: 4 }}
              />
              {newPassword && (
                <Typography
                  variant="caption"
                  color="text.secondary"
                  sx={{ mt: 1, display: 'block' }}
                >
                  {zxcvbnResult.feedback.warning}
                </Typography>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 2 }}>
          <Button onClick={() => setPasswordDialogOpen(false)}>
            {t('Cancel')}
          </Button>
          <Button
            onClick={handleChangePassword}
            disabled={changingPassword || !newPassword}
            color="warning"
            variant="contained"
          >
            {changingPassword ? (
              <CircularProgress size={24} />
            ) : (
              t('Change Password')
            )}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showCopiedSnackbar}
        autoHideDuration={3000}
        onClose={() => setShowCopiedSnackbar(false)}
        message={t('Password copied to clipboard')}
      />
    </Box>
  );
}
