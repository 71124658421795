import {
  getVertexAI,
  getGenerativeModel,
  VertexAI,
  GenerativeModel,
  ModelParams,
} from 'firebase/vertexai-preview';
import {
  useState,
  useEffect,
  createContext,
  ReactNode,
  useContext,
} from 'react';
import { useFirebaseApp } from 'reactfire';
import { FirebaseError } from 'firebase/app';

const MODEL_PARAMS: ModelParams = { model: 'gemini-2.0-flash' };

let vertexAICache: VertexAI | null = null;

export interface VertexAIContextType {
  loading: boolean;
  error: FirebaseError | null;
  vertexAI: VertexAI | null;
  model: GenerativeModel | null;
}

export const VertexAIContext = createContext<VertexAIContextType | null>(null);

export function VertexAIProvider({ children }: { children: ReactNode }) {
  const app = useFirebaseApp();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FirebaseError | null>(null);
  const [vertexAI, setVertexAI] = useState<VertexAI | null>(null);
  const [model, setModel] = useState<GenerativeModel | null>(null);

  useEffect(() => {
    setLoading(true);
    const getVertexAIAndModel = async () => {
      try {
        const vertexAI =
          vertexAICache !== null ? vertexAICache : getVertexAI(app);
        setVertexAI(vertexAI);
        vertexAICache = vertexAI;
        const model = getGenerativeModel(vertexAI, MODEL_PARAMS);
        setModel(model);
        setError(null);
      } catch (e) {
        console.error(e);
        setError(e as FirebaseError);
        setVertexAI(null);
        setModel(null);
      } finally {
        setLoading(false);
      }
    };
    getVertexAIAndModel();
  }, [app]);

  const value = { loading, error, vertexAI, model };

  return (
    <VertexAIContext.Provider value={value}>
      {children}
    </VertexAIContext.Provider>
  );
}

export function useVertexAI() {
  const context = useContext(VertexAIContext);
  if (!context) {
    throw new Error('useVertexAI must be used within a VertexAIProvider');
  }
  return context;
}
