import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import { useNavigate, matchPath } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { THERAPYPLANS_TABS } from '../hooks/useDashboardRoutes';
import TherapyPlans from './TherapyPlans';
import { TabContext, TabPanel } from '@mui/lab';
import { CircularProgress, Paper } from '@mui/material';
import LinkTab from '../components/LinkTab';
import { samePageLinkNavigation } from '../utils';

export default function TherapyPlansTabs() {
  const { t } = useTranslation();
  const { pathname } = window.location;

  const selectedTab = useMemo(
    () => THERAPYPLANS_TABS.findIndex((tab) => matchPath(tab.path, pathname)),
    [pathname]
  );

  // if selectedTab is null, redirect to the first tab
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedTab === -1) {
      navigate(THERAPYPLANS_TABS[0].path);
    }
  }, [selectedTab, navigate]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== 'click' ||
      (event.type === 'click' &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
        ))
    ) {
    }
  };

  if (selectedTab === -1) {
    return <CircularProgress />;
  }
  return (
    <TabContext value={`${selectedTab}`}>
      <Paper sx={{ width: '100%', mt: 2 }} variant="outlined">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          aria-label="task plan tabs"
          role="navigation"
        >
          {THERAPYPLANS_TABS.map((tab, idx) => (
            <LinkTab
              key={`tab-${idx}`}
              label={t(tab.label)}
              href={tab.path}
              selected={selectedTab === idx}
            />
          ))}
        </Tabs>
      </Paper>
      <Paper variant="outlined">
        <TabPanel value={`${selectedTab}`}>
          <TherapyPlans therapyplantype={THERAPYPLANS_TABS[selectedTab].type} />
        </TabPanel>
      </Paper>
    </TabContext>
  );
}
