import { useContext } from 'react';
import { TherapyCourseInvitationContext, TInvitationData } from '../contexts/TherapyCourseInvitationContext';

export const useTherapyCourseInvitations = (): TInvitationData => {
  const context = useContext(TherapyCourseInvitationContext);

  if (!context) {
    throw new Error('useTherapyCourseInvitations must be used within a TherapyCourseInvitationProvider');
  }

  return context;
}; 