/**
 * @module AppRouter
 * @category Routing
 * @packageDocumentation
 * @see {@link https://reactrouter.com/ | React Router}
 */
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Login from '../pages/Login';
import Logout from '../pages/Logout';
import Dashboard from '../pages/Dashboard';
import RequireAuth from './RequireAuth';
import { AppRoute, appRoutes } from '../hooks/useDashboardRoutes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';
import 'dayjs/locale/de';
import 'dayjs/locale/fr';
import 'dayjs/locale/es';
import 'dayjs/locale/en-gb';
import React, { useMemo } from 'react';
import ForgotPassword from '../pages/ForgotPassword';
import { SharedTherapyCourseProvider } from '../contexts/SharedTherapyCourseContext';
import { SharedPatientProvider } from '../contexts/SharedPatientContext';
import { fiFI } from '@mui/x-date-pickers/locales';

const renderRoutes = (routes: AppRoute[], path?: string) => {
  const parentPath = path || '';
  return routes
    .filter((route: AppRoute) => route.type !== 'divider')
    .map((route: AppRoute): React.ReactElement | null => {
      const { children, crumb, tabs } = route;
      const hasChildren = children && children.length > 0;
      const routePath = parentPath + route.path;
      return hasChildren ? (
        <Route
          key={routePath}
          path={routePath}
          loader={(params) => params}
          handle={{
            crumb,
            tabs,
          }}
        >
          {renderRoutes(children, routePath)}
          {route.Component ? (
            <Route
              key={`${routePath}-el`}
              path={routePath}
              element={<route.Component />}
            />
          ) : null}
        </Route>
      ) : route.Component ? (
        <Route
          key={routePath}
          path={routePath}
          element={<route.Component />}
          loader={(params) => params}
          handle={{
            crumb,
            tabs,
          }}
        />
      ) : null;
    });
};

const routes = renderRoutes(appRoutes);
const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/login" element={<Login />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <SharedTherapyCourseProvider>
              <SharedPatientProvider>
                <Dashboard />
              </SharedPatientProvider>
            </SharedTherapyCourseProvider>
          </RequireAuth>
        }
      >
        {routes}
        <Route index element={<Navigate to="/overview" replace />} />
      </Route>
    </>
  )
);

/**
 * AppRouter component. Contains the localization provider and the router provider.
 * @category Routing
 * @component
 * @returns JSX.Element
 */
function AppRouter() {
  const { i18n } = useTranslation();

  const languageCode = useMemo(
    () => i18n.language.split('-')[0],
    [i18n.language]
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={languageCode}
      localeText={
        fiFI.components.MuiLocalizationProvider.defaultProps.localeText
      }
    >
      <RouterProvider router={router} />
    </LocalizationProvider>
  );
}

export default AppRouter;
