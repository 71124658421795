import {
  ChangeEvent,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import uuid from 'react-native-uuid';
import {
  Paper,
  Button,
  Card,
  CardContent,
  TextField,
  Alert,
  Box,
  Grid,
  Autocomplete,
  AlertTitle,
  CardHeader,
  CircularProgress,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Snackbar,
  useTheme,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {
  doc,
  collection,
  updateDoc,
  addDoc,
  Timestamp,
  DocumentReference,
} from 'firebase/firestore';
import {
  TaskPoolTask,
  TaskPoolTaskOptions,
  TaskPoolTaskType,
  TranslatableField,
  TaskPoolTaskSectionType,
  TaskPoolTaskSection,
  TaskPoolTaskCategory,
} from '../firebase/firebaseModels';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import useTaskPoolTaskDoc from '../firebase/useTaskPoolTaskDoc';
import LanguageSelector from '../components/LanguageSelector';
import { useFirestore } from 'reactfire';
import { DEFAULT_IMAGE_URL } from '../consts';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TaskPoolTaskAudioSectionEditor from '../components/TaskPoolTaskAudioSectionEditor';
import TaskPoolTaskVideoSectionEditor from '../components/TaskPoolTaskVideoSectionEditor';
import TaskPoolTaskImageSectionEditor from '../components/TaskPoolTaskImageSectionEditor';
import TaskPoolTaskPDFSectionEditor from '../components/TaskPoolTaskPDFSectionEditor';
import TaskPoolTaskURLSectionEditor from '../components/TaskPoolTaskURLSectionEditor';
import TaskPoolTaskRadioSectionEditor from '../components/TaskPoolTaskRadioSectionEditor';
import TaskPoolTaskRangeSectionEditor from '../components/TaskPoolTaskRangeSectionEditor';
import TaskPoolTaskCheckboxSectionEditor from '../components/TaskPoolTaskCheckboxSectionEditor';
import TaskPoolTaskInfoPopupSectionEditor from '../components/TaskPoolTaskInfoPopupSectionEditor';
import { getCurrentLanguageCode } from '../services/i18n';
import usePortalUser from '../firebase/usePortalUser';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { useStrictDroppable } from '../hooks/useStrictDroppable';
import { useFirebaseUser } from '../firebase/useFirebaseUser';
import CreatedModifiedTable from '../components/CreatedModifiedTable';
import useUserProvider from '../firebase/useUserProvider';
import useTaskPoolTaskCategoryCollection from '../firebase/useTaskPoolTaskCategoryCollection';
import { useUpload } from '../hooks/useUpload';
import TaskPoolTaskQuestionSectionEditor from '../components/TaskPoolTaskQuestionSectionEditor';
import TranslatableTextField from '../components/TranslatableTextField';
import TranslatableEditor from '../components/TranslatableEditor';
import ImageSelector from '../components/ImageSelector';
import TaskPoolTaskTextSectionEditor from '../components/TaskPoolTaskTextSectionEditor';
import TaskCategoryDot from '../components/TaskCategoryDot';
import useAppropriateLanguage from '../hooks/useAppropriateLanguage';

type TaskTypeOption = {
  value: TaskPoolTaskType;
  label: string;
};

export type SaveFuncParams = {
  success: boolean;
  message: string;
  newSection?: TaskPoolTaskSection;
  error?: Error;
};

export type SectionEditorRef = {
  sectionType: TaskPoolTaskSectionType;
  save: () => Promise<SaveFuncParams>;
};

type SaveError = {
  message: string;
  error?: Error;
};

/**
 * Editing the TaskPoolTask.
 */
export default function TaskPoolTaskEditor() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { taskPoolTaskId, taskpooltype, idToClone } = useParams<{
    taskPoolTaskId: string;
    taskpooltype: string;
    idToClone?: string;
  }>();
  const firestore = useFirestore();

  const { email } = useFirebaseUser();
  const { status: userProviderStatus, providerRef } = useUserProvider();

  const {
    data: portalUserData,
    isSuperAdmin,
    isOrganizationAdmin,
  } = usePortalUser();

  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, []);

  const initialLang = getCurrentLanguageCode();
  const [defaultLanguage, setDefaultLanguage] = useState(initialLang);
  const [title, setTitle] = useState<TranslatableField>({
    [initialLang]: '',
  });

  const [language, setLanguage] = useState(initialLang);

  const [titleDone, setTitleDone] = useState<TranslatableField>({
    [initialLang]: '',
  });
  const [description, setDescription] = useState<TranslatableField>({
    [initialLang]: '',
  });
  const [amOwner, setAmOwner] = useState<boolean>(false);
  const [image, setImage] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string>(DEFAULT_IMAGE_URL);
  const [order, setOrder] = useState<number>(0);
  const [options, setOptions] = useState<TaskPoolTaskOptions>({});
  const [allowNotification, setAllowNotification] = useState<boolean>(false);
  const [sections, setSections] = useState<TaskPoolTaskSection[]>([]);
  const [updateSuccessful, setUpdateSuccessful] = useState<boolean>(false);
  const [saveErrors, setSaveErrors] = useState<SaveError[]>([]);
  const [category, setCategory] =
    useState<DocumentReference<TaskPoolTaskCategory> | null>(null);
  const sectionEditorsRef = useRef<SectionEditorRef[]>([]);
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [dialogDescription, setDialogDescription] = useState<string>('');
  const [dialogType, setDialogType] = useState<
    'save' | 'promote' | 'demote' | null
  >(null);
  const [updateError, setUpdateError] = useState<Error | null>(null);
  const [addError, setAddError] = useState<Error | null>(null);

  const location = useLocation();

  const defaultTaskType: TaskPoolTaskType = useMemo(() => {
    const pathSegments = location.pathname.split('/');
    const taskTypeIndex = pathSegments.indexOf('taskpool') + 1;
    if (taskTypeIndex > 0 && taskTypeIndex < pathSegments.length) {
      const taskType = pathSegments[taskTypeIndex];
      if (taskType === 'generic' || taskType === 'questionnaire') {
        return taskType;
      }
    }
    return 'generic';
  }, [location.pathname]);

  const [type, setType] = useState<TaskPoolTaskType>(
    defaultTaskType as TaskPoolTaskType
  );

  const [includeInStatistics, setIncludeInStatistics] =
    useState<boolean>(false);

  const [showTypeChangeWarning, setShowTypeChangeWarning] = useState(false);
  const [pendingTypeChange, setPendingTypeChange] =
    useState<TaskTypeOption | null>(null);

  const [hideScoresFromPatient, setHideScoresFromPatient] =
    useState<boolean>(false);

  const therapyLanguages = useMemo(() => {
    return portalUserData?.therapyLanguages || [];
  }, [portalUserData]);

  const {
    status: categoryStatus,
    data: categoryData,
    error: categoryError,
  } = useTaskPoolTaskCategoryCollection(
    isPublic ? 'organization_and_default' : null,
    therapyLanguages
  );

  const [imageUploadError, setImageUploadError] = useState<Error | null>(null);

  const { uploadFile: uploadImage } = useUpload(
    image,
    'taskpoolimages',
    (error) => {
      if (error) {
        setImageUploadError(error);
        setUpdating(false);
      }
    }
  );

  const handleImageChange = useCallback(
    (newImage: File | null, newImageUrl: string) => {
      setImage(newImage);
      setImageUrl(newImageUrl);
    },
    []
  );

  const handlePublicChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => setIsPublic(event.target.checked),
    []
  );

  const { status, error, data } = useTaskPoolTaskDoc(
    taskPoolTaskId as string,
    defaultTaskType,
    idToClone
  );
  const [updating, setUpdating] = useState(false);

  const [enabled] = useStrictDroppable(status !== 'success');
  const isGloballyAvailable = isPublic && data?.provider === null;

  const canEdit =
    isSuperAdmin ||
    amOwner ||
    (!amOwner && data?.provider?.id === providerRef?.id && isOrganizationAdmin);

  const taskLanguages = useMemo(() => {
    if (!data) return [];

    // Use task's explicit languages if available
    if (data.languages && data.languages.length > 0) {
      return data.languages;
    }

    // Otherwise use languages from title
    return Object.keys(data.title || {}).filter((lang) => !!data.title[lang]);
  }, [data]);

  // Use our hook to determine the appropriate language
  const appropriateLanguage = useAppropriateLanguage(
    defaultLanguage,
    therapyLanguages,
    taskLanguages
  );

  useEffect(() => {
    if (status === 'success' && data) {
      const taskDefaultLang = data.defaultLanguage;
      // Initialize defaultLanguage with the appropriate language
      const bestLanguage = appropriateLanguage;

      setTitle(data.title || { [bestLanguage]: '' });
      setTitleDone(data.titleDone || { [bestLanguage]: '' });
      setDescription(data.description || { [bestLanguage]: '' });
      setImageUrl(data.image || DEFAULT_IMAGE_URL);
      setOrder(data.order);
      setOptions(data.options || {});
      setAllowNotification(data.allowNotification || false);
      setSections(
        data.sections && (data.sections as any)?.map ? data.sections : []
      );
      setType(data.type || defaultTaskType);
      setLanguage(bestLanguage);
      setCategory(data.category || null);
      setAmOwner(data.amOwner || false);
      setDefaultLanguage(taskDefaultLang || bestLanguage);
      setIncludeInStatistics(data.includeInStatistics || false);
      setHideScoresFromPatient(data.hideScoresFromPatient || false);
      if (taskPoolTaskId === 'new') {
        setIsPublic(taskpooltype !== 'own');
      } else {
        setIsPublic(data.public || false);
      }
    }
  }, [
    data,
    taskPoolTaskId,
    status,
    taskpooltype,
    defaultTaskType,
    appropriateLanguage,
  ]);

  const uploadSectionData = useCallback(
    async (
      onFinish: (
        newSections: TaskPoolTaskSection[],
        url: string | null
      ) => void,
      newImageUrl: string | null
    ) => {
      const newSections = [...sections];
      for (let idx = 0; idx < sections.length; idx++) {
        const sectionEditor = sectionEditorsRef.current[idx];
        if (sectionEditor) {
          const { success, message, newSection, error } =
            await sectionEditor.save();
          if (success) {
            newSections[idx] = newSection as TaskPoolTaskSection;
          }
          if (!success) {
            const newSaveError: SaveError = {
              message,
              error,
            };
            setSaveErrors((errors) => [...errors, newSaveError]);
          }
        }
      }
      setSections(newSections);
      onFinish(newSections, newImageUrl);
    },
    [sections]
  );

  let languages = useMemo(() => {
    const langs = Object.keys(title).filter((lang) => !!title[lang]);

    Object.keys(description).forEach((lang) => {
      if (!!description[lang] && !langs.includes(lang)) {
        langs.push(lang);
      }
    });
    return langs;
  }, [description, title]);

  const updateTaskDoc = useCallback(
    (newSections: TaskPoolTaskSection[], newImageUrl: string | null) => {
      if (!taskPoolTaskId || !email) {
        return;
      }
      const updateData: Partial<TaskPoolTask> = {
        title,
        titleDone,
        description,
        order,
        options: options || {},
        allowNotification,
        sections: newSections || [],
        image: newImageUrl || imageUrl,
        type,
        languages,
        category,
        defaultLanguage,
        public: isPublic,
        modified: Timestamp.now(),
        modifiedBy: email,
        includeInStatistics,
        hideScoresFromPatient,
      };

      updateDoc(doc(firestore, 'TaskPool', taskPoolTaskId), updateData)
        .then(() => {
          setUpdating(false);
          setUpdateSuccessful(true);
        })
        .catch((error) => {
          setUpdateError(error);
          setUpdating(false);
        });
    },
    [
      taskPoolTaskId,
      email,
      title,
      titleDone,
      description,
      order,
      options,
      allowNotification,
      imageUrl,
      type,
      languages,
      category,
      defaultLanguage,
      isPublic,
      firestore,
      includeInStatistics,
      hideScoresFromPatient,
    ]
  );

  const saveTaskDoc = useCallback(
    (newSections: TaskPoolTaskSection[], newImageUrl: string | null) => {
      if (!email || !providerRef) {
        return;
      }
      const newData: TaskPoolTask = {
        title,
        titleDone,
        description,
        image: newImageUrl || imageUrl,
        defaultLanguage,
        languages,
        order,
        options: options || {},
        allowNotification,
        sections: newSections || [],
        type,
        createdBy: email,
        created: Timestamp.now(),
        provider: providerRef,
        category,
        public: isPublic,
        includeInStatistics,
        hideScoresFromPatient,
      };

      addDoc(collection(firestore, 'TaskPool'), newData)
        .then((docRef) => {
          uploadImage((url: string | null) => {
            if (url) {
              updateDoc(doc(firestore, 'TaskPool', docRef.id), {
                image: url,
              }).then(() => {
                navigate(`/admin/taskpool/${type}/own/${docRef.id}`);
                setUpdateSuccessful(true);
                setUpdateError(null);
                setUpdating(false);
              });
            } else {
              navigate(`/admin/taskpool/${type}/own/${docRef.id}`);
              setUpdateSuccessful(true);
              setUpdateError(null);
              setUpdating(false);
            }
          });
        })
        .catch((error) => {
          console.error('Error adding document: ', error);
          setAddError(error);
          setUpdating(false);
        });
    },
    [
      allowNotification,
      category,
      defaultLanguage,
      description,
      email,
      firestore,
      imageUrl,
      isPublic,
      languages,
      navigate,
      options,
      order,
      providerRef,
      title,
      titleDone,
      type,
      uploadImage,
      includeInStatistics,
      hideScoresFromPatient,
    ]
  );

  const updateTask = useCallback(() => {
    if (!title || !description) {
      return;
    }
    setUpdating(true);

    if (taskPoolTaskId && taskPoolTaskId !== 'new') {
      setUpdating(true);
      setUpdateSuccessful(false);
      setSaveErrors([]);
      uploadImage((url) => {
        setImageUrl(url || '');
        uploadSectionData(updateTaskDoc, url);
      });
    } else {
      uploadSectionData(saveTaskDoc, null);
    }
  }, [
    title,
    description,
    taskPoolTaskId,
    uploadImage,
    uploadSectionData,
    updateTaskDoc,
    saveTaskDoc,
  ]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!title || !description) {
        return;
      }
      if (!amOwner) {
        setDialogDescription(
          t(
            'You are not the owner of this task. Are you sure you want to save changes?'
          )
        );
        setDialogOpen(true);
        setDialogType('save');
        return;
      }
      updateTask();
    },
    [title, description, amOwner, updateTask, t]
  );

  const confirmPromoteToGlobal = useCallback(() => {
    if (!taskPoolTaskId) {
      return;
    }
    const taskRef = doc(firestore, 'TaskPool', taskPoolTaskId);
    updateDoc(taskRef, {
      public: true,
      provider: null,
    });
  }, [firestore, taskPoolTaskId]);

  const confirmDemoteToPrivate = useCallback(() => {
    if (!taskPoolTaskId || !providerRef) {
      return;
    }
    const taskRef = doc(firestore, 'TaskPool', taskPoolTaskId);
    updateDoc(taskRef, {
      public: false,
      provider: providerRef,
    });
  }, [firestore, providerRef, taskPoolTaskId]);

  const promoteToGlobal = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(t('Are you sure you want to make this task global?'));
    setDialogOpen(true);
    setDialogType('promote');
  }, [isSuperAdmin, t]);

  const demoteToPrivate = useCallback(() => {
    if (!isSuperAdmin) {
      return;
    }
    setDialogDescription(t('Are you sure you want to make this task private?'));
    setDialogOpen(true);
    setDialogType('demote');
  }, [isSuperAdmin, t]);

  const handleConfirm = useCallback(() => {
    setDialogOpen(false);
    switch (dialogType) {
      case 'save':
        updateTask();
        break;
      case 'promote':
        confirmPromoteToGlobal();
        break;
      case 'demote':
        confirmDemoteToPrivate();
        break;
    }
  }, [confirmDemoteToPrivate, confirmPromoteToGlobal, dialogType, updateTask]);

  const taskTypeOptions: TaskTypeOption[] = useMemo(() => {
    const baseOptions = [
      { value: 'generic' as TaskPoolTaskType, label: t('Home Task') },
      { value: 'questionnaire' as TaskPoolTaskType, label: t('Questionnaire') },
    ];

    if (isSuperAdmin) {
      return [
        ...baseOptions,
        { value: 'homework' as TaskPoolTaskType, label: t('Homework') },
        { value: 'morning' as TaskPoolTaskType, label: t('Morning') },
        { value: 'evening' as TaskPoolTaskType, label: t('Evening') },
        {
          value: 'sleepRestriction' as TaskPoolTaskType,
          label: t('Sleep Restriction'),
        },
      ];
    }

    return baseOptions;
  }, [t, isSuperAdmin]);

  const taskSectionTypeOptions = useMemo(() => {
    switch (type) {
      case 'questionnaire':
        return [
          {
            value: 'radio' as TaskPoolTaskSectionType,
            label: t('Multiple Question'),
          },
          {
            value: 'range' as TaskPoolTaskSectionType,
            label: t('Range Question'),
          },
          {
            value: 'checkbox' as TaskPoolTaskSectionType,
            label: t('Yes/No Question'),
          },
          {
            value: 'question' as TaskPoolTaskSectionType,
            label: t('Free Text Question'),
          },
        ];
      case 'generic':
        return [
          { value: 'pdf' as TaskPoolTaskSectionType, label: t('PDF') },
          { value: 'url' as TaskPoolTaskSectionType, label: t('Web Link') },
          {
            value: 'infoPopup' as TaskPoolTaskSectionType,
            label: t('Info Popup'),
          },
          {
            value: 'radio' as TaskPoolTaskSectionType,
            label: t('Multiple Question'),
          },
          {
            value: 'range' as TaskPoolTaskSectionType,
            label: t('Range Question'),
          },
          {
            value: 'checkbox' as TaskPoolTaskSectionType,
            label: t('Yes/No Question'),
          },
          {
            value: 'question' as TaskPoolTaskSectionType,
            label: t('Free Text Question'),
          },
          { value: 'image' as TaskPoolTaskSectionType, label: t('Image') },
          { value: 'audio' as TaskPoolTaskSectionType, label: t('Audio') },
          { value: 'video' as TaskPoolTaskSectionType, label: t('Video') },
          {
            value: 'text' as TaskPoolTaskSectionType,
            label: t('Text Only'),
          },
        ];
      default:
        return [];
    }
  }, [t, type]);

  const taskCategoryOptions = useMemo(() => {
    return (categoryData
      ?.filter((category) => category.id)
      .map((category) => ({
        id: category.id,
        title:
          category.title[language] ||
          `${category.title[Object.keys(category.title)[0]]} [${
            Object.keys(category.title)[0]
          }]`,
        color: category.color,
      })) || []) as { id: string; title: string; color: string }[];
  }, [categoryData, language]);

  const categoryValue = useMemo(
    () =>
      taskCategoryOptions?.find((option) => option.id === category?.id) || null,
    [category, taskCategoryOptions]
  );

  const handleCategoryChange = useCallback(
    (_: any, newValue: any) => {
      const categoryRef = newValue?.id
        ? doc(firestore, 'TaskPoolTaskCategories', newValue?.id)
        : null;
      setCategory(
        categoryRef as DocumentReference<TaskPoolTaskCategory> | null
      );
    },
    [firestore]
  );

  const onDragEnd = useCallback(
    (result: any) => {
      if (!result.destination) {
        return;
      }
      const items = [...sections];
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setSections(items);
    },
    [sections]
  );

  const grid = 1;

  const theme = useTheme();

  const getItemStyle = useCallback(
    (isDragging: boolean, draggableStyle: any): React.CSSProperties => ({
      userSelect: 'none',
      padding: grid * 2,
      margin: `0 0 ${grid}px 0`,
      background: isDragging ? theme.palette.action.hover : 'transparent',
      cursor: 'grab',
      ...draggableStyle,
    }),
    [theme.palette.action.hover]
  );

  const getListStyle = useCallback(
    (isDraggingOver: boolean): React.CSSProperties => ({
      background: isDraggingOver
        ? theme.palette.action.selected
        : 'transparent',
      padding: grid,
      width: '100%',
    }),
    [theme.palette.action.selected]
  );

  const handleFrequencyChange = useCallback(
    (event: SelectChangeEvent<TaskPoolTaskOptions['frequency']>) => {
      setOptions((options) => ({
        ...options,
        frequency: event.target.value as TaskPoolTaskOptions['frequency'],
      }));
    },
    []
  );

  const handleOrderChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      if (value === '' || !isNaN(Number(value))) {
        setOrder(Number(value));
      }
    },
    []
  );

  const handleAllowNotificationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) =>
      setAllowNotification(event.target.checked),
    []
  );

  const handleDeleteSection = useCallback((section: TaskPoolTaskSection) => {
    setSections((sections) => {
      return sections?.filter((s) => s.id !== section.id) || [];
    });
  }, []);

  const sectionsList = useMemo(
    () => (
      <DragDropContext onDragEnd={onDragEnd}>
        {enabled && (
          <Droppable droppableId="sections" key="sections" direction="vertical">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {sections?.map((section, idx) => {
                  return (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          {section.type === 'audio' && (
                            <TaskPoolTaskAudioSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'video' && (
                            <TaskPoolTaskVideoSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'image' && (
                            <TaskPoolTaskImageSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'pdf' && (
                            <TaskPoolTaskPDFSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'url' && (
                            <TaskPoolTaskURLSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'radio' && (
                            <TaskPoolTaskRadioSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'range' && (
                            <TaskPoolTaskRangeSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'checkbox' && (
                            <TaskPoolTaskCheckboxSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'infoPopup' && (
                            <TaskPoolTaskInfoPopupSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'question' && (
                            <TaskPoolTaskQuestionSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                          {section.type === 'text' && (
                            <TaskPoolTaskTextSectionEditor
                              ref={(elRef: SectionEditorRef) => {
                                sectionEditorsRef.current[idx] = elRef;
                              }}
                              disabled={!canEdit}
                              section={section}
                              language={language}
                              defaultLanguage={defaultLanguage}
                              onDelete={() => handleDeleteSection(section)}
                            />
                          )}
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    ),
    [
      onDragEnd,
      enabled,
      getListStyle,
      sections,
      getItemStyle,
      canEdit,
      language,
      defaultLanguage,
      handleDeleteSection,
    ]
  );
  const titleExistsInPrimaryLanguage = !!title[defaultLanguage];

  // const hasOnlyQuestionnaireCompatibleSections = useMemo(() => {
  //   const questionnaireTypes = ['radio', 'range', 'checkbox', 'question'];
  //   return sections.every((section) =>
  //     questionnaireTypes.includes(section.type)
  //   );
  // }, [sections]);

  // const handleTypeChange = useCallback(
  //   (
  //     _event: React.SyntheticEvent<Element, Event>,
  //     newValue: TaskTypeOption | null
  //   ) => {
  //     if (!taskPoolTaskId || taskPoolTaskId === 'new') {
  //       // For new tasks, change type directly
  //       setType((newValue?.value || 'generic') as TaskPoolTaskType);
  //       return;
  //     }

  //     // For existing tasks, show warning
  //     setPendingTypeChange(newValue);
  //     setShowTypeChangeWarning(true);
  //   },
  //   [taskPoolTaskId]
  // );

  const handleConfirmTypeChange = useCallback(() => {
    if (pendingTypeChange) {
      setType((pendingTypeChange.value || 'generic') as TaskPoolTaskType);
    }
    setShowTypeChangeWarning(false);
    setPendingTypeChange(null);
  }, [pendingTypeChange]);

  const handleCancelTypeChange = useCallback(() => {
    setShowTypeChangeWarning(false);
    setPendingTypeChange(null);
  }, []);

  const taskTypeLabel = useMemo(() => {
    const option = taskTypeOptions.find((opt) => opt.value === type);
    return option?.label || '';
  }, [taskTypeOptions, type]);

  if (!taskPoolTaskId) {
    return <Paper>{t('Task ID not provided')}</Paper>;
  }

  if (status === 'loading') {
    return <CircularProgress />;
  }

  return (
    <>
      {error && <Alert severity="error">{error.message}</Alert>}
      {categoryError && <Alert severity="error">{categoryError.message}</Alert>}
      {userProviderStatus === 'loading' && <CircularProgress />}
      <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>
        <Card sx={{ mt: 2 }} variant="outlined" key="language">
          <CardHeader
            title={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                {t('Basics')}
                {taskTypeLabel && (
                  <Typography variant="subtitle1" color="text.secondary">
                    ({taskTypeLabel})
                  </Typography>
                )}
              </Box>
            }
            subheader={t('Select the language to edit')}
            action={
              <LanguageSelector
                disabled={!canEdit}
                language={language}
                defaultLanguage={defaultLanguage}
                setLanguage={setLanguage}
                setDefaultLanguage={setDefaultLanguage}
                showPrimaryLanguage={false}
              />
            }
          />
          <CardContent>
            <TranslatableTextField
              key={`task-title-${language}`}
              name={`task-title-${language}`}
              disabled={!canEdit}
              label={t('Task Name')}
              helperText={
                !titleExistsInPrimaryLanguage
                  ? t('Name is required in primary language')
                  : undefined
              }
              error={!titleExistsInPrimaryLanguage}
              language={language}
              type="title"
              setValue={setTitle}
              value={title}
              placeholder={t('Enter Name')}
              defaultLanguage={defaultLanguage}
            />
            <TranslatableTextField
              key={`task-titleDone-${language}`}
              name={`task-titleDone-${language}`}
              disabled={!canEdit}
              label={t('Task Name When User Has Marked is Done')}
              placeholder={t(
                'Enter the name the app user sees when task is done (optional)'
              )}
              language={language}
              type="title"
              setValue={setTitleDone}
              value={titleDone}
              sx={{ mt: 2 }}
              defaultLanguage={defaultLanguage}
            />

            <TranslatableEditor
              key={`task-description-${language}`}
              name={`task-description-${language}`}
              disabled={!canEdit}
              placeholder={t('Description')}
              value={description}
              setValue={setDescription}
              language={language}
              type="description"
              defaultLanguage={defaultLanguage}
            />
            <Autocomplete
              disabled={!canEdit}
              loading={categoryStatus === 'loading'}
              blurOnSelect
              options={taskCategoryOptions}
              value={categoryValue}
              getOptionLabel={(option) => option.title}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={handleCategoryChange}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  <TaskCategoryDot
                    color={option.color || null}
                    categoryName={option.title}
                    size={12}
                    sx={{ mr: 2 }}
                  />
                  {option.title}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="task-category"
                  label={t('Task Category')}
                  placeholder={t('Select task category...')}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: categoryValue ? (
                      <InputAdornment position="start">
                        <TaskCategoryDot
                          color={categoryValue.color || null}
                          categoryName={categoryValue.title}
                          size={12}
                          sx={{ ml: 1, mr: 0 }}
                        />
                      </InputAdornment>
                    ) : null,
                  }}
                />
              )}
              sx={{ mt: 2 }}
            />
          </CardContent>
        </Card>

        {taskSectionTypeOptions.length > 0 && (
          <Card sx={{ mt: 2 }} variant="outlined">
            <CardHeader
              title={t('Sections')}
              subheader={t('Select the language to edit')}
              action={
                <LanguageSelector
                  language={language}
                  defaultLanguage={defaultLanguage}
                  setLanguage={setLanguage}
                  setDefaultLanguage={setDefaultLanguage}
                />
              }
            />
            <CardContent>
              {sectionsList}
              {/** add section; display dropdown for type and button for adding */}
              <Grid container spacing={2}>
                <Grid item>
                  <Autocomplete
                    disabled={!canEdit}
                    blurOnSelect
                    value={null}
                    options={taskSectionTypeOptions}
                    getOptionLabel={(option) => option.label}
                    isOptionEqualToValue={(option, value) =>
                      option.value === value.value
                    }
                    onChange={(e, newValue) => {
                      if (newValue) {
                        const newId = uuid.v4() as string;
                        const newSection: TaskPoolTaskSection = {
                          id: newId,
                          title: { [defaultLanguage]: '' },
                          description: { [defaultLanguage]: '' },
                          type: newValue.value,
                        };
                        setSections((sections) => {
                          return [...(sections || []), newSection];
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="task-section-add"
                        label={t('Add Section')}
                        placeholder=""
                      />
                    )}
                    sx={{ mt: 2, minWidth: 200 }}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        )}
        <ImageSelector
          disabled={!canEdit}
          image={image}
          imageUrl={imageUrl}
          onImageChange={handleImageChange}
        />

        <Card sx={{ mt: 2 }} variant="outlined">
          <CardHeader title={t('App Options')} />
          <CardContent>
            <FormControl fullWidth>
              <InputLabel id="frequency">
                <Trans>Task Frequency</Trans>
              </InputLabel>
              <Select
                disabled={!canEdit}
                labelId="frequency"
                id="frequency"
                value={options.frequency || 'once'}
                label={t('Task Frequency')}
                onChange={handleFrequencyChange}
              >
                <MenuItem value="daily" key="daily">
                  <Trans>Daily</Trans>
                </MenuItem>
                <MenuItem value="once" key="once">
                  <Trans>Once</Trans>
                </MenuItem>
              </Select>
            </FormControl>
            <FormGroup sx={{ mt: 2 }} row>
              <FormControlLabel
                disabled={
                  !canEdit || !['generic', 'questionnaire'].includes(type)
                }
                control={
                  <Checkbox
                    checked={includeInStatistics}
                    onChange={(e) => setIncludeInStatistics(e.target.checked)}
                  />
                }
                label={t('Take into account when calculating statistics')}
              />
              <FormControlLabel
                disabled={!canEdit}
                control={
                  <Checkbox
                    checked={allowNotification}
                    onChange={handleAllowNotificationChange}
                  />
                }
                label={t('Allow User to Set A Reminder Notification')}
              />
              {type === 'questionnaire' && (
                <FormControlLabel
                  disabled={!canEdit}
                  control={
                    <Checkbox
                      checked={hideScoresFromPatient}
                      onChange={(e) =>
                        setHideScoresFromPatient(e.target.checked)
                      }
                    />
                  }
                  label={t('Hide questionnaire scores from patient')}
                />
              )}
            </FormGroup>

            <TextField
              name="task-order"
              label={t('Order')}
              disabled={!canEdit}
              value={order || ''}
              onChange={handleOrderChange}
              fullWidth
              required
              type="number"
              inputProps={{ step: 1 }}
              sx={{ mt: 2 }}
            />
          </CardContent>
        </Card>
        {!isGloballyAvailable && (isOrganizationAdmin || isSuperAdmin) && (
          <Card sx={{ mt: 2 }} variant="outlined">
            <CardHeader title={t('Administration')} subheader={t('Options')} />
            <CardContent>
              <FormGroup>
                <FormControlLabel
                  disabled={!isOrganizationAdmin && !isSuperAdmin}
                  control={
                    <Checkbox
                      checked={isPublic}
                      onChange={handlePublicChange}
                    />
                  }
                  label={t('Show this task to all users of organization')}
                />
              </FormGroup>
            </CardContent>
          </Card>
        )}

        {updateSuccessful && (
          <Alert
            severity="success"
            sx={{ mt: 2 }}
            onClose={() => setUpdateSuccessful(false)}
          >
            <AlertTitle>{t('Task updated successfully')}</AlertTitle>
          </Alert>
        )}
        {saveErrors.map(
          (error, idx) =>
            error.error && (
              <Alert key={`alert-${idx}`} severity="error" sx={{ mt: 2 }}>
                <AlertTitle>{t(error.message)}</AlertTitle>
                {error.error.message}
              </Alert>
            )
        )}
        {canEdit && (
          <Button
            loading={updating}
            type="submit"
            variant="contained"
            sx={{ mt: 2 }}
            disabled={!email || updating}
          >
            {t('Save')}
          </Button>
        )}
        {isSuperAdmin && isGloballyAvailable && (
          <Button
            color="error"
            variant="contained"
            sx={{ mt: 2, ml: 2 }}
            onClick={demoteToPrivate}
          >
            {t('Make Private')}
          </Button>
        )}
        {isSuperAdmin && !isGloballyAvailable && (
          <Button
            color="error"
            variant="contained"
            sx={{ mt: 2, ml: 2 }}
            onClick={promoteToGlobal}
          >
            {t('Make Default')}
          </Button>
        )}

        <CreatedModifiedTable isNew={taskPoolTaskId === 'new'} data={data} />
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Trans>Are you sure?</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogDescription}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={handleConfirm} autoFocus>
            <Trans>OK</Trans>
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!updateError}
        autoHideDuration={6000}
        onClose={() => setUpdateError(null)}
      >
        <Alert onClose={() => setUpdateError(null)} severity="error">
          <AlertTitle>
            <Trans>Error Saving Task</Trans>
          </AlertTitle>
          <Trans>{updateError?.message}</Trans>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!addError}
        autoHideDuration={6000}
        onClose={() => setAddError(null)}
      >
        <Alert onClose={() => setAddError(null)} severity="error">
          <AlertTitle>
            <Trans>Error Adding Task</Trans>
          </AlertTitle>
          <Trans>{addError?.message}</Trans>
        </Alert>
      </Snackbar>
      <Snackbar
        open={!!imageUploadError}
        autoHideDuration={6000}
        onClose={() => setImageUploadError(null)}
      >
        <Alert onClose={() => setImageUploadError(null)} severity="error">
          <AlertTitle>
            <Trans>Error Uploading Image</Trans>
          </AlertTitle>
          <Trans>{imageUploadError?.message}</Trans>
        </Alert>
      </Snackbar>
      <Dialog
        open={showTypeChangeWarning}
        onClose={handleCancelTypeChange}
        aria-labelledby="type-change-dialog-title"
        aria-describedby="type-change-dialog-description"
      >
        <DialogTitle id="type-change-dialog-title">
          <Trans>Warning: Changing Task Type</Trans>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="type-change-dialog-description">
            <Trans>
              Changing the task type for an existing task may cause unexpected
              application behavior if patients have already made markings using
              this task. Are you sure you want to continue?
            </Trans>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelTypeChange}>
            <Trans>Cancel</Trans>
          </Button>
          <Button onClick={handleConfirmTypeChange} color="warning">
            <Trans>Change Type</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
