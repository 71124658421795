import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
  Autocomplete,
} from '@mui/material';
import { Timestamp, DocumentReference } from 'firebase/firestore';
import {
  TherapyCourse,
  TherapyCourseInvitation,
  ServiceProvider,
  PortalUser,
} from '../../firebase/firebaseModels';
import useUserProvider from '../../firebase/useUserProvider';
import { useFirebaseUser } from '../../firebase/useFirebaseUser';
import { usePortalUsers } from '../../firebase/usePortalUsers';
import { useTherapyCourseInvitations } from '../../firebase/useTherapyCourseInvitations';

interface InviteTherapistDialogProps {
  open: boolean;
  onClose: () => void;
  therapyCourseRef: DocumentReference<TherapyCourse>;
  pendingInvites: TherapyCourseInvitation[];
  setPendingInvites: (invites: TherapyCourseInvitation[]) => void;
}

export default function InviteTherapistDialog({
  open,
  onClose,
  therapyCourseRef,
  pendingInvites,
  setPendingInvites,
}: InviteTherapistDialogProps) {
  const { t } = useTranslation();
  const { email: userEmail } = useFirebaseUser();
  const { data: portalUsers } = usePortalUsers();
  const { invitingInvitations: pendingInvitations } =
    useTherapyCourseInvitations();

  const [selectedUser, setSelectedUser] = useState<PortalUser | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data: userProviderData } = useUserProvider();

  // Extract owner email from therapyCourseRef path
  const ownerEmail = therapyCourseRef.path.split('/')[3];

  // Filter out the owner and already invited users from the autocomplete options
  const filteredUsers = (portalUsers || []).filter((user) => {
    const isOwner = user.email === ownerEmail;
    const isInvited = pendingInvitations?.some(
      (invitation) => invitation.invitedTherapistEmail === user.email
    );
    const isCurrentUser = user.email === userEmail;
    const isSameProvider = user.provider?.id === userProviderData?.id;
    return !isOwner && !isInvited && !isCurrentUser && isSameProvider;
  });

  const handleSubmit = async () => {
    if (!userProviderData) {
      setError(t('User provider data not found'));
      return;
    }
    if (!userEmail || !selectedUser) {
      return;
    }

    if (selectedUser.email === userEmail) {
      setError(t('You cannot invite yourself'));
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const invitation: TherapyCourseInvitation = {
        id: `pending-${Date.now()}`, // Temporary ID for pending invites
        therapyCourse: therapyCourseRef,
        invitedTherapistEmail: selectedUser.email,
        invitingTherapistEmail: userEmail,
        status: 'pending',
        created: Timestamp.now(),
        createdBy: userEmail,
        modified: Timestamp.now(),
        modifiedBy: userEmail,
        provider: therapyCourseRef.parent
          .parent as DocumentReference<ServiceProvider>,
      };

      // Add to pending invites instead of sending immediately
      setPendingInvites([...pendingInvites, invitation]);
      setSelectedUser(null);
      onClose();
    } catch (err) {
      setError(t('Failed to create invitation. Please try again.'));
      console.error('Error creating invitation:', err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setSelectedUser(null);
    setError(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('Add Therapist to Course')}</DialogTitle>
      <DialogContent>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Autocomplete
          autoFocus
          fullWidth
          options={filteredUsers}
          value={selectedUser}
          onChange={(_, newValue) => setSelectedUser(newValue)}
          getOptionLabel={(option) => `${option.name} (${option.email})`}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              label={t('Select User')}
              error={!!error}
            />
          )}
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('Cancel')}</Button>
        <Button
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {t('Send Invitation')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
