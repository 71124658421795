import { useContext } from 'react';
import { SharedPatientContext, TSharedPatientData } from '../contexts/SharedPatientContext';

export default function useSharedPatientCollection(): TSharedPatientData {
  const context = useContext(SharedPatientContext);
  if (context === null) {
    throw new Error(
      'useSharedPatientCollection must be used within a SharedPatientProvider'
    );
  }
  return context;
}  