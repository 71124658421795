import { useMemo } from 'react';
import { TaskPoolOption } from './useTaskPoolOptions';
import { getTranslatableField } from '../services/i18n';
import { TaskPoolTask } from '../firebase/firebaseModels';

type TaskFilterParams<T extends TaskPoolOption | TaskPoolTask> = {
  tasks: T[];
  searchQuery: string;
  selectedTaskType: string;
  selectedCategory: string;
  excludeTaskTypes?: string[];
  docType?: string;
};

export function useTaskFilter<T extends TaskPoolOption | TaskPoolTask>({
  tasks,
  searchQuery,
  selectedTaskType,
  selectedCategory,
  excludeTaskTypes = [],
  docType,
}: TaskFilterParams<T>): T[] {
  const filteredTasks = useMemo(() => {
    if (!tasks) return [];
    return tasks.filter((task) => {
      let taskType: string;
      let categoryId: string | null;
      let translatedTitle: string;
      let taskDocType: string | undefined;

      if (typeof task === 'object' && 'taskType' in task) {
        taskType = task.taskType;
        categoryId = task.categoryId;
        translatedTitle = task.translatedTitle;
        taskDocType = task.docType;
      } else {
        taskType = task.type;
        categoryId = task.category?.id || null;
        translatedTitle = getTranslatableField(task.title);
      }

      // Exclude specific task types if provided
      if (excludeTaskTypes.includes(taskType)) {
        return false;
      }

      const matchesType = selectedTaskType === 'all' || taskType === selectedTaskType;
      const matchesCategory = selectedCategory === 'all' || categoryId === selectedCategory;
      const matchesSearch =
        !searchQuery ||
        translatedTitle.toLowerCase().includes(searchQuery.toLowerCase());
      const matchesDocType = !docType || taskDocType === docType;

      return matchesType && matchesCategory && matchesSearch && matchesDocType;
    });
  }, [tasks, selectedTaskType, selectedCategory, searchQuery, excludeTaskTypes, docType]);

  return filteredTasks;
}
