import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useFirestore } from 'reactfire';
import { doc, onSnapshot, DocumentReference } from 'firebase/firestore';
import { Patient, User } from '../firebase/firebaseModels';
import { useSharedTherapyCourses } from '../firebase/useSharedTherapyCourses';
import { FirebaseError } from 'firebase/app';

export type TSharedPatientData = {
  status: 'loading' | 'success' | 'error';
  data: Patient[] | null;
  appUsersStatus: 'loading' | 'success' | 'error';
  appUsers: User[] | null;
  error: FirebaseError | null;
  appUsersError: FirebaseError | null;
  isSharedPatientValid: (patient: Patient | null) => boolean;
  isSharedPatientValidById: (patientId: string | null) => boolean;
  getSharedPatientRef: (patientId: string | null) => DocumentReference | null;
  getPatientOwnerEmail: (patientId: string | null) => string | null;
};

export const SharedPatientContext = createContext<TSharedPatientData | null>(
  null
);

export const useSharedAppUser = (patient: Patient | null) => {
  const context = useContext(SharedPatientContext);

  if (!context) {
    throw new Error(
      'useSharedAppUser must be used within a SharedPatientProvider'
    );
  }

  const { appUsers } = context;

  // Memoize the result since it depends on patient and appUsers
  return useMemo(() => {
    if (!patient) {
      return null;
    }
    const { licenceCode } = patient;
    const userId = patient.user?.id;
    if (!licenceCode || !userId) {
      return null;
    }

    return (
      appUsers?.find(
        (user) => user.licenceCode === licenceCode && user.id === userId
      ) || null
    );
  }, [patient, appUsers]);
};

export const SharedPatientProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const firestore = useFirestore();
  const { sharedCourses, status: coursesStatus } = useSharedTherapyCourses();

  const [nTimeouts, setNTimeouts] = useState(0);
  const [appUsers, setAppUsers] = useState<User[] | null>(null);
  const [patientData, setPatientData] = useState<Patient[] | null>(null);
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>(
    'loading'
  );
  const [appUsersStatus, setAppUsersStatus] = useState<
    'loading' | 'success' | 'error'
  >('loading');
  const [error, setError] = useState<FirebaseError | null>(null);
  const [appUsersError, setAppUsersError] = useState<FirebaseError | null>(
    null
  );
  const [hasInitialFetch, setHasInitialFetch] = useState(false);

  // Fetch patients from shared courses
  useEffect(() => {
    if (coursesStatus !== 'success' || !sharedCourses?.length) {
      setPatientData([]);
      setStatus('success');
      setHasInitialFetch(true);
      return;
    }

    // Only set loading status on initial fetch
    if (!hasInitialFetch) {
      setStatus('loading');
    }

    const unsubscribes: (() => void)[] = [];
    let completedInitialFetches = 0;
    const totalPatientRefs = sharedCourses.reduce(
      (acc, course) => acc + (course.patients?.length || 0),
      0
    );

    // Create a map to track patient updates
    const patientMap = new Map<string, Patient>();

    sharedCourses.forEach((course) => {
      course.patients?.forEach((patientRef) => {
        const unsubscribe = onSnapshot(
          doc(firestore, patientRef.path),
          (snapshot) => {
            const pathParts = patientRef.path.split('/');
            const ownerEmail = pathParts[3];

            const patientData = {
              ...snapshot.data(),
              id: snapshot.id,
              ownerEmail,
            } as Patient;

            if (patientData && patientData.status !== 'deleted') {
              patientMap.set(snapshot.id, patientData);
            } else {
              patientMap.delete(snapshot.id);
            }

            // Update patient data with current map values
            setPatientData(Array.from(patientMap.values()));

            // Only update completion status on initial load
            if (completedInitialFetches < totalPatientRefs) {
              completedInitialFetches++;
              if (completedInitialFetches === totalPatientRefs) {
                setStatus('success');
                setNTimeouts(0);
                setHasInitialFetch(true);
              }
            }
          },
          (error) => {
            console.error('Error fetching shared patient:', error);
            setError(error as FirebaseError);

            if (completedInitialFetches < totalPatientRefs) {
              completedInitialFetches++;
              if (completedInitialFetches === totalPatientRefs) {
                setStatus('error');
              }
            }
          }
        );
        unsubscribes.push(unsubscribe);
      });
    });

    // Handle case where there are no patients to fetch
    if (totalPatientRefs === 0) {
      setPatientData([]);
      setStatus('success');
    }

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [firestore, sharedCourses, coursesStatus, nTimeouts, hasInitialFetch]);

  // Fetch app users for the patients
  useEffect(() => {
    if (!patientData?.length) {
      setAppUsers([]);
      setAppUsersStatus('success');
      return;
    }

    setAppUsersStatus('loading');
    const unsubscribes: (() => void)[] = [];
    let completedFetches = 0;
    const totalUsers = patientData.filter((patient) => patient.user?.id).length;

    patientData.forEach((patient) => {
      const userId = patient.user?.id;
      if (userId) {
        const userRef = doc(firestore, 'Users', userId);
        const unsubscribe = onSnapshot(
          userRef,
          (userDoc) => {
            setAppUsers((prevUsers) => {
              const userData = { ...userDoc.data(), id: userDoc.id } as User;
              if (prevUsers) {
                return [
                  ...prevUsers.filter((u) => u.id !== userData.id),
                  userData,
                ];
              }
              return [userData];
            });
            completedFetches++;
            if (completedFetches === totalUsers) {
              setAppUsersStatus('success');
            }
          },
          (error) => {
            console.error('Error fetching shared user:', error);
            setAppUsersError(error as FirebaseError);
            completedFetches++;
            if (completedFetches === totalUsers) {
              setAppUsersStatus('error');
            }
          }
        );
        unsubscribes.push(unsubscribe);
      }
    });

    if (totalUsers === 0) {
      setAppUsers([]);
      setAppUsersStatus('success');
    }

    return () => {
      unsubscribes.forEach((unsubscribe) => unsubscribe());
    };
  }, [firestore, patientData, nTimeouts]);

  const isSharedPatientValid = useCallback(
    (patient: Patient | null) => {
      if (!patient) return false;
      const { licenceCode } = patient;
      const userId = patient.user?.id;
      if (!licenceCode || !userId) return false;
      return !!appUsers?.some(
        (user) => user.licenceCode === licenceCode && user.id === userId
      );
    },
    [appUsers]
  );

  const isSharedPatientValidById = useCallback(
    (patientId: string | null) => {
      if (!patientId) return false;
      const patient =
        patientData?.find((patient) => patient.id === patientId) || null;
      return isSharedPatientValid(patient);
    },
    [patientData, isSharedPatientValid]
  );

  const data = useMemo(() => {
    // Filter out patients whose licenceCode does not match the user's licenceCode
    if (!patientData || !appUsers) {
      return null;
    }
    return patientData.filter((patient) => {
      const { licenceCode } = patient;
      const userId = patient.user?.id;
      if (!licenceCode || !userId) {
        return false;
      }
      const user = appUsers.find(
        (user) => user.licenceCode === licenceCode && user.id === userId
      );
      return !!user;
    });
  }, [appUsers, patientData]);

  const getSharedPatientRef = useCallback(
    (patientId: string | null) => {
      if (!patientId || !sharedCourses) return null;

      for (const course of sharedCourses) {
        const patientRef = course.patients?.find((ref) => {
          const parts = ref.path.split('/');
          return parts[parts.length - 1] === patientId;
        });
        if (patientRef) return patientRef;
      }
      return null;
    },
    [sharedCourses]
  );

  const getPatientOwnerEmail = useCallback(
    (patientId: string | null) => {
      if (!patientId || !sharedCourses) return null;

      for (const course of sharedCourses) {
        const patientRef = course.patients?.find((ref) => {
          const parts = ref.path.split('/');
          return parts[parts.length - 1] === patientId;
        });

        if (patientRef) {
          const pathParts = patientRef.path.split('/');
          // UserData/{email}/Patients/{id}
          return pathParts[3];
        }
      }
      return null;
    },
    [sharedCourses]
  );

  const contextValue = useMemo(
    () => ({
      status,
      data,
      error,
      appUsers,
      appUsersStatus,
      appUsersError,
      isSharedPatientValid,
      isSharedPatientValidById,
      getSharedPatientRef,
      getPatientOwnerEmail,
    }),
    [
      status,
      data,
      error,
      appUsers,
      appUsersStatus,
      appUsersError,
      isSharedPatientValid,
      isSharedPatientValidById,
      getSharedPatientRef,
      getPatientOwnerEmail,
    ]
  );

  return (
    <SharedPatientContext.Provider value={contextValue}>
      {children}
    </SharedPatientContext.Provider>
  );
};
