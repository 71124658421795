import { httpsCallable } from 'firebase/functions';
import { useFunctions } from 'reactfire';
import { useCallback, useState } from 'react';
import { useAuditLogContext } from '../contexts/AuditLogContext';
import { AuditLogData } from '../contexts/AuditLogContext';
import useUserProvider from './useUserProvider';
/**
 * Custom hook to send audit logs for tracking sensitive healthcare operations.
 *
 * @returns {Object} An object containing:
 *   - sendAuditLog: Function to log sensitive operations with parameters:
 *     - action: Type of action performed ('view', 'create', 'update', 'delete', 'share')
 *     - resourceType: Type of resource being accessed (e.g., 'patient', 'therapyCourse')
 *     - resourceId: Identifier of the accessed resource
 *     - performedBy: ID of the user performing the action
 *     - details: Optional additional context about the operation
 *   - isLogging: Boolean indicating if logging is in progress
 *   - logError: String containing error message if logging failed, null otherwise
 */
export function useRemoteLog() {
  const functions = useFunctions();
  const [isLogging, setIsLogging] = useState(false);
  const [logError, setLogError] = useState<string | null>(null);
  const { lastLogData, setLastLogData } = useAuditLogContext();
  const { data: userProviderData } = useUserProvider();

    const sendAuditLog = useCallback(
    async ({ action, resourceType, resourceId, performedBy, details }: AuditLogData) => {
      setIsLogging(true);
      setLogError(null);

      // Check if this is a duplicate log within the time window
      const currentTime = Date.now();
      const DEBOUNCE_WINDOW = 5 * 60 * 1000; // 5 minutes in milliseconds

      if (lastLogData) {
        const timeWithinWindow = (currentTime - lastLogData.timestamp) < DEBOUNCE_WINDOW;
        const currentMetadata = { action, resourceType, resourceId, performedBy, details };
        const isDuplicate = timeWithinWindow && 
          JSON.stringify(currentMetadata) === JSON.stringify(lastLogData.data);

        if (isDuplicate) {
          setIsLogging(false);
          return { success: true, debounced: true };
        }
      }

      try {
        const remoteLogFunction = httpsCallable(functions, 'remotelog');
        const logData = {
          level: 'info',
          message: `${action} operation performed on ${resourceType} (${resourceId})`,
          service: 'console',
          metadata: {
            action,
            resourceType,
            resourceId,
            performedBy,
            details,
            userProviderId: userProviderData?.id,
            userProviderName: userProviderData?.name,
          }
        };
        const result = await remoteLogFunction(logData);
        
        // Store the successful log data
        setLastLogData({
          data: { action, resourceType, resourceId, performedBy, details },
          timestamp: currentTime
        });

        // Check if the response contains an error
        const response = result.data as { success?: boolean; error?: string };
        if (response.error) {
          console.error('Error sending audit log', response.error);
          throw new Error(response.error);
        }

        setIsLogging(false);
        return response;
      } catch (error: any) {
        setIsLogging(false);
        setLogError(error.message);
        console.error('Error sending audit log', error);
        return { success: false, error: error.message };
      }
    },
    [functions, lastLogData, setLastLogData, userProviderData?.id, userProviderData?.name]
  );

  return { sendAuditLog, isLogging, logError };
} 