import { useSigninCheck } from 'reactfire';
import { Navigate, useLocation } from 'react-router-dom';
import {
  Box,
  CircularProgress,
  Button,
  Typography,
  Container,
} from '@mui/material';
import { useMemo } from 'react';
import usePortalUser from '../firebase/usePortalUser';
import { Trans } from 'react-i18next';
import LogoutIcon from '@mui/icons-material/Logout';
import { useLogout } from '../pages/Logout';
import RequirePasswordChange from '../pages/RequirePasswordChange';

/**
 * A wrapper component that redirects to the login page if the user is not logged in.
 *
 * @param  JSX.Element children
 * @returns JSX.Element
 */
export default function RequireAuth({ children }: { children: JSX.Element }) {
  const { status: authStatus, data: signInCheckResult } = useSigninCheck();
  const logout = useLogout();
  let location = useLocation();
  const state = useMemo(() => ({ from: location }), [location]);

  const { status: userDataStatus, accessAllowed } = usePortalUser();

  if (!signInCheckResult || authStatus === 'loading') {
    return <CircularProgress />;
  }

  if (!signInCheckResult.signedIn) {
    return <Navigate to="/login" state={state} replace />;
  }

  if (userDataStatus === 'success' && !accessAllowed) {
    // show a message that the user is not allowed to access the app
    return (
      <Container>
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">
            <Trans>You are not allowed to access this app.</Trans>
          </Typography>
          <Button
            variant="contained"
            startIcon={<LogoutIcon />}
            onClick={() => logout()}
          >
            <Trans>Logout</Trans>
          </Button>
        </Box>
      </Container>
    );
  }

  return <RequirePasswordChange>{children}</RequirePasswordChange>;
}
