export const devMode = process.env.NODE_ENV === 'development';

/* Change to false when you want to use the production Firebase API from local development environment */
export const useEmulator = devMode;

export const drawerWidth: number = 260; 

export const DEFAULT_IMAGE_URL =
  'https://cdn.oneirostherapies.com/Infi.png';

export const CDN_URL = useEmulator
  ? 'http://127.0.0.1:9199/v0/b/oneiros-5ccbe.appspot.com/o'
  : 'https://cdn.oneirostherapies.com';
